import React, { useState } from "react";
import {
    Form, required, SelectInput, usePermissions, useRefresh
} from "react-admin";
import {
    Card,
    Button, Avatar, CardActions, CircularProgress,
} from "@mui/material";
import { useProjects } from "./dataHookTest";
import Box from "@mui/material/Box";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";


const ProjectSelect: React.FC<{}> = ({}) => {
    const { isLoading, permissions } = usePermissions();
    const { isLoading: isQueryLoading, data, refetch } = useProjects();
    const refresh = useRefresh();

    const [ isRequestLoading, setRequestLoading ] = useState<boolean>(false);

    const navigate = useNavigate();

    if (isLoading || isQueryLoading || ! data) return null;

    const handleSubmit = (value: any) => {
        if (!value.project)
            return null;
        setRequestLoading(true);
        refresh();
        localStorage.setItem('project', value.project);
        navigate('/');
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>
                            <LockIcon />
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            px: '10px',
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        You have more than project assigned. Please select the project using.
                    </Box>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <SelectInput
                                fullWidth
                                autoFocus
                                name="project"
                                source="project"
                                variant="outlined"
                                optionText={value => (
                                    <Box>
                                        {value.name} <br />
                                        {value.description}
                                    </Box>
                                )}
                                optionValue="uuid"
                                choices={data.sort((n1, n2) => n1.recordId - n2.recordId)}
                                validate={required("Please select project")}
                                isRequired
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isRequestLoading}
                            fullWidth
                        >
                            {isRequestLoading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            Select Project
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

export default ProjectSelect;

interface Project {
    recordId: number;
    name: string;
    description: string;
    uuid: string;
    status: string;
}