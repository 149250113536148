import React, { ReactComponentElement } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Fab, IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import { useDelete, useNotify, useRedirect, useResourceContext } from "react-admin";
import Swal from "sweetalert2";
import { useRecordContext } from "ra-core";
import CustomIconButton from "./common/CustomIconButton";

const defaultIcon = <DeleteOutlineOutlinedIcon />

interface CustomDeleteIconButtonV2Prop {
    forwardedRef?: React.ForwardedRef<any>;
    style?: React.CSSProperties;
    title?: string;
    icon?: ReactComponentElement<any>;
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
    IconButtonProps?: IconButtonProps;
    isFloating?: boolean;
    redirectTo?: string,
    onSuccessFunction?: () => void,
}

const CustomDeleteIconButtonV2: React.FC<CustomDeleteIconButtonV2Prop> =
    ({
         forwardedRef,
         style,
         title = 'Delete',
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         redirectTo = 'list',
         onSuccessFunction = () => {
         },
         isFloating = false,
     }) => {
        const resource = useResourceContext();
        const record = useRecordContext();

        const redirect = useRedirect();
        const notify = useNotify();

        const [ deleteOne, { isLoading, error } ] = useDelete();

        const deleteDialog = () => {
            Swal.fire({
                title: `Delete Record #${record?.id}`,
                text: 'Are you sure you want to delete this item?',
                icon: 'warning',
                footer: 'The action cannot be reversed!',
                showCancelButton: true,
                focusConfirm: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        deleteOne(resource, {
                                id: record?.id,
                                previousData: record,
                            },
                            {
                                onSuccess: () => {
                                    if (redirectTo === 'reload') {
                                        Swal.fire({
                                            title: 'Record delete',
                                            showConfirmButton: true,
                                            showDenyButton: true,
                                            confirmButtonText: 'Stay here',
                                            denyButtonText: 'Back to list'
                                        }).then((result) => {
                                            if (result.isConfirmed || result.isDismissed) {
                                                onSuccessFunction();
                                            }
                                        });
                                    } else {
                                        Swal.fire({
                                            title: 'Record delete',
                                        }).then(() => {
                                            redirect(redirectTo, resource);
                                        });
                                    }
                                    if (onSuccessFunction) onSuccessFunction();
                                },
                                onError: () => {
                                    notify('Failed to delete', {
                                        type: 'error',
                                        messageArgs: { smart_count: 1 },
                                    });
                                },
                            },)
                    }
                });
        }

        const FloatingButton = () => (
            <Fab
                onClick={(e) => {
                    deleteDialog();
                }}
                color="error"
            >
                {icon}
            </Fab>
        )

        const NormalButton = () => (
            <Tooltip title={title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        size="small"
                        color="softError"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteDialog();
                        }}
                        {...IconButtonProps}>
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        );

        return (
            <Box component="span" style={style} ref={forwardedRef}>
                {isFloating ? <FloatingButton /> : <NormalButton />}
            </Box>
        );
    }

export default React.forwardRef((props: CustomDeleteIconButtonV2Prop, ref) =>
    <CustomDeleteIconButtonV2 {...props} forwardedRef={ref} />);
