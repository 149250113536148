import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    Fab,
    Stack, TextField,
    Zoom,
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import LockIcon from '@mui/icons-material/Lock';
import {
    useCheckAuth,
    useLogin,
    useNotify,
    useTranslate,
} from 'react-admin';

import Box from '@mui/material/Box';
import { useLocales, useLocaleState } from "ra-core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { userLogin } from "./dataHookTest";
import { AxiosError } from "axios";
import { setUserUuid } from "../../support/googleAnalytics";

interface LoginForm {
    username: string;
    password: string;
}

const Login = () => {

    const formProps = useForm<LoginForm>({
        reValidateMode: "onChange",
        defaultValues: {
            username: "",
            password: "",
        },
    });
    const checkAuth = useCheckAuth();
    const navigate = useNavigate();

    useEffect(() => {
        formProps.reset({
            username: "",
            password: "",
        });
    }, [ formProps ]);

    const [ loading, setLoading ] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();

    const onSubmitHandler: SubmitHandler<LoginForm> = (data, event) => {
        setLoading(true);
        userLogin(
            data.username,
            data.password,
        )
            .then(response => {
                return response.data;
            })
            .then((auth) => {
                navigate("/");
                localStorage.setItem('auth', auth.token);
                setUserUuid();
            })
            .catch((error: AxiosError<ErrorDTO>) => {
                console.log("Error: ", error);
                if (error.response) {
                    notify(error.response.data.message, { type: "error" });
                } else {
                    notify(error.message, { type: "error" });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const FloatingLocaleButtonGroup: React.FC = () => {
        const [ expended, setExpended ] = useState(false);
        const translate = useTranslate();
        const languages = useLocales();
        const [ locale, setLocale ] = useLocaleState();

        return (
            <Fragment>
                <Box component="div"
                     sx={{
                         zIndex: 222,
                         position: "fixed",
                         bottom: 16,
                         right: 16,
                     }}
                >
                    <Stack
                        direction="column-reverse"
                        spacing={2}
                        alignItems="flex-end"
                        onClick={() => setExpended(! expended)}
                    >
                        <Fab onClick={() => {
                            setExpended(true);
                        }} color="warning">
                            <TranslateIcon />
                        </Fab>
                        <Zoom in={expended}>
                            <Fab
                                color={locale === "en" ? "primary" : "secondary"}
                                variant="extended"
                                onClick={() => setLocale("en")}
                            >
                                {translate("common.locale.en")}
                            </Fab>
                        </Zoom>
                        <Zoom in={expended}>
                            <Fab
                                color={locale === "zh-hk" ? "primary" : "secondary"}
                                variant="extended"
                                onClick={() => setLocale("zh-hk")}
                            >
                                {translate("common.locale.zh_HK")}
                            </Fab>
                        </Zoom>
                        {languages.map(language => (
                            <Zoom in={expended} key={language.locale}>
                                <Fab
                                    color={locale === language.locale ? "primary" : "secondary"}
                                    variant="extended"
                                    onClick={() => setLocale(language.locale)}
                                >
                                    {language.name}
                                </Fab>
                            </Zoom>
                        ))}
                    </Stack>
                </Box>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <form onSubmit={formProps.handleSubmit(onSubmitHandler)} noValidate>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                >
                    <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                        <CardContent>
                            <Alert severity="warning" color="warning">Credentials might be changed, please contact system admin.</Alert>
                        </CardContent>
                        <CardContent>
                            <Box
                                sx={{
                                    margin: '1em',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                    <LockIcon />
                                </Avatar>
                            </Box>
                            <Box sx={{ padding: '0 1em 1em 1em' }}>
                                <Box sx={{ marginTop: '1em' }}>
                                    <Controller
                                        render={({ field, fieldState, formState }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={!! fieldState.error}
                                                autoFocus
                                                type="email"
                                                label={translate("ra.auth.username")}
                                                fullWidth
                                            />}
                                        rules={{ required: true }}
                                        disabled={loading}
                                        name="username"
                                        control={formProps.control}
                                    />
                                </Box>
                                <Box sx={{ marginTop: '1em' }}>
                                    <Controller
                                        render={({ field, fieldState, formState }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={!! fieldState.error}
                                                type="password"
                                                label={translate("ra.auth.password")}
                                                disabled={loading}
                                                required
                                                fullWidth
                                                autoComplete="on"
                                            />}
                                        name="password"
                                        control={formProps.control}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                {translate("ra.auth.sign_in")}
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </form>
            <FloatingLocaleButtonGroup />
        </Fragment>
    );
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}