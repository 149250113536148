import { Box } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Title } from "react-admin";
import { useState } from "react";
import { EventSourceInput } from "@fullcalendar/core";
import ProgressiveImg from "../../../component/ProgressiveImg";


const TestCalendar = () => {

    const [events, setEvents] = useState<EventSourceInput>(
        [
            {
                id: "A1",
                title: "Meeting 123",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                extendedProps: {
                    eventEntity: {
                        id: "asd",
                    },
                },
                editable: true,
            },
            {
                id: "A2",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A3",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A4",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A5",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A6",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A7",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                editable: true,
            },
            {
                id: "A8",
                title: "Meeting",
                start: moment().startOf("hour").toDate(),
                end: moment().endOf("hour").toDate(),
                editable: true,
            },
            {
                id: "A9",
                title: "Meeting",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").add(2, "day").toDate(),
                editable: true,
            },
        ]
    );

    return (
        <Box sx={{
            "& .fc": {
                "& .fc-popover": {
                    backgroundColor: "#000",
                },
                "& .fc-list-event:hover td" : {
                    color: "#000",
                },
            },
        }}>
            <Title title="Calendar - Test" />
            <FullCalendar
                plugins={[ dayGridPlugin, listPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                eventBackgroundColor="#fd54ee"
                events={'https://fullcalendar.io/api/demo-feeds/events.json?overload-day'}
                headerToolbar={{
                    left: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
                    center: 'title',
                    right: 'prevYear,prev,next,nextYear',
                }}
                editable={true}
                height={"80vh"}
                dayMaxEventRows={5}
                eventClick={(e) => {
                    console.log(e);
                }}
                eventDragStart={(e) => {
                    console.log(e);
                }}
                eventDragStop={(e) => {
                    console.log(e);
                }}
                eventChange={(e) => {
                    console.log("Event Change: ", e)
                }}
                locale="en-GB"
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                    hour12: false
                }}
            />

            <ProgressiveImg alt="test" src="Test.png" />
        </Box>
    );
};

export default TestCalendar;
