import React from "react";
import { SelectInput, SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";
import { COMMON_STATUS } from "../../constant/CommonStatus";

const QuantityUnitEditForm: React.FC<EditFormProps> = ({ type }) => {
    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent>

                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="nameSingular" fullWidth required variant="standard" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="namePlural" fullWidth required variant="standard" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="description" fullWidth required variant="standard" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <SelectInput source="status" fullWidth required variant="standard"
                                         defaultValue="A"
                                         choices={COMMON_STATUS}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
}

export default QuantityUnitEditForm;
