import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Title, usePermissions } from "react-admin";

export const AdminDashboard = () => {

    const { isLoading, permissions } = usePermissions();

    console.log(permissions);

    // console.log(permissions.indexOf('admin'));

    if (isLoading)
        return null;

    return (
        <Card>
            <Title title="Home Cloud Admin" />
            <CardHeader title="Welcome to the administration" />
            <CardContent>Please.</CardContent>
        </Card>
    );
};