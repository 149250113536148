import { DataProvider } from "react-admin";
import axios from "axios";
import config from "../../../../config.json";
import { getProjectUuid, getUserUuid } from "../../../../auth/AuthUtils";
import moment from "moment/moment";
import {
    FetchOctopusConsumptionOptions,
    FetchOctopusGasConsumptionOptions,
    FetchOctopusGasTariffOptions,
    FetchOctopusStandingChargeOptions,
    FetchOctopusTariffOptions,
    OctopusAccount,
    OctopusApiConsumptionResponse,
    OctopusApiStandardChargeResponse,
    OctopusApiTariffResponse,
    OctopusConvertedConsumption,
    OctopusConvertedStandardCharge,
    OctopusConvertedTariff,
    OctopusGasConvertedConsumption
} from "../types/types";

export interface OctopusDataProvider extends DataProvider {
    fetchOctopusAccount: (resource: string, { projectUuid, userUuid}: { projectUuid: string; userUuid: string; }) => Promise<OctopusAccount>;
    updateOctopusAccount: (resource: string, { apiKey, accountId }: { apiKey: string; accountId: string;}) => Promise<boolean>;
    fetchOctopusTariff: (resource: string, options: FetchOctopusTariffOptions) => Promise<OctopusConvertedTariff[]>;
    fetchOctopusConsumption: (resource: string, options: FetchOctopusConsumptionOptions) => Promise<OctopusConvertedConsumption[]>;
    fetchOctopusGasConsumption: (resource: string, options: FetchOctopusGasConsumptionOptions) => Promise<OctopusGasConvertedConsumption[]>;
    fetchOctopusStandingCharge: (resource: string, options: FetchOctopusStandingChargeOptions) => Promise<OctopusConvertedStandardCharge[]>;
    fetchOctopusGasTariff: (resource: string, options: FetchOctopusGasTariffOptions) => Promise<OctopusConvertedTariff[]>;
    fetchOctopusGasStandingCharge: (resource: string, options: FetchOctopusStandingChargeOptions) => Promise<OctopusConvertedStandardCharge[]>;
}

const methodNotSupport = () => Promise.reject("method not supported");

export const octopusDataProvider = (): OctopusDataProvider => {
    return {
        create: () => methodNotSupport(),
        delete: () => methodNotSupport(),
        deleteMany: () => methodNotSupport(),
        getList: () => methodNotSupport(),
        getMany: () => methodNotSupport(),
        getManyReference: () => methodNotSupport(),
        getOne: () => methodNotSupport(),
        update: () => methodNotSupport(),
        updateMany: () => methodNotSupport(),
        fetchOctopusAccount: async (_: string, { projectUuid, userUuid }): Promise<OctopusAccount> => {
            return axios.get<OctopusAccount>(`${config.BASE_URL}/project/${projectUuid}/user/${userUuid}/energy/octopus/account`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth")}`
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(error => {
                    throw new Error(`Error during fetch octopus account`, error);
                });
        },
        updateOctopusAccount: async (_: string, options: { apiKey: string; accountId: string;}): Promise<boolean> => {
            const { apiKey, accountId } = options;
            console.log(apiKey, accountId);
            return axios.post(`${config.BASE_URL}/project/${getProjectUuid()}/user/${getUserUuid()}/energy/octopus/account/update`, {
                apiKey: apiKey,
                accountId: accountId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth")}`
                }
            })
                .then(result => {
                    return !!result;
                })
                .catch(error => {
                    console.log("Error caught", error)
                    throw new Error(`Error during update octopus account`, error);
                });
        },
        fetchOctopusTariff: async (resource: string, { dataProvider, agreements, results = [], pageParam = 1 }: FetchOctopusTariffOptions): Promise<OctopusConvertedTariff[]> => {
            if (agreements.length === 0)
                return Promise.resolve(results);

            const { tariffCode, validFrom, validTo } = agreements[0];
            const productCode = tariffCode.substring(5, tariffCode.length - 2);

            return axios.get<OctopusApiTariffResponse>(`https://api.octopus.energy/v1/products/${productCode}/electricity-tariffs/${tariffCode}/standard-unit-rates/?period_from=${validFrom}&period_to=${validTo}&page=${pageParam}&page_size=1500`)
                .then((result) => {
                    if (tariffCode === "E-1R-VAR-22-11-01-E") {
                        // Hard code logic because Gov Allowance during that period.
                        result.data.results.forEach((e) => {
                            e.value_exc_vat = 31.2483;
                            e.value_inc_vat = 32.810715;
                        });
                    }
                    return result;
                })
                .then((result) => {
                    const convertedResult: OctopusConvertedTariff[] = [];
                    result.data.results.forEach((e) => {
                        const validFrom = moment(e['valid_from']);
                        const validTo = e['valid_to'] ? moment(e['valid_to']) : moment().endOf("day");
                        const paymentMethod = e['payment_method'];
                        if (paymentMethod === null || (paymentMethod && paymentMethod === "DIRECT_DEBIT")) {
                            if (validTo.diff(validFrom, "minutes") > 30) {
                                const times = validTo.clone().startOf("day").diff(validFrom, "hours", false) * 2;
                                const list: OctopusConvertedTariff[] = [];
                                for (let i = 0; i < times; i++) {
                                    list.push({
                                        validFrom: moment(validFrom).startOf("day").add(i * 30, "minutes").toISOString(),
                                        validTo: moment(validFrom).startOf("day").add((i + 1) * 30, "minutes").subtract(1, "millisecond").toISOString(),
                                        valueExcVat: e['value_exc_vat'],
                                        valueIncVat: e['value_inc_vat'],
                                        paymentMethod: paymentMethod,
                                    })
                                }
                                const reversedTariff: OctopusConvertedTariff[] = [ ...list ].reverse();
                                convertedResult.push(...reversedTariff);
                            } else {
                                convertedResult.push({
                                    validFrom: e.valid_from,
                                    validTo: `${e.valid_to}`,
                                    valueExcVat: e['value_exc_vat'],
                                    valueIncVat: e['value_inc_vat'],
                                    paymentMethod: "",
                                });
                            }
                        }
                    });
                    results.push(...convertedResult);
                    return result.data;
                })
                .then((result) => {
                    const next = result.next;
                    if (next) {
                        return dataProvider.fetchOctopusTariff(resource, {
                            dataProvider,
                            agreements,
                            results,
                            pageParam: pageParam + 1,
                        });
                        // return dataProvider.fetchOctopusTariffV4(agreements, results, pageParam + 1);
                    }
                    // End of Page
                    agreements.splice(0, 1);
                    // return dataProvider.fetchOctopusTariffV4(agreements, results, 1);
                    return dataProvider.fetchOctopusTariff(resource, {
                        dataProvider,
                        agreements,
                        results,
                        pageParam: 1,
                    });
                });
        },
        fetchOctopusConsumption: (resource: string, options: FetchOctopusConsumptionOptions): Promise<OctopusConvertedConsumption[]> => {
            const {
                token,
                dataProvider,
                agreements,
                results = [],
                pageParam = 1,
            } = options;

            if (agreements.length === 0)
                return Promise.resolve(results);

            const agreement = agreements[0];
            const { mpan, meterSerialNumber } = agreement;

            return axios.get<OctopusApiConsumptionResponse>(`https://api.octopus.energy/v1/electricity-meter-points/${mpan}/meters/${meterSerialNumber}/consumption/?page=${pageParam}&page_size=2000&period_from=${agreement.validFrom}&period_to=${agreement.validTo}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${token}`
                }
            }).then((res) => {
                return res.data;
            }).then((res) => {
                results.push(...res.results.map((result) => {
                    return {
                        consumption: result.consumption,
                        intervalStart: result.interval_start,
                        intervalEnd: result.interval_end,
                    };
                }));
                const next = res.next;
                if (next) {
                    return dataProvider.fetchOctopusConsumption(resource, {
                        token,
                        dataProvider,
                        agreements,
                        results,
                        pageParam: pageParam + 1,
                    });
                }
                // End of Page
                agreements.splice(0, 1);
                return dataProvider.fetchOctopusConsumption(resource, {
                    token,
                    dataProvider,
                    agreements,
                    results,
                    pageParam: 1,
                });
            });
        },

        fetchOctopusGasConsumption: (resource: string, options: FetchOctopusGasConsumptionOptions): Promise<OctopusGasConvertedConsumption[]> => {
            const {
                token,
                dataProvider,
                agreements,
                results = [],
                pageParam = 1,
            } = options;

            if (agreements.length === 0)
                return Promise.resolve(results);

            const agreement = agreements[0];
            const { mpan, meterSerialNumber } = agreement;

            return axios.get<OctopusApiConsumptionResponse>(`https://api.octopus.energy/v1/gas-meter-points/${mpan}/meters/${meterSerialNumber}/consumption/?page=${pageParam}&page_size=2000&period_from=${agreement.validFrom}&period_to=${agreement.validTo}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${token}`
                }
            }).then((res) => {
                return res.data;
            }).then((res: OctopusApiConsumptionResponse) => {
                results.push(...res.results.map((result) => {
                    return {
                        consumptionInMeterCube: result.consumption,
                        // Since the API returns m3 instead of kWh, need to convert it to kWh
                        consumptionInKWH: result.consumption * 1.02264 * 39.6 / 3.6,
                        intervalStart: result.interval_start,
                        intervalEnd: result.interval_end,
                    };
                }));
                const next = res.next;
                if (next) {
                    return dataProvider.fetchOctopusGasConsumption(resource, {
                        token,
                        dataProvider,
                        agreements,
                        results,
                        pageParam: pageParam + 1,
                    });
                }
                // End of Page
                agreements.splice(0, 1);
                return dataProvider.fetchOctopusGasConsumption(resource, {
                    token,
                    dataProvider,
                    agreements,
                    results,
                    pageParam: 1,
                });
            });
        },
        fetchOctopusStandingCharge: (resource, options) => {
            const {
                dataProvider,
                agreements,
                results = [],
                pageParam = 1,
            } = options;

            if (agreements.length === 0)
                return Promise.resolve(results);

            const currentAgreement = agreements[0];
            const productCode = currentAgreement.tariffCode.substring(5, currentAgreement.tariffCode.length - 2);

            const getValidFrom = (result: string) => {
                if (moment(result).isAfter(moment(currentAgreement.validFrom))) {
                    return moment(result).toDate().toISOString();
                } else {
                    return moment(currentAgreement.validFrom).toDate().toISOString();
                }
            }

            const getValidTo = (result: string | null) => {
                if (result) {
                    if (moment(result).isBetween(currentAgreement.validFrom, currentAgreement.validTo)) {
                        return moment(result).toISOString(false);
                    } else if (moment(result).isBefore(currentAgreement.validTo)) {
                        return moment(result).toISOString();
                    } else if (moment(result).isAfter(currentAgreement.validTo)) {
                        return moment(currentAgreement.validTo).subtract(1, "millisecond").toISOString();
                    }
                }
                if (currentAgreement.validTo) {
                    return moment(currentAgreement.validTo).subtract(1, "millisecond").toISOString();
                }
                return moment().endOf("day").toISOString(false);
            }

            const periodTo = (currentAgreement.validTo ? moment(currentAgreement.validTo) : moment().add(1, "day").endOf("day")).toISOString(false);
            return axios.get<OctopusApiStandardChargeResponse>(`https://api.octopus.energy/v1/products/${productCode}/electricity-tariffs/${currentAgreement.tariffCode}/standing-charges/?period_from=${moment(currentAgreement.validFrom).toISOString()}&period_to=${periodTo}&page=${pageParam}&page_size=2000`)
                .then((result) => {
                    const convertedResult: OctopusConvertedStandardCharge[] = [];
                    result.data.results.forEach((e) => {
                        if (e['payment_method'] === 'NON_DIRECT_DEBIT') {
                            // skip
                        } else {
                            convertedResult.push({
                                validFrom: getValidFrom(e['valid_from']),
                                validTo: getValidTo(e.valid_to),
                                valueExcVat: e['value_exc_vat'],
                                valueIncVat: e['value_inc_vat'],
                                paymentMethod: e.payment_method
                            });
                        }
                    });
                    results.push(...convertedResult);
                    return result.data;
                })
                .then((result) => {
                    const next = result.next;
                    if (next) {
                        return dataProvider.fetchOctopusStandingCharge(resource, {
                            dataProvider,
                            agreements,
                            results,
                            pageParam: pageParam + 1,
                        });
                    }
                    // End of Page
                    agreements.splice(0, 1);
                    return dataProvider.fetchOctopusStandingCharge(resource, {
                        dataProvider,
                        agreements,
                        results,
                        pageParam: 1,
                    });
                });
        },
        fetchOctopusGasTariff: (resource, { dataProvider, agreements, results = [], pageParam = 1 }) => {
            if (agreements.length === 0)
                return Promise.resolve(results);

            const { tariffCode, validFrom, validTo } = agreements[0];
            const productCode = tariffCode.substring(5, tariffCode.length - 2);

            return axios.get<OctopusApiTariffResponse>(`https://api.octopus.energy/v1/products/${productCode}/gas-tariffs/${tariffCode}/standard-unit-rates/?period_from=${validFrom}&period_to=${validTo}&page=${pageParam}&page_size=1500`)
                .then((result) => {
                    const convertedResult: OctopusConvertedTariff[] = [];
                    result.data.results.forEach((e) => {
                        const validFrom = moment(e['valid_from']);
                        const validTo = e['valid_to'] ? moment(e['valid_to']) : moment().endOf("day");
                        const paymentMethod = e['payment_method'];
                        if (paymentMethod === null || (paymentMethod && paymentMethod === "DIRECT_DEBIT")) {
                            if (validTo.diff(validFrom, "minutes") > 30) {
                                const times = validTo.clone().startOf("day").diff(validFrom, "hours", false) * 2;
                                const list: OctopusConvertedTariff[] = [];
                                for (let i = 0; i < times; i++) {
                                    list.push({
                                        validFrom: moment(validFrom).startOf("day").add(i * 30, "minutes").toISOString(),
                                        validTo: moment(validFrom).startOf("day").add((i + 1) * 30, "minutes").subtract(1, "millisecond").toISOString(),
                                        valueExcVat: e['value_exc_vat'],
                                        valueIncVat: e['value_inc_vat'],
                                        paymentMethod: paymentMethod,
                                    })
                                }
                                const reversedTariff: OctopusConvertedTariff[] = [ ...list ].reverse();
                                convertedResult.push(...reversedTariff);
                            } else {
                                convertedResult.push({
                                    validFrom: e.valid_from,
                                    validTo: `${e.valid_to}`,
                                    valueExcVat: e['value_exc_vat'],
                                    valueIncVat: e['value_inc_vat'],
                                    paymentMethod: "",
                                });
                            }
                        }
                    });
                    results.push(...convertedResult);
                    return result.data;
                })
                .then((result) => {
                    const next = result.next;
                    if (next) {
                        return dataProvider.fetchOctopusTariff(resource, {
                            dataProvider,
                            agreements,
                            results,
                            pageParam: pageParam + 1,
                        });
                        // return dataProvider.fetchOctopusTariffV4(agreements, results, pageParam + 1);
                    }
                    // End of Page
                    agreements.splice(0, 1);
                    // return dataProvider.fetchOctopusTariffV4(agreements, results, 1);
                    return dataProvider.fetchOctopusTariff(resource, {
                        dataProvider,
                        agreements,
                        results,
                        pageParam: 1,
                    });
                });
        },
        fetchOctopusGasStandingCharge: (resource, options) => {
            const {
                dataProvider,
                agreements,
                results = [],
                pageParam = 1,
            } = options;

            if (agreements.length === 0)
                return Promise.resolve(results);

            const currentAgreement = agreements[0];
            const productCode = currentAgreement.tariffCode.substring(5, currentAgreement.tariffCode.length - 2);

            const getValidFrom = (result: string) => {
                if (moment(result).isAfter(moment(currentAgreement.validFrom))) {
                    return moment(result).toDate().toISOString();
                } else {
                    return moment(currentAgreement.validFrom).toDate().toISOString();
                }
            }

            const getValidTo = (result: string | null) => {
                if (result) {
                    if (moment(result).isBetween(currentAgreement.validFrom, currentAgreement.validTo)) {
                        return moment(result).toISOString(false);
                    } else if (moment(result).isBefore(currentAgreement.validTo)) {
                        return moment(result).toISOString();
                    } else if (moment(result).isAfter(currentAgreement.validTo)) {
                        return moment(currentAgreement.validTo).subtract(1, "millisecond").toISOString();
                    }
                }
                if (currentAgreement.validTo) {
                    return moment(currentAgreement.validTo).subtract(1, "millisecond").toISOString();
                }
                return moment().endOf("day").toISOString(false);
            }

            const periodTo = (currentAgreement.validTo ? moment(currentAgreement.validTo) : moment().add(1, "day").endOf("day")).toISOString(false);
            return axios.get<OctopusApiStandardChargeResponse>(`https://api.octopus.energy/v1/products/${productCode}/gas-tariffs/${currentAgreement.tariffCode}/standing-charges/?period_from=${moment(currentAgreement.validFrom).toISOString()}&period_to=${periodTo}&page=${pageParam}&page_size=2000`)
                .then((result) => {
                    const convertedResult: OctopusConvertedStandardCharge[] = [];
                    result.data.results.forEach((e) => {
                        if (e['payment_method'] === 'NON_DIRECT_DEBIT') {
                            // skip
                        } else {
                            convertedResult.push({
                                validFrom: getValidFrom(e['valid_from']),
                                validTo: getValidTo(e.valid_to),
                                valueExcVat: e['value_exc_vat'],
                                valueIncVat: e['value_inc_vat'],
                                paymentMethod: e.payment_method
                            });
                        }
                    });
                    results.push(...convertedResult);
                    return result.data;
                })
                .then((result) => {
                    const next = result.next;
                    if (next) {
                        return dataProvider.fetchOctopusGasStandingCharge(resource, {
                            dataProvider,
                            agreements,
                            results,
                            pageParam: pageParam + 1,
                        });
                    }
                    // End of Page
                    agreements.splice(0, 1);
                    return dataProvider.fetchOctopusGasStandingCharge(resource, {
                        dataProvider,
                        agreements,
                        results,
                        pageParam: 1,
                    });
                });
        },
    }
}
