import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Datagrid, FunctionField, List, TextField } from "react-admin";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import CreateIconButton from "../../component/CreateIconButton";

const QuantityUnitList = () => {

    const quantityUnitListBreadcrumbItems: BreadcrumbItem[] =[
        {
            label: 'Quantity Units',
            isCurrent: true,
        },
    ];

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            empty={false}
            component="div"
            title="Quantity Unit Management"
        >
            <Box>
                <CustomBreadcrumbs title={"Quantity Unit Management"} items={quantityUnitListBreadcrumbItems} />
                <Card>
                    <CardHeader title="Quantity Units" action={<CreateIconButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )}/>
                            <TextField source="recordId" />
                            <TextField source="nameSingular" />
                            <TextField source="namePlural" />
                            <TextField source="description" />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );

}

export default QuantityUnitList;
