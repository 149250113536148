import { Resource } from "react-admin";
import ProductList from "./ProductList";
import ProductCreate from "./ProductCreate";
import ProductShow from "./ProductShow";
import ProductEdit from "./ProductEdit";
import ProductBarcode from "./barcode";
import { Route } from "react-router-dom";

const Product = (
    <Resource key="product"
              name="product"
              show={ProductShow}
              list={ProductList}
              create={ProductCreate}
              edit={ProductEdit}
    >
        <Route path="create" element={<ProductCreate />} />
        <Route path=":productId">
            <Route path="" element={<ProductEdit />} />
            {ProductBarcode}
        </Route>
    </Resource>
);

export default Product;
