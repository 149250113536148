import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import {
    Datagrid,
    DateField,
    FunctionField,
    Pagination,
    ReferenceManyField,
    SelectField,
    Show,
    TextField,
    useRedirect,
    useShowContext
} from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material";
import Loading from "../../../component/common/Loading";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";
import { PROJECT_STATUSES } from "../../constant/display";
import CustomShowButton from "../../../component/CustomShowButton";
import CreateIconButton from "../../../component/CreateIconButton";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";
import CustomEditButtonV2 from "../../../component/CustomEditButtonV2";

const ProjectShow = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Projects",
            resource: "project",
            type: "list",
        },
        {
            label: "Record",
            isCurrent: true,
        },
    ];

    const ProjectShowContent = () => {
        const { record, isLoading, refetch } = useShowContext();
        const redirect = useRedirect();

        if (isLoading)
            return <Loading />;

        if (! record)
            return <>Record Not Found</>;

        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 IconButtonProps={{ size: "small" }} />
                <CustomDeleteIconButtonV2
                    IconButtonProps={{ size: "small" }}
                    redirectTo="list"
                />
            </Stack>
        );

        return (
            <Box sx={{ width: "100%" }}>
                <Card>
                    <CardHeader title={`Project - ${record.name}`}
                                action={<CardHeaderAction />}
                    />
                    <Divider />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4}>
                                Record ID: {record.recordId}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Name: {record.name}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Description: {record.description}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Status: <SelectField source="status" choices={PROJECT_STATUSES} />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container>
                            <Grid item xs={12}>
                                <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                            </Grid>
                            <Grid item xs={12}>
                                <>Last Updated
                                    By {record.updatedBy} @ {new Date(record.updatedDate).toLocaleString()}</>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{ mt: 2 }}>
                    <CardHeader title="Roles"
                                action={<CreateIconButton customPath={`/admin/project/${record.id}/role/create`} />} />
                    <Divider />
                    <CardContent>

                        <ReferenceManyField reference="project/role"
                                            target="projectId"
                                            source="uuid"
                                            pagination={<Pagination />} perPage={5}
                                            sort={{ field: 'createdDate', order: 'DESC' }}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <FunctionField label="Actions" render={(role: any) => (
                                    <Stack component="div" spacing={1} direction="row">
                                        <CustomEditButton
                                            customPath={`/admin/project/${record.id}/role/${role.recordId}/edit`} />
                                        <CustomShowButton
                                            customPath={`/admin/project/${record.id}/role/${role.recordId}/show`} />
                                        <CustomDeleteIconButton />
                                    </Stack>
                                )} />
                                <TextField source="role" sortable={false} />
                                <TextField source="roleName" sortable={false} />
                                <TextField source="roleDescription" sortable={false} />
                                <DateField source="createdDate" sortable={true} showTime={true} />
                                <TextField source="createdBy" sortable={false} />
                            </Datagrid>
                        </ReferenceManyField>

                    </CardContent>
                </Card>
            </Box>
        )

    }

    return (
        <Show actions={false}
              component="div"
        >
            <Box>
                <CustomBreadcrumbs title="View" items={breadcrumbItems} />
                <ProjectShowContent />
            </Box>
        </Show>
    );

};

export default ProjectShow;
