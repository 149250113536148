import React from "react";
import { DateInput, SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";

const AnnouncementEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput name="message"
                                       source="message"
                                       fullWidth
                                       isRequired
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} md={6}>
                            <DateInput name="effectiveStartDate"
                                       source="effectiveStartDate"
                                       fullWidth
                                       isRequired
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <DateInput name="effectiveEndDate"
                                       source="effectiveEndDate"
                                       fullWidth
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    )

}

export default AnnouncementEditForm;
