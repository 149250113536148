import React from "react";
import { Box, SxProps } from "@mui/material";
import { BarcodeFormat } from "@zxing/library";
import BwipJs from "bwip-js";

const BarcodeV2: React.FC<{
    text: string;
    format: BarcodeFormat;
    sx?: SxProps;
}> = ({
          text,
          format,
          sx,
      }) => {

    const getBcid = (format: BarcodeFormat) => {
        switch(format) {
            case BarcodeFormat.CODE_128: return "code128";
            case BarcodeFormat.AZTEC: return "azteccodecompact";
            case BarcodeFormat.EAN_8: return "ean8";
            case BarcodeFormat.EAN_13: return "ean13";
            case BarcodeFormat.QR_CODE: return "qrcode";
            case BarcodeFormat.UPC_A: return "upca";
            case BarcodeFormat.UPC_E: return "upce";
            case BarcodeFormat.PDF_417: return "pdf417";
            case BarcodeFormat.CODE_39: return "code39";
            // Failed
            case BarcodeFormat.CODE_93: return "code93";
            case BarcodeFormat.ITF: return "itf14";
            case BarcodeFormat.DATA_MATRIX: return "datamatrix";

            case BarcodeFormat.MAXICODE:
            case BarcodeFormat.RSS_EXPANDED:
            case BarcodeFormat.CODABAR:
            case BarcodeFormat.UPC_EAN_EXTENSION:
            case BarcodeFormat.RSS_14:
                throw new Error("Format not support");
            default:
                throw new Error("unknown format");
        }
    };

    const getWidth = (format: BarcodeFormat) => {
        switch(format) {
            case BarcodeFormat.QR_CODE:
            case BarcodeFormat.AZTEC:
            case BarcodeFormat.DATA_MATRIX:
                return 100;
            default: return 180;
        }
    };

    const getHeight = (format: BarcodeFormat) => {
        switch(format) {
            case BarcodeFormat.QR_CODE:
            case BarcodeFormat.AZTEC:
            case BarcodeFormat.DATA_MATRIX:
                return 100;
            default: return 40;
        }
    };

    try {
        const barcodeSvg = BwipJs.toSVG({
            bcid: getBcid(format),
            text: text,
            backgroundcolor: "#fff",
            width: getWidth(format),
            height: getHeight(format),
            scaleX: 2,
            paddingwidth: 30,
            paddingheight: 30,
        });

        return (
            <Box
                sx={{ width: "180px", ...sx, }}
                dangerouslySetInnerHTML={{ __html: barcodeSvg }} />
        );
    } catch (e: any) {
        return (
            <Box>Barcode generation failed! - {e.message}</Box>
        );
    }
}

export default BarcodeV2;
