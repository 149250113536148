import ReactGA from "react-ga4";
import config from "./../config.json";
import { getUserUuid } from "../auth/AuthUtils";

const setUserUuid = () => {
    if (!config.GOOGLE_ANALYTICS_ENABLED)
        return;
    const userUuid = getUserUuid();
    ReactGA.set({ userId: userUuid ? userUuid : "N/A" });
    console.log("USER-UUID: ", userUuid ? userUuid : "N/A")
};

const trackEvent = (category: string, action: string, label: string) => {
    // don't track event if Google Analytics
    // hasn't been initialized (for example, local environment)
    if (!config.GOOGLE_ANALYTICS_ENABLED)
        return;

    // all the events we track here are page interaction events
    ReactGA.event("home_cloud_ui_v2_page_interaction", {
        category: category,
        action: action,
        label: label,
    });
};

export { setUserUuid, trackEvent };