import React from "react";
import { Resource } from "react-admin";
import RetailerList from "./RetailerList";
import RetailerCreate from "./RetailerCreate";
import RetailerEdit from "./RetailerEdit";
import RetailerShow from "./RetailerShow";

const Retailer = (
    <Resource key="retailer"
              name="retailer"
              list={RetailerList}
              create={RetailerCreate}
              edit={RetailerEdit}
              show={RetailerShow}
    />
);

export default Retailer;
