import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { SHOP_STATUS } from "./ShopConstant";
import { useParams } from "react-router-dom";

const ShopEdit = () => {
    const { id } = useParams();
    const shopEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Shops',
            isCurrent: false,
            resource: 'shop',
            type: 'list',
        },
        {
            label: 'Shop',
            isCurrent: false,
            resource: 'shop',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
              component="div"
              title="Shop Management"
              mutationMode="pessimistic"
        >
            <CustomBreadcrumbs title="Edit Shop" items={shopEditBreadcrumbItems} />
            <Box sx={{ width: '100%' }}>
                <SimpleForm>
                    <Box sx={{ width: '100%' }}>
                        <Card>
                            <CardContent sx={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput label="Record ID" name="recordId" source="recordId" fullWidth
                                                   variant="standard" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput source="name" fullWidth variant="standard" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput source="description" fullWidth variant="standard" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <SelectInput source="status" fullWidth variant="standard"
                                                     choices={SHOP_STATUS} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </SimpleForm>
            </Box>
        </Edit>
    );
};

export default ShopEdit;
