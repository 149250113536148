import CampaignIcon from '@mui/icons-material/Campaign';
import AnnouncementList from "./AnnouncementList";
import { Resource } from "react-admin";
import AnnouncementCreate from "./AnnouncementCreate";
import AnnouncementEdit from "./AnnouncementEdit";

const Product = (
    <Resource key="announcement"
              name="announcement"
        // show={ProductShow}
              list={AnnouncementList}
              create={AnnouncementCreate}
              edit={AnnouncementEdit}
              icon={CampaignIcon}
    />
);

export default Product;
