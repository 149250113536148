import { CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import BnoCalculation from "./BnoCalculation";

const Bno = (
    <CustomRoutes>
        <Route path="/bno/calculation" element={<BnoCalculation />} />
    </CustomRoutes>
);

export default Bno;
