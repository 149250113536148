import { Resource } from "react-admin";
import UserCreate from "./UserCreate";
import UserList from "./UserList";
import UserShow from "./UserShow";
import { Route } from "react-router-dom";
import UserProjectRoleView from "./UserProjectRoleView";
import UserEdit from "./UserEdit";

const User = (
    <Resource key="user"
              name="user"
              create={UserCreate}
              list={UserList}
              show={UserShow}
    >
        <Route path=":userId">
            <Route path="" element={<UserEdit />} />
            <Route path="project/:projectUuid/role" element={<UserProjectRoleView />} />
        </Route>
    </Resource>
);

export default User;
