import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import Box from "@mui/material/Box";
import QuantityUnitEditForm from "./QuantityUnitEditForm";

const QuantityUnitCreate = () => {

    const quantityUnitCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Quantity Units',
            isCurrent: false,
            resource: 'quantityUnit',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Quantity Unit Management"
                redirect="list"
        >
            <Box>
                <CustomBreadcrumbs title="Create Quantity Unit" items={quantityUnitCreateBreadcrumbItems} />
                <QuantityUnitEditForm type="create" />
            </Box>
        </Create>
    );

}

export default QuantityUnitCreate;
