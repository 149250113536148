import { Resource } from "react-admin";
import { Route } from "react-router-dom";
import EquipmentList from "./EquipmentList";
import EquipmentCreate from "./EquipmentCreate";
import EquipmentEdit from "./EquipmentEdit";
import EquipmentShow from "./EquipmentShow";
import EquipmentNoteCreate from "./note/EquipmentNoteCreate";
import EquipmentNoteEdit from "./note/EquipmentNoteEdit";

const Equipment = (
    <Resource
        key="equipment"
        name="equipment"
        create={EquipmentCreate}
        edit={EquipmentEdit}
    >
        <Route path="" element={<EquipmentList />} />
        <Route path=":id/show/*" element={<EquipmentShow />} />
        <Route path=":equipmentId/note/create" element={<EquipmentNoteCreate />} />
        <Route path=":equipmentId/note/:id/edit" element={<EquipmentNoteEdit />} />
    </Resource>
);

export default Equipment;
