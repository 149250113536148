import { QueryClient } from "@tanstack/react-query";

const cleanRelatedCaches = (queryClient: QueryClient, userId: number) => {
    queryClient.invalidateQueries({
        predicate: (query) => {
            return (
                    // clear reference cache
                    query.queryKey.length === 3 &&
                    query.queryKey[0] === "project" &&
                    query.queryKey[1] === "getManyReference" &&
                    (query.queryKey[2] as any)?.target === "userIds" &&
                    (query.queryKey[2] as any)?.id === userId) ||
                // clear project List cache
                (query.queryKey.length === 3 &&
                    query.queryKey[0] === "project" &&
                    query.queryKey[1] === "getList" &&
                    (query.queryKey[2] as any)?.filter?.unassignedOnly &&
                    (query.queryKey[2] as any)?.filter?.userIds === userId)
        }
    });
};

const cleanUserProjectRolesCache = (queryClient: QueryClient, userUuid: string, projectUuid: string) => {
    queryClient.invalidateQueries({
        queryKey: [ 'super', 'user/project/roles', projectUuid, userUuid ],
    });
};

export {
    cleanRelatedCaches,
    cleanUserProjectRolesCache,
};