import CategoryIcon from "@mui/icons-material/Bookmark";
import StockLocationList from "./StockLocationList";
import StockLocationCreate from "./StockLocationCreate";
import StockLocationEdit from "./StockLocationEdit";
import StockLocationShow from "./StockLocationShow";
import { Resource } from "react-admin";
import React from "react";

const StockLocation = (
    <Resource key="stockLocation"
              name="stock/location"
              list={StockLocationList}
              show={StockLocationShow}
              create={StockLocationCreate}
              edit={StockLocationEdit}
              icon={CategoryIcon}
    />
);

export default StockLocation;
