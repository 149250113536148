import axios from "axios";
import config from "../../../config.json";
import { getAuth } from "../../../auth/AuthUtils";

const getFileDetails = async (projectUuid: string, userUuid: string, fileId: string) => {
    return axios.get<FileV2>(`${config.BASE_URL}/project/${projectUuid}/user/${userUuid}/document/file/${fileId}/details`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuth()}`
        }
    }).then(result => result.data);
};

export default getFileDetails;
