import React, { useState } from "react";
import { Box, ClickAwayListener, Fab, Stack, Zoom } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomEditButtonV2 from "./CustomEditButtonV2";
import CustomDeleteIconButtonV2 from "./CustomDeleteIconButtonV2";

const SmallFloatingButtonGroup = () => {

    const [ isOpen, setOpen ] = useState(false);
    const handleClickAway = () => {
        if (! isOpen) return;
        setOpen(false);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box component="div"
                 sx={{
                     zIndex: 222,
                     position: "fixed",
                     bottom: 16,
                     right: 16,
                 }}
            >
                <Stack direction="column-reverse" spacing={2}
                       onClick={() => {
                           setOpen(! isOpen);
                       }}>
                    <Fab color="primary"
                    >
                        <ExpandMoreIcon sx={{
                            transition: "transform 150ms ease",
                            transform: isOpen ? "rotate(0)" : "rotate(-180deg)",
                        }} />
                    </Fab>
                    <Zoom in={isOpen}>
                        <CustomEditButtonV2 isFloating />
                    </Zoom>
                    <Zoom in={isOpen}>
                        <CustomDeleteIconButtonV2 isFloating />
                    </Zoom>
                </Stack>

            </Box>
        </ClickAwayListener>
    );
}

export default SmallFloatingButtonGroup;
