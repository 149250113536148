import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { CreateButton, Datagrid, FunctionField, List, SelectField, TextField } from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import { COMMON_STATUS } from "../../constant/CommonStatus";

const BrandList = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Brands",
            isCurrent: true,
        },
    ];

    return (
        <List actions={false}
              exporter={false}
              sort={{ field: 'recordId', order: 'ASC' }}
              perPage={25}
              empty={false}
              component="div"
              title="Brand Management"
        >
            <Box>
                <CustomBreadcrumbs title="Brand Management" items={breadcrumbItems} />
                <Card>
                    <CardHeader title="Bands" action={<CreateButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )} />
                            <TextField source="recordId" />
                            <TextField source="name" />
                            <TextField source="description" />
                            <SelectField source="status" choices={COMMON_STATUS} />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );
};

export default BrandList;
