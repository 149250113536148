export const SHOP_STATUS = [
    {
        id: 'A',
        name: 'Active',
    },
    {
        id: 'D',
        name: 'Disabled',
    },
    {
        id: 'X',
        name: 'Deleted',
    },
    {
        id: 'U',
        name: 'Unknown',
    },
];
