import React, { Fragment } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { SelectInput, SimpleForm, TextInput } from "react-admin";
import { PROJECT_STATUS, PROJECT_STATUSES } from "../../constant/display";

const ProjectEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Fragment>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput name="recordId"
                                               label="ID"
                                               source="recordId"
                                               fullWidth
                                               disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput name="uuid"
                                               label="UUID"
                                               source="uuid"
                                               fullWidth
                                               disabled
                                    />
                                </Grid>
                            </Fragment>
                        }

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="description"
                                       source="description"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         source="status"
                                         choices={PROJECT_STATUSES}
                                         fullWidth
                                         variant="outlined"
                                         defaultValue={PROJECT_STATUS.ACTIVE}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
};

export default ProjectEditForm;
