import React, { Fragment } from "react";
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Link,
    Stack,
    Typography
} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from "@mui/material/Unstable_Grid2";
import ReportIcon from "@mui/icons-material/Report";
import { Link as RouterLink, useParams } from "react-router-dom";
import DocumentBreadcrumbs from "./component/DocumentBreadcrumbs";
import useGetFolderDetails from "./data/useGetFolderDetails";
import moment from "moment";
import useGetTags from "./data/useGetTags";
import { Title } from "react-admin";
import FolderCreateButton from "./component/documentExplorer/FolderCreateButton";

const DocumentExplorer = () => {

    const { folderId } = useParams();

    const { data: currentFolder, isLoading } = useGetFolderDetails(folderId);
    const { data: tags, isLoading: isTagsLoading } = useGetTags();

    if (isLoading || !currentFolder)
        return "Loading...";

    return (
        <Fragment>
            <Title title="Document Explorer" />
            <Alert color="info" sx={{ my: 1 }} icon={<ReportIcon />}>Experiment function, mobile view is not supported</Alert>

            <DocumentBreadcrumbs folderDetails={currentFolder} />

            <Card sx={{ my: 1 }}>
                <CardHeader
                    title="Folders"
                    action={<FolderCreateButton />}
                />
                <CardContent>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {currentFolder.children?.map((folder, index) => (
                            <Grid xs={2} sm={4} md={3} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={<FolderIcon sx={{ color: "yellow" }} />}
                                        title={
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                to={`/document/explorer/${folder.recordId}`}
                                            >
                                                {folder.name}
                                            </Link>
                                        }
                                        subheader={folder.description}
                                        action={<IconButton><MoreHorizIcon /></IconButton>}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>

            <Card sx={{ my: 1 }}>
                <CardHeader title="Files" />
                <CardContent>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {currentFolder.files?.map((file, index) => (
                            <Grid xs={2} sm={4} md={3} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={<DescriptionIcon sx={{ color: "text.secondary" }} />}
                                        title={
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                to={`/document/preview/${file.recordId}`}
                                            >
                                                {file.filename}
                                            </Link>
                                        }
                                        action={<IconButton><MoreHorizIcon /></IconButton>}
                                    />
                                    <CardContent>
                                        {file.tags && file.tags.length > 0 && ! isTagsLoading && tags && (
                                            <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                                                {file.tags.map((tagId, index) => (
                                                    tags.map[tagId] ?
                                                        <Chip sx={{ backgroundColor: tags.map[tagId].color }}
                                                              size="small" label={tags.map[tagId].name} />
                                                        : null
                                                ))}
                                            </Stack>
                                        )}
                                        <Typography variant="body2" color="text.secondary">Uploaded: {moment(file.createdDate).format("YYYY-MM-DD")} by {file.createdBy}</Typography>
                                        <Typography variant="body2" color="text.secondary">Modified: {moment(file.updatedDate).format("YYYY-MM-DD")} by {file.updatedBy}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default DocumentExplorer;
