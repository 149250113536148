import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../../component/CustomBreadcrumbs";
import { Create, useNotify, useRedirect } from "react-admin";
import ProjectRoleEditForm from "./ProjectRoleEditForm";
import { useParams } from "react-router-dom";

const ProjectRoleCreate = () => {

    const { projectId } = useParams();
    const notify = useNotify();
    const redirect = useRedirect();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Projects',
            resource: 'project',
            type: 'list',
        },
        {
            label: "Record",
            resource: "project",
            type: "show",
            id: projectId,
        },
        {
            label: "Role",
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Project Role Management"
                resource="project/role"
                mutationOptions={{
                    meta: {
                        projectId: projectId,
                    },
                }}
                redirect={`/admin/project/${projectId}/show`}
        >
            <CustomBreadcrumbs title="Project Role Management" items={breadcrumbItems} />
            <ProjectRoleEditForm type="create" />
        </Create>
    )
}

export default ProjectRoleCreate;
