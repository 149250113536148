import axios from "axios";
import config from "../../../config.json";
import { getAuth } from "../../../auth/AuthUtils";

const getPreviewUrl = (projectUuid: string, userUuid: string, filepath: string, storeType: string = "Microsoft") => {
    return axios.get(`${config.DOCUMENT_API_BASE_URL}/project/${projectUuid}/user/${userUuid}/${storeType}/preview/${filepath}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuth()}`
        }
    });
};

export default getPreviewUrl;
