import React from "react";
import {
    DateField,
    FunctionField,
    RecordContextProvider,
    SelectField,
    useListContext,
    useTranslate
} from "react-admin";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import Grid from "@mui/material/Unstable_Grid2";
import { WARNING } from "../../constant/Color";
import VoucherStatus from "./constant/VoucherStatus";
import VoucherBarcodeDialog from "./VoucherBarcodeDialog";

const VoucherMobileGrid = () => {
    const translate = useTranslate();
    const { data, isLoading } = useListContext<Voucher>();

    if (isLoading || !data || data.length === 0) {
        return null;
    }

    return (
        <Box>
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card
                        sx={{
                            mb: 3,
                            backgroundColor: WARNING.normal,
                            "&:hover": {
                                backgroundColor: WARNING.hover,
                            },
                        }}
                    >
                        <CardHeader title={record.name}
                                    action={<CustomShowButton />}
                        />
                        <CardContent>
                            <Grid container>
                                <Grid xs={4}>
                                    <FunctionField render={(record: Voucher) => (
                                        <>Total £{record.amount.toFixed(2)}</>
                                    )} />
                                </Grid>
                                <Grid xs={4}>
                                    <FunctionField render={(record: any) => (
                                                       <>£{(+(record.amount.toFixed(2)) -
                                                           +(record.usedAmount.toFixed(2))).toFixed(2)} left</>
                                                   )}
                                    />
                                </Grid>
                                <Grid xs={4}>
                                    <FunctionField render={(record: any) => (
                                        <>£{record.usedAmount.toFixed(2)} used</>
                                    )} />
                                </Grid>
                                <Grid xs={4}>
                                    <SelectField source="status"
                                                 label="resources.voucher.common.status"
                                                 choices={VoucherStatus}
                                    />
                                </Grid>
                                <Grid xs={4}>
                                    <DateField source="purchaseDate" />
                                </Grid>
                                <FunctionField label="BC"
                                               render={(record: Voucher) => (
                                                   record.barcodeText && record.barcodeType &&
                                                   <VoucherBarcodeDialog barcodeText={record.barcodeText}
                                                                         barcodeType={record.barcodeType} small />
                                               )} />
                            </Grid>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default VoucherMobileGrid;
