import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import { Moment } from "moment";
import MathUtils from "../../../../helper/MathUtils";
import StarIcon from '@mui/icons-material/Star';
import moment from "moment-timezone";
import { OctopusConvertedTariff } from "../types/types";

const OctopusTariffTableV4: React.FC<{ results: OctopusConvertedTariff[] }> = ({ results }) => {

    moment.tz.setDefault("Europe/London");

    const values = results.reduce((p: number[], item) => {
        p.push(item.valueIncVat);
        return p;
    }, []);

    const smallest = Math.min(...values);
    const biggest = Math.max(...values);
    const q25 = MathUtils.q25(values);
    const q50 = MathUtils.q50(values);
    const q75 = MathUtils.q75(values);

    if (! results || results.length === 0) {
        return null;
    }

    const isCurrentTimestamp = (record: OctopusConvertedTariff) => {
        return moment().isBetween(moment(record.validFrom), moment(record.validTo));
    }

    const OctopusTariffBox: React.FC<{result: OctopusConvertedTariff}> = ({ result }) => {

        const getClassNames = (value: number, validFrom: Moment, validTo: Moment) => {
            const className: string[] = [];

            if (value <= 0) {
                className.push("zeroOrNegative");
            }

            if (value === smallest) {
                className.push("min");
            }

            if (value <= q25) {
                className.push("q1");
            } else if (value > q25 && value <= q50) {
                className.push("q2");
            } else if (value > q50 && value <= q75) {
                className.push("q3");
            } else {
                className.push("q4");
            }


            if (value === biggest) {
                className.push("max");
            }

            if (moment().isBetween(validFrom, validTo)) {
                className.push("current");
            }

            return className.join(" ");
        }

        return (
            <Paper elevation={3}
                   className={getClassNames(result.valueIncVat, moment(result.validFrom), moment(result.validTo))}
                   sx={{
                       p: 1,
                       backgroundColor: isCurrentTimestamp(result) ? "#7aaede" : "#fff",
                       "&.q1": {
                           backgroundColor: "rgba(52, 195, 143, 0.75)",
                           color: "#fff",
                       },
                       "&.q2": {
                           backgroundColor: "rgba(241, 180, 76, 0.25)",
                           color: "rgba(241, 180, 76)",
                       },
                       "&.q3": {
                           backgroundColor: "rgba(241, 180, 76, 0.80)",
                           color: "#fff",
                       },
                       "&.q4": {
                           backgroundColor: "rgba(244, 106, 106, 0.80)",
                           color: "#fff",
                       },
                       "&.max": {
                           backgroundColor: "rgba(244, 106, 106, 1)",
                           color: "#fff",
                       },
                       "&.min": {
                           backgroundColor: "rgba(52, 195, 143)",
                           color: "#fff",
                       },
                       "&.zeroOrNegative": {
                           backgroundColor: "#fff",
                           color: "rgba(52, 195, 143)",
                           animation: "1s infinite bgcolorchange",
                           // background: "linear-gradient(to right, orange , yellow, green, cyan, blue, violet)", /* Standard syntax (must be last) */
                       },
                       "&.current": {
                           color: "#fff",
                           backgroundColor: "#74788d",
                           "&.q1": {
                               boxShadow: "0px 3px 3px -2px rgba(52, 195, 143, 0.75)," +
                                   "0px 3px 4px 0px rgba(52, 195, 143, 0.75)," +
                                   "0px 1px 8px 0px rgba(52, 195, 143, 0.75)",
                           },
                           "&.q2": {
                               boxShadow: "0px 3px 3px -2px rgba(241, 180, 76, 0.25)," +
                                   "0px 3px 4px 0px rgba(241, 180, 76, 0.25)," +
                                   "0px 1px 8px 0px rgba(241, 180, 76, 0.25)",
                           },
                           "&.q3": {
                               boxShadow: "0px 3px 3px -2px rgba(241, 180, 76, 0.80)," +
                                   "0px 3px 4px 0px rgba(241, 180, 76, 0.80)," +
                                   "0px 1px 8px 0px rgba(241, 180, 76, 0.80)",
                           },
                           "&.q4": {
                               boxShadow: "0px 3px 3px -2px rgba(244, 106, 106, 0.80)," +
                                   "0px 3px 4px 0px rgba(244, 106, 106, 0.80)," +
                                   "0px 1px 8px 0px rgba(244, 106, 106, 0.80)",
                           },
                           "&.max": {
                               boxShadow: "0px 3px 3px -2px rgba(244, 106, 106, 1)," +
                                   "0px 3px 4px 0px rgba(244, 106, 106, 1)," +
                                   "0px 1px 8px 0px rgba(244, 106, 106, 1)",
                           },
                           "&.min": {
                               boxShadow: "0px 3px 3px -2px rgba(52, 195, 143)," +
                                   "0px 3px 4px 0px rgba(52, 195, 143)," +
                                   "0px 1px 8px 0px rgba(52, 195, 143)",
                           },
                       },
                       "@keyframes bgcolorchange": {
                           "0%": {
                               boxShadow: "0px 3px 3px -2px rgba(241, 180, 76, 0.80)," +
                                   "0px 3px 4px 0px rgba(241, 180, 76, 0.80)," +
                                   "0px 1px 8px 0px rgba(241, 180, 76, 0.80)",
                           },
                           "25%": {
                               boxShadow: "0px 3px 3px -2px rgba(52, 195, 143)," +
                                   "0px 3px 4px 0px rgba(52, 195, 143)," +
                                   "0px 1px 8px 0px rgba(52, 195, 143)",
                           },
                           "50%": {
                               boxShadow: "0px 3px 3px -2px rgba(244, 106, 106, 1)," +
                                   "0px 3px 4px 0px rgba(244, 106, 106, 1)," +
                                   "0px 1px 8px 0px rgba(244, 106, 106, 1)",
                           },
                           "75%": {
                               boxShadowColor: "rgba(52, 195, 143)",
                               boxShadow: "0px 3px 3px -2px rgba(52, 195, 143)," +
                                   "0px 3px 4px 0px rgba(52, 195, 143)," +
                                   "0px 1px 8px 0px rgba(52, 195, 143)",
                           },
                           "100%": {
                               boxShadow: "0px 3px 3px -2px rgba(241, 180, 76, 0.80)," +
                                   "0px 3px 4px 0px rgba(241, 180, 76, 0.80)," +
                                   "0px 1px 8px 0px rgba(241, 180, 76, 0.80)",
                           },
                       },
                   }}
            >
                <Box sx={{ fontSize: "14px" }}>
                    {moment(result.validFrom).format("HH:mm")} - {moment(result.validTo).format("HH:mm")}
                </Box>
                <Box sx={{ fontSize: "18px" }}>
                    { result.valueIncVat }
                    { result.valueIncVat === smallest ||result.valueIncVat === biggest ? <StarIcon sx={{ height: "12px", width: "12px" }} /> : null}
                </Box>
            </Paper>
        );
    };

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={2}>
                {results.map((result, index) => (
                    <Grid item key={`row-${index}`} xs={4} md={3} xl={1}>
                        <OctopusTariffBox result={result} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OctopusTariffTableV4;
