import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import {
    CreateButton,
    Datagrid,
    FilterForm,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    SelectArrayInput,
    TextField,
    TextInput,
    useListContext,
} from "react-admin";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    Tooltip,
} from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { COMMON_STATUS } from "../../constant/CommonStatus";
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import NewScanner from "../scanner/NewScanner";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

const ProductList = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Products",
            isCurrent: true,
        },
    ];

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const codeReader = useRef(new BrowserMultiFormatReader());

    useEffect(() => {
        if (! dialogOpen) {
            codeReader.current.reset();
        }
    }, [ dialogOpen ]);

    const dialogOpenHandler = () => {
        setDialogOpen(true);
    }

    const dialogCloseHandler = () => {
        codeReader.current.reset();
        setDialogOpen(false);
    }

    const ScannerDialog = () => {

        const { displayedFilters, setFilters, filterValues } = useListContext();

        const resultHandler = (result: Result) => {
            // setValue("code", result.getText(), { shouldTouch: true, shouldDirty: true, });
            // setValue("format", convertToFormatName(result.getBarcodeFormat()), {
            //     shouldTouch: true,
            //     shouldDirty: true,
            // });
            setFilters({
                ...filterValues,
                barcode: result.getText(),
            }, displayedFilters);

            setDialogOpen(false);
        }

        return (
            dialogOpen ? (
                <Dialog open={dialogOpen}
                        onClose={dialogCloseHandler}
                >
                    <NewScanner resultHandler={resultHandler}
                                codeReader={codeReader}
                    />
                </Dialog>
            ) : null
        )

    }

    const listFilters = [
        <TextInput label="Search" source="q" name="q" alwaysOn InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                </InputAdornment>
            ),
        }} />,
        <TextInput label="Record ID" source="recordId" name="recordId" alwaysOn />,
        <TextInput label="Name" source="name" name="name" alwaysOn />,
        <TextInput label="Barcode"
                   source="barcode"
                   name="barcode"
                   alwaysOn
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <IconButton edge="end" onClick={() => dialogOpenHandler()}>
                                   <AddAPhotoOutlinedIcon />
                               </IconButton>
                           </InputAdornment>
                       ),
                   }}
        />,
        <SelectArrayInput source="statuses" name="statuses" choices={COMMON_STATUS} alwaysOn variant="outlined" sx={{
            "& .RaSelectArrayInput-chip": {
                my: 0,
            },
        }} />,
    ];

    const defaultFilters = {
        statuses: [ "A" ],
    }

    const ListFilter = () => {
        const { setFilters } = useListContext();

        return (
            <Stack direction="row" justifyContent="space-between">
                <FilterForm filters={listFilters} />
                <Button onClick={() => setFilters(defaultFilters, {})}>Reset Filter</Button>
                <ScannerDialog />
            </Stack>
        );
    }

    return (
        <List actions={false}
              exporter={false}
              sort={{ field: "recordId", order: "ASC" }}
              perPage={25}
              empty={false}
              component="div"
              title="Product Management"
              filterDefaultValues={defaultFilters}
        >
            <Box>
                <CustomBreadcrumbs title="Product Management" items={breadcrumbItems} />
                <Card>
                    <CardHeader title="Products" action={<CreateButton />} />
                    <Divider />
                    <CardContent sx={{ padding: "2px 16px" }}>
                        <ListFilter />
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )} />
                            <TextField source="recordId" label="#" />
                            <FunctionField label="Name" sortable sortBy="name" render={(record: any) => (
                                <>
                                    {record.name}
                                    {record.imagePath && (
                                        <Tooltip
                                            title="This product has image"
                                            disableInteractive
                                        >
                                            <ImageOutlinedIcon />
                                        </Tooltip>
                                    )}
                                </>
                            )} />
                            <NumberField source="minStockAmount" textAlign="left" />
                            <ReferenceField sortable={false} reference="productGroup" source="productGroupId" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField sortable={false} reference="brand" source="brandId" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    )

}

export default ProductList;
