import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import VoucherConsumptionEdit from "./VoucherConsumptionEdit";
import VoucherConsumptionCreate from "./VoucherConsumptionCreate";
import VoucherConsumptionShow from "./VoucherConsumptionShow";

const VoucherConsumption = (
    <Fragment>
        <Route path=":voucherId/consumption/create" element={<VoucherConsumptionCreate />} />
        <Route path=":voucherId/consumption/:consumptionId/edit" element={<VoucherConsumptionEdit />} />
        <Route path=":voucherId/consumption/:consumptionId/show" element={<VoucherConsumptionShow />} />
    </Fragment>
);

export default VoucherConsumption;
