import React, { Fragment, useMemo, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    SwipeableDrawer,
    Typography,
} from "@mui/material";
import moment from "moment";
import {
    useGasStandingChargeV4,
    useOctopusGasData,
    useOctopusGasTariffV4, useStandingChargeV4,
} from "./data/useOctopusData";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocaleState } from "ra-core";
import OctopusConsumptionTable from "./component/consumption/OctopusConsumptionTable";
import OctopusConsumptionChart from "./component/consumption/OctopusConsumptionChart";
import {
    OctopusAccount,
    OctopusAgreement,
    OctopusPeriodUsageMap,
    OctopusProperty,
    TariffSearchPeriod
} from "./types/types";
import OctopusConsumptionDayChart from "./component/consumption/OctopusConsumptionDayChart";

export type OctopusV4TariffProps = {
    account: OctopusAccount;
    selectedProperty: OctopusProperty;
};

const OctopusGasConsumption: React.FC<OctopusV4TariffProps> = ({ account, selectedProperty }) => {

    const [ isDrawerOpen, setDrawerOpen ] = useState(false);
    const [ error, setError ] = useState<string | null>(null);
    const [ locale ] = useLocaleState();

    const [ period, setPeriod ] = useState<TariffSearchPeriod>({
        start: moment().startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
    });

    const [ tempPeriod, setTempPeriod ] = useState<TariffSearchPeriod>({ ...period });

    const periodAgreements = useMemo(() => {
        const finalAgreements: OctopusAgreement[] = [];

        if (error)
            return finalAgreements;

        selectedProperty.agreements.filter(agreement => agreement.type === "G")
            .forEach((agreement) => {
                if (moment(period.start).isBetween(agreement.validFrom, agreement.validTo, "day", "[]")) {
                    finalAgreements.push({
                        ...agreement,
                        validFrom: period.start.toISOString(),
                        validTo: moment(agreement.validTo).isAfter(period.end) ? period.end.toISOString() : agreement.validTo,
                    });
                } else if (moment(period.end).isBetween(agreement.validFrom, agreement.validTo, "day", "[]")) {
                    finalAgreements.push({
                        ...agreement,
                        validFrom: moment(agreement.validFrom).isBefore(period.start) ? period.start.toISOString() : agreement.validFrom,
                        validTo: period.end.toISOString(),
                    });
                } else if (moment(agreement.validFrom).isBetween(period.start, period.end, "day", "[]") && moment(agreement.validTo).isBetween(period.start, period.end, "day", "[]")) {
                    finalAgreements.push({ ...agreement });
                }
            });

        return finalAgreements;
    }, [ error, selectedProperty.agreements, period.start, period.end ]);


    const {
        isError,
        error: tariffError,
        data: tariffs,
        isLoading: isTariffLoading,
        isFetching,
        refetch
    } = useOctopusGasTariffV4(periodAgreements);
    const {
        isError: isConsumptionError,
        error: ConsumptionError,
        data: consumptions,
        isLoading: isConsumptionLoading,
        isFetching:  isConsumptionFetching,
        refetch: consumptionRefetch,
    } = useOctopusGasData(account.token, periodAgreements);

    const halfHourMapping = useMemo(() => {
        if (consumptions && tariffs) {
            const tariffDateTimeMappings: OctopusPeriodUsageMap =
                tariffs.sort((a, b) => moment(a.validFrom).isBefore(b.validFrom) ? -1 : 1)
                    .reduce((prev: OctopusPeriodUsageMap, current) => {
                        const key = moment(current.validFrom).format("YYYY-MM-DD'T'HH:mm");
                        prev[key] = {
                            from: current.validFrom,
                            to: moment(current.validTo).subtract(1, "millisecond").toISOString(false),
                            consumption: 0.0,
                            cost: 0.0,
                            costPerKwh: current.valueIncVat,
                        }
                        return prev;
                    }, {});

            consumptions.forEach((consumption, _) => {
                const key = moment(consumption.intervalStart).format("YYYY-MM-DD'T'HH:mm");

                if (tariffDateTimeMappings[key]) {
                    tariffDateTimeMappings[key].cost = consumption.consumptionInKWH * tariffDateTimeMappings[key].costPerKwh;
                    tariffDateTimeMappings[key].consumption = consumption.consumptionInKWH;
                }
            });

            return tariffDateTimeMappings;
        }
        return {};
    }, [ consumptions, tariffs ]);

    const {
        isError: isStandingChargeError,
        isLoading: isStandingChargeLoading,
        data: standingCharges
    } = useGasStandingChargeV4(periodAgreements);

    const getDayStandingCharge = (date: string): number => {
        if (! standingCharges) return 0.0;
        const standingChargeObject = standingCharges.find(agreement => moment(date).isBetween(agreement.validFrom, agreement.validTo, "day", "[]"));
        if (! standingChargeObject) {
            console.error("Unable to find the corresponding standing charge record");
            return 0.0;
        }
        return standingChargeObject.valueIncVat;
    }

    const dayMapping = useMemo(() => {
        if (Object.keys(halfHourMapping).length > 0) {
            const dayMapping = Object.keys(halfHourMapping).reduce((prev: OctopusPeriodUsageMap, curr: string) => {
                const { from, to, consumption, cost } = halfHourMapping[curr];
                const key = moment(from).format("YYYY-MM-DD");
                if (! prev[key]) {
                    prev[key] = {
                        from: moment(from).startOf("day").toISOString(false),
                        to: moment(to).endOf("day").toISOString(false),
                        consumption: 0.0,
                        cost: 0.0,
                        costPerKwh: 0.0,
                    }
                }
                prev[key].consumption += consumption;
                prev[key].cost += cost;

                return prev;
            }, {});

            // Calc the cost per kWh by 'day'
            Object.keys(dayMapping).forEach(key => {
                const { from, cost, consumption } = dayMapping[key];
                dayMapping[key].costPerKwh = cost > 0 ? cost / consumption : 0.0;
                dayMapping[key].standingCharge = getDayStandingCharge(from);
            });

            return dayMapping;
        }
        return {};
    }, [getDayStandingCharge, halfHourMapping]);

    console.log("dayMapping: ", dayMapping);

    const { totalDays, totalCost, totalConsumptions } = useMemo(() => {
        const totalDays = moment(period.end).diff(moment(period.start), "days", false) + 1;
        if (Object.keys(halfHourMapping).length === 0) {
            return {
                totalDays,
                totalCost: 0,
                totalConsumptions: 0,
            };
        }
        return {
            totalDays,
            ...Object.keys(halfHourMapping).reduce((prev, current) => {
                prev.totalCost += halfHourMapping[current].costPerKwh * halfHourMapping[current].consumption;
                prev.totalConsumptions += halfHourMapping[current].consumption;
                return prev;
            }, {
                totalCost: 0,
                totalConsumptions: 0,
            }),
        };

    }, [ period.start, period.end, halfHourMapping ]);

    const { totalStandingCharge } = useMemo(() => {
        let totalStandingCharge = 0;
        if (! isStandingChargeLoading && standingCharges && standingCharges.length > 0) {
            standingCharges.forEach((standingCharge) => {
                const daysInDiff = moment(standingCharge.validTo).diff(moment(standingCharge.validFrom), "days", false) + 1;
                totalStandingCharge += daysInDiff * standingCharge.valueIncVat;
            });
        }
        return {
            totalStandingCharge
        }
    }, [ isStandingChargeLoading, standingCharges ]);


    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        if (open) {
            setTempPeriod({ ...period });
        }
        setDrawerOpen(open);
    }

    const saveFilter = () => {
        setPeriod({ ...tempPeriod });
        setDrawerOpen(false);
    };

    const nextPeriod = () => {
        setPeriod((prev) => {
            return {
                start: moment(prev.start).add(1, "day").toDate(),
                end: moment(prev.end).add(1, "day").toDate(),
            }
        });
    };

    const previousPeriod = () => {
        setPeriod((prev) => {
            return {
                start: moment(prev.start).subtract(1, "day").toDate(),
                end: moment(prev.end).subtract(1, "day").toDate(),
            }
        });
    }

    return (
        <>
            <Box>

                <Paper
                    component="div"
                    square={true}
                    elevation={0}
                    sx={{
                        position: "sticky",
                        top: "48px",
                        zIndex: 200,
                        backgroundColor: "rgb(231, 235, 240)",
                    }}
                >
                    <Grid container>
                        <Grid xs={2} md={1}>
                            <Button color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={previousPeriod}
                                    variant="contained"
                                    disableElevation
                                    sx={{ borderRadius: 0 }}
                            >
                                {"<<"}
                            </Button>
                        </Grid>
                        <Grid xs={6} md={3} sx={{
                            textAlign: "center",
                            color: "#000",
                        }}>
                            {moment(period.start).isSame(period.end, "day") ?
                                moment(period.start)
                                    .locale(locale)
                                    .format("yyyy-MM-DD (dddd)") : `${moment(period.start).format("YYYY-MM-DD (dddd)")} - ${moment(period.end).format("YYYY-MM-DD (dddd)")}`}
                        </Grid>
                        <Grid xs={2} md={1}>
                            <Button color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={nextPeriod}
                                    variant="contained"
                                    disableElevation
                                    sx={{ borderRadius: 0 }}
                                // disabled={isNextDateButtonDisabled()}
                            >
                                {">>"}
                            </Button>
                        </Grid>
                        <Grid mdOffset={6} xs={2} md={1}>
                            <Button color="primary"
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    sx={{ borderRadius: 0 }}
                                    onClick={toggleDrawer(true)}
                            >
                                Options
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Card sx={{ m: 1 }}>
                    <CardHeader title="Overview" />
                    <Divider />
                    <CardContent>
                        <Grid container>
                            <Grid xs={12} md={4}>Total days: {totalDays} days</Grid>
                            <Grid xs={12} md={4}>Total Consumption: {totalConsumptions.toFixed(2)} kWh</Grid>
                            <Grid mdOffset={4} />
                            <Grid xs={12} md={4}>Consumption cost: &pound;{(totalCost / 100).toFixed(2)}</Grid>
                            <Grid xs={12} md={4}>Standing charge
                                cost: &pound;{(totalStandingCharge / 100).toFixed(2)}</Grid>
                            <Grid xs={12} md={4}>Total
                                cost: &pound;{((totalCost + totalStandingCharge) / 100).toFixed(2)}</Grid>
                            <Grid xs={12} md={4}>Cost per
                                kWh: &pound;{(totalConsumptions > 0 ? (totalCost) / 100 / totalConsumptions : 0).toFixed(2)} /
                                kWh</Grid>
                            <Grid xs={12} md={4}>Cost per kWh (with standing
                                charge): &pound;{(totalConsumptions > 0 ? (totalCost + totalStandingCharge) / 100 / totalConsumptions : 0).toFixed(2)} /
                                kWh</Grid>
                            <Grid xs={12} md={4}>Daily
                                cost: &pound;{((totalCost + totalStandingCharge) / 100 / totalDays).toFixed(2)}</Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Paper sx={{ m: 1, p: 3, display: "none" }}>
                    <Typography variant="h5"> Matched Agreements </Typography>
                    <Divider />
                    {periodAgreements.length === 0 && <Box>No Agreement Matched</Box>}
                    {periodAgreements.map((agreement, index) => {
                        return (
                            <Box key={index}>
                                Tariff Code: {agreement.tariffCode} <br />
                                From {moment(agreement.validFrom).locale(locale).format("LLL")} To {moment(agreement.validTo).locale(locale).format("LLL")}
                            </Box>
                        );
                    })}
                </Paper>
            </Box>

            <OctopusConsumptionTable periodUsageMap={halfHourMapping} />
            {
                moment(period.end).diff(period.start, "day") === 0 ?
                    <OctopusConsumptionChart type="half-hour" periodUsageMap={halfHourMapping} /> : null
            }

            <OctopusConsumptionDayChart periodUsageMap={dayMapping} />

            <SwipeableDrawer anchor="right"
                             open={isDrawerOpen}
                             onClose={toggleDrawer(false)}
                             onOpen={toggleDrawer(true)}
            >
                <Box
                    sx={{
                        width: {
                            xs: "70vw",
                            md: 600,
                        },
                    }}
                >
                    <Box sx={{ m: 2 }}>
                        <Typography variant="h4">Date Range</Typography>
                        <Divider />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Fragment>
                                <DesktopDatePicker label="Start Date"
                                                   value={moment(tempPeriod.start)}
                                                   minDate={moment(selectedProperty.movedInAt ?? new Date())}
                                                   maxDate={moment(tempPeriod.end)}
                                                   dayOfWeekFormatter={(day) => {
                                                       return day.format("ddd");
                                                   }}
                                                   onError={(error, value) => {
                                                       setError(error);
                                                   }}
                                                   onChange={(newValue, context) => {
                                                       if (newValue) {
                                                           setTempPeriod((prev) => ({
                                                               start: newValue.toDate(),
                                                               end: prev.end,
                                                           }));
                                                       }
                                                   }} />
                                <br />
                                <DesktopDatePicker label="End Date"
                                                   value={moment(tempPeriod.end)}
                                                   minDate={moment(tempPeriod.start)}
                                                   maxDate={moment().add(1, "day")}
                                                   dayOfWeekFormatter={(day) => {
                                                       return day.format("ddd");
                                                   }}
                                                   onError={(error, value) => {
                                                       setError(error);
                                                   }}
                                                   onChange={(newValue, context) => {
                                                       if (newValue) {
                                                           setTempPeriod((prev) => ({
                                                               start: prev.start,
                                                               end: newValue.toDate(),
                                                           }));
                                                       }
                                                   }}
                                />
                            </Fragment>
                        </LocalizationProvider>
                        <Divider />
                        <Box sx={{ mt: 1 }}>
                            <Button variant="contained" color="success" sx={{ mr: 3 }}
                                    onClick={() => saveFilter()}>Apply</Button>
                            <Button variant="contained" color="error"
                                    onClick={() => toggleDrawer(false)}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </>
    )
};

export default OctopusGasConsumption;
