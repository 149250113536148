import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import EquipmentEditForm from "./form/EquipmentEditForm";

const EquipmentCreate: React.FC = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Equipments',
            resource: 'equipment',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Equipment Management"
                resource="equipment"
                redirect="show"
        >
            <CustomBreadcrumbs title="Create Equipment" items={breadcrumbItems} />
            <EquipmentEditForm type="create" />
        </Create>
    );
};

export default EquipmentCreate;
