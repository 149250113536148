import React from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create, Edit } from "react-admin";
import EquipmentNoteEditForm from "../form/EquipmentNoteEditForm";

const EquipmentNoteEdit: React.FC = () => {

    const { equipmentId, id } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Equipments",
            resource: "equipment",
            type: "list",
        },
        {
            label: "Record",
            resource: "equipment",
            type: "show",
            id: equipmentId,
        },
        {
            label: "Note Edit",
            isCurrent: true,
        },
    ];

    return (
        <Edit
            actions={false}
            component="div"
            title="Equipment Note"
            resource="equipment/note"
            redirect={`/equipment/${equipmentId}/show/notes`}
        >
            <CustomBreadcrumbs title="Edit Equipment Note" items={breadcrumbItems} />
            <EquipmentNoteEditForm type="edit" />
        </Edit>
    );
};

export default EquipmentNoteEdit;
