import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "@tanstack/react-query";
import getFolderDetails from "./getFolderDetails";

const useGetFolderDetails = (folderId?: string) => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getFolderDetails', projectUuid, folderId ],
        queryFn: () => getFolderDetails(projectUuid, userUuid, folderId),
        enabled: projectUuid.length > 0 && userUuid.length > 0,
    });
};

export default useGetFolderDetails;
