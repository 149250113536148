import englishMessages from 'ra-language-english';

export const zhHK = {
    ra: {
        ...englishMessages.ra,
        action: {
            ...englishMessages.ra.action,
            cancel: "取消",
            save: "保存",
            sort: '排序',
            refresh: "重新載入",
        },
        auth: {
            ...englishMessages.ra.auth,
            auth_check_error: '請重新登入再繼續操作',
            user_menu: '會員選單',
            username: '帳戶名稱',
            password: '密碼',
            sign_in: '登入',
            sign_in_error: '帳戶驗證錯誤，請重新嘗試！',
            logout: '登出',
        },
        navigation: {
            ...englishMessages.ra.navigation,
            no_results: '沒有結果',
            no_more_results: '頁數 %{page} 超出最後一頁, 請試上一頁.',
            page_out_of_boundaries: '頁數 %{page} 超出最後一頁',
            page_out_from_end: '已到最後一頁',
            page_out_from_begin: 'Cannot go before page 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            partial_page_range_info:
                '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
            current_page: 'Page %{page}',
            page: 'Go to page %{page}',
            first: 'Go to first page',
            last: 'Go to last page',
            next: 'Go to next page',
            previous: 'Go to previous page',
            page_rows_per_page: '每頁紀錄:',
            skip_nav: 'Skip to content',
        },
        sort: {
            ...englishMessages.ra.sort,
            sort_by: '以 %{field} %{order}',
            ASC: '順序',
            DESC: '倒序',
        },
        validation: {
            ...englishMessages.ra.validation,
            required: '必填',
            minLength: '最少 %{min} 個字',
            maxLength: '最多 %{max} 個字',
            minValue: '必須大於 %{min}',
            maxValue: '必須小於 %{max}',
            number: '必須為數字',
            email: '必須是有效的郵箱',
            oneOf: '必須為: %{options}其中一項',
            regex: '必須符合指定的格式 (regexp): %{pattern}',
            unique: '必須為獨一無二',
        },
    },
    common: {
        table: {
            filter: {
                add: "新增篩選",
                reset: "重設篩選",
            },
            actions: "操作",
            search: "搜尋",
        },
        button: {
            show: "顯示",
            create: "新增",
            edit: "修改",
            delete: "刪除",
        },
        status: {
            voucher: {
                pending: "暫停",
                active: "正常",
                partlyConsumed: "部份使用",
                consumed: "已使用",
                deleted: "已刪除",
            },
            common: {
                active: "正常",
                disabled: "禁用",
                deleted: "已刪除",
                unknown: "不明",
            },
        },
        locale: {
            zh_HK: "繁體中文",
            en: "英文"
        },
    },
    resources: {
        voucher: {
            menu: "禮物卡",
            common: {
                recordId: "系統編號",
                name: "名稱",
                purchaseDate: "購買日期",
                issuer: "發行人",
                amount: "金額",
                usedAmount: "已用",
                actualCost: "實際金額",
                number: "號碼",
                pin: "PIN",
                url: "URL",
                barcodeText: "條碼文字",
                barcodeType: "條碼總類",
                effectiveStartDate: "生效日期（開始）",
                effectiveEndDate: "生效日期（結束）",
                status: "狀態",
                balance: "餘額",
            },
            page: {
                list: {
                    title: "代金劵管理",
                },
                create: {
                    title: "新增代金劵",
                },
                edit: {
                    title: "修改代金劵",
                    message: {
                        usedAmountWarning: "現有金額不能低於已用金額",
                        currentUsedAmount: "現時已用金額: %{usedAmount}"
                    },
                    validator: {
                        amount: {
                            required: "Amount is required!",
                            less: "不能低於已用金額！",
                            more: "Not more than transferable voucher available value",
                        }
                    },
                },
            },
        },
        energy: {
            octopus: {
                tariff: {
                    v3: {
                        consumptions: "用電量",
                        averageConsumptions: "平均用電量",
                        charge: {
                            energy: "電費",
                            standing: "標準收費",
                            total: "總收費",
                            averageDaily: "平均每日收費",
                            averageHourly: "平均每小時收費",
                        },
                        totalDays: "總日數",
                        days: "日",
                        table: {
                            from: "由",
                            to: "至",
                            used: "使用量",
                            rate: "收費率",
                            charge: "收費",
                        },
                    },
                },
            },
        },
    },
};
