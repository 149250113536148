import React, { Fragment } from "react";
import { Create } from "react-admin";
import ProjectEditForm from "./ProjectEditForm";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";

const ProjectCreate = () => {

    return (
        <Create actions={false}
                component="div"
                title="Project Management"
                redirect="show"
        >
            <Fragment>
                <CustomBreadcrumbs title="Project Management"
                                   items={[]}
                />
                <ProjectEditForm type="create" />
            </Fragment>
        </Create>
    );
};

export default ProjectCreate;
