import React, { useContext } from "react";
import { OctopusV4Context } from "./context/OctopusV4Context";
import { Box, Button } from "@mui/material";

const OctopusV4Property: React.FC = () => {

    const { account: { isLoading, data }, selectedProperty, changeSelectedProperty } = useContext(OctopusV4Context);

    if (isLoading)
        return <Box>Loading...</Box>;
    else if (! data) {
        return <Box>Account Not Found</Box>;
    }

    return (
        <>
            SelectedProperty : {selectedProperty?.recordId} <br />
            Properties List : <br />
            {data.properties.map((property) => {
                return <Box key={property.recordId}>{property.addressLine1} <Button
                    onClick={() => changeSelectedProperty(property)} disabled={selectedProperty === property}>Change
                    it</Button></Box>
            })}
        </>
    )
};

export default OctopusV4Property;
