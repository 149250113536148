import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Box } from "@mui/material";
import ProductGroupEditForm from "./ProductGroupEditForm";
import { Edit } from "react-admin";

const ProductGroupEdit = () => {
    const { id } = useParams();
    const breadcrumbItems: BreadcrumbItem[] =[
        {
            label: "Product Groups",
            resource: "productGroup",
            type: "list",
        },
        {
            label: "Record",
            resource: "productGroup",
            type: "show",
            id,
        },
        {
            label: "Edit",
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
                    component="div"
                    mutationMode="pessimistic"
                    title="Product Group Management"
                    resource="productGroup"
                    redirect="show">
            <Box>
                <CustomBreadcrumbs title="Edit Product Group" items={breadcrumbItems} />
                <ProductGroupEditForm type="edit" />
            </Box>
        </Edit>
    )
}

export default ProductGroupEdit;
