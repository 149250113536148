import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsVariantOverrides } from "@mui/material/Button/Button";
import { useTheme } from "react-admin";

export type CustomButtonProps = Omit<ButtonProps, "color"> & {
    color?: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "dark" | "link" | "light";
    soft?: boolean;
    rounded?: boolean;
};

type ButtonStyle = {
    backgroundColor: string;
    textColor: string;
    borderColor: string;
    onHoverBackgroundColor: string;
    onHoverTextColor: string;
    onHoverBorderColor: string;
}

const getButtonStyle = (
    color: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "dark" | "link" | "light",
    variant: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
    soft: boolean,
    isDarkMode: boolean,
): ButtonStyle => {
    switch (color) {
        case "light":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: isDarkMode ? "#32394e" : "#000",
                        borderColor: isDarkMode ? "#32394e" : "#eff2f7",
                        onHoverBackgroundColor: isDarkMode ? "#32394e" : "#eff2f7",
                        onHoverTextColor: isDarkMode ? "#c3cbe4" : "#000",
                        onHoverBorderColor: isDarkMode ? "#32394e" : "#eff2f7",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: isDarkMode ? "#32394e" : "#000",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: isDarkMode ? "#5b5f6b" : "#000",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    return {
                        backgroundColor: isDarkMode ? "#32394e" : "#eff2f7",
                        textColor: isDarkMode ? "#c3cbe4" : "#000",
                        borderColor: isDarkMode ? "#32394e" : "#eff2f7",
                        onHoverBackgroundColor: isDarkMode ? "#5b5f6b" : "#bfc2c6",
                        onHoverTextColor: isDarkMode ? "#c3cbe4" : "#000",
                        onHoverBorderColor: isDarkMode ? "#32394e" : "#eff2f7",
                    }
            }
        case "link":
            switch (variant) {
                // No variant.
                default:
                    return {
                        backgroundColor: "transparent",
                        textColor: "#556ee6",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#556ee6",
                        onHoverBorderColor: "transparent",
                    }
            }
        case "dark":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: isDarkMode ? "#a6b0cf" : "#343a40",
                        borderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                        onHoverBackgroundColor: isDarkMode ? "#a6b0cf" : "#343a40",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: isDarkMode ? "#a6b0cf" : "#343a40",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: isDarkMode ? "#5b5f6b" : "#52585d",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: isDarkMode ? "rgba(166,176,207,0.1)" : "rgba(52,58,64,0.1)",
                            textColor: isDarkMode ? "#a6b0cf" : "#343a40",
                            borderColor: "transparent",
                            onHoverBackgroundColor: isDarkMode ? "#a6b0cf" : "#343a40",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else

                        return {
                            backgroundColor: isDarkMode ? "#a6b0cf" : "#343a40",
                            textColor: "#fff",
                            borderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                            onHoverBackgroundColor: isDarkMode ? "#5b5f6b" : "#52585d",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                        }
            }
        case "danger":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#f46a6a",
                        borderColor: "#f46a6a",
                        onHoverBackgroundColor: "#f46a6a",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#f46a6a",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#f46a6a",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#c35555",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(244,106,106,0.1)",
                            textColor: "#f46a6a",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#f46a6a",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else

                        return {
                            backgroundColor: "#f46a6a",
                            textColor: "#fff",
                            borderColor: "#f46a6a",
                            onHoverBackgroundColor: "#c35555",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#f46a6a",
                        }
            }
        case "warning":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#f1b44c",
                        borderColor: "#f1b44c",
                        onHoverBackgroundColor: "#f1b44c",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#f1b44c",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#f1b44c",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#cd9941",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(241,180,76,0.1)",
                            textColor: "#f1b44c",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#f1b44c",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else

                        return {
                            backgroundColor: "#f1b44c",
                            textColor: "#fff",
                            borderColor: "#f1b44c",
                            onHoverBackgroundColor: "#cd9941",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#f1b44c",
                        }
            }
        case "info":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#50a5f1",
                        borderColor: "#50a5f1",
                        onHoverBackgroundColor: "#50a5f1",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#50a5f1",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#50a5f1",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#448ccd",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(80,165,241,0.1)",
                            textColor: "#50a5f1",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#50a5f1",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else

                        return {
                            backgroundColor: "#50a5f1",
                            textColor: "#fff",
                            borderColor: "#50a5f1",
                            onHoverBackgroundColor: "#448ccd",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#50a5f1",
                        }
            }
        case "success":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#34c38f",
                        borderColor: "#34c38f",
                        onHoverBackgroundColor: "#34c38f",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#34c38f",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#34c38f",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#2ca67a",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(52,195,143,0.1)",
                            textColor: "#34c38f",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#34c38f",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else
                        return {
                            backgroundColor: "#34c38f",
                            textColor: "#fff",
                            borderColor: "#34c38f",
                            onHoverBackgroundColor: "#2ca67a",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#34c38f",
                        }
            }
        case "secondary":
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#74788d",
                        borderColor: "#74788d",
                        onHoverBackgroundColor: "#74788d",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#74788d",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#74788d",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#636678",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(116,120,141,0.1)",
                            textColor: "#74788d",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#74788d",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else
                        return {
                            backgroundColor: "#74788d",
                            textColor: "#fff",
                            borderColor: "#74788d",
                            onHoverBackgroundColor: "#636678",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#74788d",
                        }
            }
        case "primary":
        default:
            switch (variant) {
                case "outlined":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#556ee6",
                        borderColor: "#556ee6",
                        onHoverBackgroundColor: "#556ee6",
                        onHoverTextColor: "#fff",
                        onHoverBorderColor: "#556ee6",
                    }
                case "text":
                    return {
                        backgroundColor: "transparent",
                        textColor: "#556ee6",
                        borderColor: "transparent",
                        onHoverBackgroundColor: "transparent",
                        onHoverTextColor: "#485ec4",
                        onHoverBorderColor: "transparent",
                    }
                case "contained":
                default:
                    if (soft)
                        return {
                            backgroundColor: "rgba(85, 110, 230, 0.1)",
                            textColor: "#556ee6",
                            borderColor: "transparent",
                            onHoverBackgroundColor: "#556ee6",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "transparent",
                        }
                    else
                        return {
                            backgroundColor: "#556ee6",
                            textColor: "#fff",
                            borderColor: "#556ee6",
                            onHoverBackgroundColor: "#485ec4",
                            onHoverTextColor: "#fff",
                            onHoverBorderColor: "#556ee6",
                        }
            }
    }
};

const CustomButton: React.FC<CustomButtonProps> =
    ({
         children,
         color = "primary",
         variant = "contained",
         soft = false,
         rounded = false,
         ...rest
     }) => {

        const [ theme ] = useTheme();

        const {
            backgroundColor,
            textColor,
            borderColor,
            onHoverBackgroundColor,
            onHoverTextColor,
            onHoverBorderColor
        } = getButtonStyle(color, variant, soft, theme === "dark");

        return (
            <Button
                sx={{
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    color: textColor,
                    opacity: 1,
                    "&:disabled": {
                        opacity: 0.65,
                    },
                    "&:hover": {
                        textDecoration: color === "link" || variant === "text" ? "underline" : "",
                        backgroundColor: onHoverBackgroundColor,
                        borderColor: onHoverBorderColor,
                        color: onHoverTextColor,
                        opacity: 1,
                    },
                    ...rest.sx,
                }}
                {...rest}
            >
                {children}
            </Button>
        );
    };

export default CustomButton;
