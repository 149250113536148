import useGetPreviewUrl from "../data/useGetPreviewUrl";
import React, { useEffect, useState } from "react";

export type DocumentPreviewerProps = {
    serverPath: string;
};

const DocumentPreviewer: React.FC<DocumentPreviewerProps> = ({ serverPath }) => {

    const { data, isLoading, isRefetching, isError, isPending } = useGetPreviewUrl(true, serverPath);

    if (isLoading || isRefetching)
        return "Loading...";
    if (isError)
        return "Error...";
    if (!data)
        return "Data is empty"

    return (
        <embed
            width="100%"
            height="100%"
            src={data.data.previewUrl}
        />
    );
};

export default DocumentPreviewer;