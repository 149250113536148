import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
    Box,
    Button,
    Chip,
} from "@mui/material";
import { AutocompleteArrayInput, AutocompleteInput, Form, RecordContextProvider, TextInput } from "react-admin";
import useGetTags from "../../data/useGetTags";
import useGetFileDetails from "../../data/useGetFileDetails";
import { useParams } from "react-router-dom";

export type DocumentPreviewDetailsTabProps = {
    file: FileV2;
};

const DocumentPreviewDetailsTab: React.FC<DocumentPreviewDetailsTabProps> = ({ file }) => {

    const { data: tags, isLoading: isTagsLoading, refetch } = useGetTags();

    const contrastColor = (color: string) => {
        const r = parseInt(color.substring(1,3),16);
        const g = parseInt(color.substring(3,5),16);
        const b = parseInt(color.substring(5,7),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    const onSubmitHandler = (formValue: any) => {
        console.log(formValue);
    };

    return (
        <Box>
            <RecordContextProvider key={-1} value={{
                name: file.name,
                description: file.description,
                tags: file.tags,
            }}>
                <Form onSubmit={onSubmitHandler}>

                    <Grid container>
                        <Grid xs={12}>
                            <TextInput name="name" source="name" fullWidth />
                        </Grid>
                        <Grid xs={12}>
                            <TextInput name="description" source="description" fullWidth />
                        </Grid>
                        <Grid xs={12}>
                            <AutocompleteInput
                                name="type"
                                source="type"
                                choices={[
                                    { id: 0, name: "Financial" },
                                ]}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <AutocompleteArrayInput
                                name="tags"
                                source="tags"
                                choices={[
                                    ...isTagsLoading ? [] : [...tags.list],
                                ]}
                                optionValue="recordId"
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            sx={{
                                                backgroundColor: option.color,
                                                color: contrastColor(option.color),
                                            }}
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                onCreate={() => {
                                    const newCategoryName = prompt('Enter a new category');
                                    if (newCategoryName) {
                                        const newCategory = { id: newCategoryName.toLowerCase(), name: newCategoryName };
                                        refetch();
                                        return newCategory;
                                    }
                                    return null;
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Button type="submit">Submit</Button>
                            <Button type="reset">Reset</Button>
                        </Grid>
                    </Grid>
                </Form>
            </RecordContextProvider>
        </Box>
    );
};

export default DocumentPreviewDetailsTab;
