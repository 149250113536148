import React, { ReactComponentElement } from "react";
import { useRecordContext } from "ra-core";
import { useNavigate } from "react-router";
import { useCreatePath, useResourceContext } from "react-admin";
import { Box, Fab, FabProps, IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CustomIconButton from "./common/CustomIconButton";

const stopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation();

const defaultIcon = <EditIcon />;

interface CustomEditButtonV2Prop {
    forwardedRef?: React.ForwardedRef<any>;
    style?: React.CSSProperties;
    title?: string;
    icon?: ReactComponentElement<any>;
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
    IconButtonProps?: IconButtonProps;
    customPath?: string;
    isFloating?: boolean;
    FabProps?: FabProps,
    iconFontSize?: string;
}

const CustomEditButtonV2: React.FC<CustomEditButtonV2Prop> =
    ({
         forwardedRef,
         style,
         title = "Edit",
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         customPath,
         isFloating = false,
         FabProps,
         iconFontSize = "16px",
     }) => {
        const resource = useResourceContext();
        const record = useRecordContext();
        const navigate = useNavigate();
        const createPath = useCreatePath();

        const disabled = IconButtonProps ? IconButtonProps.disabled ? IconButtonProps.disabled : false : false;

        if (! record) return null;

        const FloatingButton = () => (
            <Fab
                onClick={(e) => {
                    navigate(toPath);
                }}
                color="warning"
                {...FabProps}
            >
                {icon}
            </Fab>
        );

        const NormalButton = () => (
            <Tooltip title={disabled ? "" : title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        disabled={disabled}
                        size="small"
                        color="softPrimary"
                        onClick={(e) => {
                            navigate(toPath);
                        }}
                        {...IconButtonProps}
                    >
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        )

        const toPath: string = customPath ? customPath : createPath({ type: 'edit', resource: resource, id: record.id })
        return (
            <Box component="span" style={style} ref={forwardedRef}>
                {isFloating ? <FloatingButton /> : <NormalButton />}
            </Box>
        );
    }

export default React.forwardRef((props: CustomEditButtonV2Prop, ref) =>
    <CustomEditButtonV2 {...props} forwardedRef={ref} />);
