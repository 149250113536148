import BranchList from "./BranchList";
import CategoryIcon from "@mui/icons-material/Bookmark";
import BranchEdit from "./BranchEdit";
import BranchCreate from "./BranchCreate";
import BranchShow from "./BranchShow";

export default {
    list: BranchList,
    edit: BranchEdit,
    create: BranchCreate,
    show: BranchShow,
    icon: CategoryIcon,
}