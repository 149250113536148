import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import {
    CreateButton,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField
} from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import React from "react";
import moment from "moment";

const AnnouncementList = (props: any) => {

    const announcementBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Announcement',
            isCurrent: true,
        }
    ];

    return (
        <List
            {...props}
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            empty={false}
            component="div"
            title="Announcement Management"

        >
            <Box>
                <CustomBreadcrumbs title="Announcement Management" items={announcementBreadcrumbItems} />
                <Card>
                    <CardHeader title="Announcements" action={<CreateButton />} />
                    {/*<Divider />*/}
                    {/*<ListFilter />*/}
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false} data-testid="123123">
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )} data-testid="actions" />
                            <TextField source="recordId" data-testid="recordId" />
                            <TextField source="message" data-testid="message" />
                            <DateField source="effectiveStartDate" />
                            <DateField source="effectiveEndDate" />
                            <FunctionField
                                label="Status"
                                data-testid="status"
                                render={(e: any) => {
                                    const effectiveEndDate = e.effectiveEndDate ?? new Date();
                                    if (moment().isBetween(e.effectiveStartDate, effectiveEndDate, "day", "[]")) {
                                        return "Active"
                                    }
                                    return "Expired";
                                }} />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );
};

export default AnnouncementList;
