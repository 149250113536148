export const DUE_DATE_TYPE = [
    {
        id: "X",
        name: "N/A",
    },
    {
        id: "B",
        name: "Best Before Date",
    },
    {
        id: "P",
        name: "Expiration Date",
    },
];