import axios from "axios";
import config from "../../../config.json";
import { getHeaders } from "../../../helper/RestUtils";

const getDownloadUrl = (projectUuid: string, userUuid: string, filepath: string, storeType: string = "Microsoft") => {
    return axios.get(`${config.DOCUMENT_API_BASE_URL}/project/${projectUuid}/user/${userUuid}/${storeType}/download/${filepath}`, {
        headers: getHeaders(),
    });
};

export default getDownloadUrl;
