import { Show, TextField, useRedirect, useShowContext } from "react-admin";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../component/common/Loading";

const ProjectRoleShow = () => {

    const { projectId, roleId } = useParams();
    const redirect = useRedirect();

    const ProjectRoleShowContent = () => {

        const { isLoading, record } = useShowContext();

        if (isLoading)
            return <Loading />;

        return (
            <Box>
                Record ID: {record.recordId} <br/>
                Role: {record.role} <br/>
                Role Name: {record.roleName} <br/>
                Role Description: {record.roleDescription} <br/>

                Created By: {record.createdBy} <br/>
                Created Date: {record.createdDate} <br/>
                Updated By: {record.updatedBy} <br/>
                Updated Date: {record.updatedDate} <br/>
            </Box>
        );
    }

    return (
        <Show actions={false}
              component="div"
              title="Project Role Management"
              resource="project/role"
              id={roleId}
              queryOptions={{
                  meta: { projectId },
                  onError: () => {
                      redirect('show', 'project', projectId);
                  },
              }}
        >
            <CustomBreadcrumbs title="Project Role Management" items={[]} />
            <ProjectRoleShowContent />
        </Show>
    );
};

export default ProjectRoleShow;
