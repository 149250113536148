import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import {
    Datagrid, DateField,
    FunctionField,
    Pagination,
    ReferenceManyField,
    SelectField, Show, TextField,
    useRedirect,
    useShowContext
} from "react-admin";
import Loading from "../../../component/common/Loading";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material";
import CustomEditButtonV2 from "../../../component/CustomEditButtonV2";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";
import React from "react";
import { PROJECT_STATUSES, USER_STATUS_LIST } from "../../constant/display";
import UserUnassignProjectButton from "./componenets/UserUnassignProjectButton";
import UserAssignProjectButton from "./componenets/UserAssignProjectButton";
import CustomIconButton from "../../../component/common/CustomIconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { red } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

const UserShow = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Users",
            resource: "User",
            type: "list",
        },
        {
            label: "Record",
            isCurrent: true,
        },
    ];

    const UserShowContent = () => {

        const { id } = useParams();
        const { record, isLoading, refetch } = useShowContext();
        const redirect = useRedirect();

        if (isLoading)
            return <Loading />;

        if (! record)
            return <>Record Not Found</>;

        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 IconButtonProps={{ size: "small" }} />
                <CustomDeleteIconButtonV2
                    IconButtonProps={{ size: "small" }}
                    redirectTo="list"
                />
            </Stack>
        );

        return (
            <Box sx={{ width: "100%" }}>
                <Card>
                    <CardHeader title={`User - ${record.name}`}
                                action={<CardHeaderAction />}
                    />
                    <Divider />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4}>
                                Record ID: {record.recordId}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Name: {record.name}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Description: {record.description}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Status: <SelectField source="status" choices={USER_STATUS_LIST} />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container>
                            <Grid item xs={12}>
                                <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                            </Grid>
                            <Grid item xs={12}>
                                <>Last Updated
                                    By {record.updatedBy} @ {new Date(record.updatedDate).toLocaleString()}</>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card sx={{ mt: 2 }}>
                    <CardHeader title="Assigned Projects"
                                action={<UserAssignProjectButton user={record} />} />
                    <Divider />
                    <CardContent>
                        <ReferenceManyField reference="project"
                                            target="userIds"
                                            source="recordId"
                                            pagination={<Pagination />} perPage={5}
                                            sort={{ field: 'createdDate', order: 'DESC' }}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <FunctionField label="Actions" render={(project: any) => (
                                    <Stack component="div" spacing={1} direction="row">
                                        <UserUnassignProjectButton user={record} project={project} />
                                        <CustomIconButton
                                            size="small"
                                            color="softPrimary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                redirect(`/admin/user/${id}/project/${project.uuid}/role`);
                                            }}>
                                            <AssignmentReturnIcon sx={{ fontSize: 16 }} />
                                        </CustomIconButton>
                                    </Stack>
                                )} />
                                <TextField source="recordId" sortable={false} />
                                <TextField source="uuid" sortable={false} />
                                <TextField source="name" sortable={false} />
                                <TextField source="description" sortable={false} />
                                <SelectField source="status" choices={PROJECT_STATUSES} sortable={false} />
                            </Datagrid>
                        </ReferenceManyField>

                    </CardContent>
                </Card>
            </Box>
        )
    }

    return (
        <Show actions={false}
              component="div"
        >
            <Box>
                <CustomBreadcrumbs title="View" items={breadcrumbItems} />
                <UserShowContent />
            </Box>
        </Show>
    );

};

export default UserShow;
