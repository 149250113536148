import { Layout, LayoutProps } from "react-admin";
import React from "react";
import { MyAppBar } from "../MyLayout";
import { AdminMenu } from "./AdminMenu";

// TODO: revise AppBar.
const AdminLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => (
    <Layout {...props} menu={AdminMenu} appBar={MyAppBar} sx={{
        "& .MuiDrawer-root": {
            marginTop: "0 !important",
        },
    }}/>
);

export default AdminLayout;
