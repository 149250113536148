const SUCCESS = {
    normal: "#34c38f",
    hover: "rgba(52,195,143,0.1)"
}

const WARNING = {
    normal: "#f1b44c",
    hover: "#cd9941",
};

export {
    SUCCESS,
    WARNING,
};
