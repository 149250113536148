export const COMMON_STATUS = [
    {
        id: 'A',
        name: 'Active',
        translate: 'common.status.commonStatus.active',
    },
    {
        id: 'D',
        name: 'Disabled',
        translate: 'common.status.commonStatus.disabled',
    },
    {
        id: 'X',
        name: 'Deleted',
        translate: 'common.status.commonStatus.deleted',
    },
    {
        id: 'U',
        name: 'Unknown',
        translate: 'common.status.commonStatus.unknown',
    },
];
