import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box } from "@mui/material";
import { Edit } from "react-admin";
import React from "react";
import BranchEditForm from "./BranchEditForm";

const BranchEdit = () => {

    const { id } = useParams();
    const branchEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Branches',
            isCurrent: false,
            resource: 'branch',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: false,
            resource: 'branch',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false} component="div" mutationMode="pessimistic">
            <Box>
                <CustomBreadcrumbs title="Edit Branch" items={branchEditBreadcrumbItems} />
                <BranchEditForm type="edit" />
            </Box>
        </Edit>
    );

};

export default BranchEdit;
