import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { convertToFormat } from "../../helper/BarcodeTypeUtils";
import CustomIconButton from "../../component/common/CustomIconButton";
import BarcodeV2 from "../../component/common/BarcodeV2";

export type VoucherBarcodeDialogProps = {
    barcodeText: string;
    barcodeType: string;
    small?: boolean;
};

const VoucherBarcodeDialog: React.FC<VoucherBarcodeDialogProps> =
    ({ barcodeText, barcodeType, small = false }) => {

        const [ dialogOpen, setDialogOpen ] = useState(false);

        const dialogOpenHandler = () => {
            setDialogOpen(true);
        }

        const dialogCloseHandler = () => {
            setDialogOpen(false);
        }

        if (!barcodeText || !barcodeType) {
            return <Box>Barcode Generation Failed</Box>
        }

        return (
            <>
                {small && <CustomIconButton
                    size="small"
                    color="softPrimary"
                    onClick={dialogOpenHandler}
                >
                    <QrCodeIcon sx={{ fontSize: 16 }} />
                </CustomIconButton>}

                {! small && <Button size="small"
                                    color="warning"
                                    variant="outlined"
                                    onClick={dialogOpenHandler}
                                    sx={{ textTransform: "none" }}
                >
                    Open Barcode <QrCodeIcon />
                </Button>}
                <Dialog open={dialogOpen} onClose={dialogCloseHandler}>
                    <DialogTitle>
                        Barcode
                        <IconButton
                            aria-label="close"
                            onClick={dialogCloseHandler}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <BarcodeV2 text={barcodeText}
                                   format={convertToFormat(barcodeType)}
                                   sx={{
                                       pt: 2,
                                       width: {
                                           xs: "200px",
                                           md: "300px",
                                       },
                                   }}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );

    };

export default VoucherBarcodeDialog;
