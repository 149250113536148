import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { SelectField, Show, useShowContext } from "react-admin";
import React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import CustomEditButton from "../../component/CustomEditButton";
import { BRANCH_STATUS, BRANCH_TYPE } from "./BranchConstant";


const BranchShowLayout = () => {
    const { error, isLoading, record } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Card>
                <CardHeader title={`Branch #${record.recordId}`} action={<div><CustomEditButton /></div>} />
                <CardContent sx={{ width: '100%' }}>
                    <Typography>Record ID: {record.recordId}</Typography>
                    <Typography>Name: {record.name}</Typography>
                    <Typography>Description: {record.description}</Typography>

                    <Typography>Address Line 1: {record.address1}</Typography>
                    <Typography>Address Line 2: {record.address2}</Typography>
                    <Typography>Postcode: {record.postcode}</Typography>

                    <Typography>Status: <SelectField source="status" choices={BRANCH_STATUS} /></Typography>
                    <Typography>Type: <SelectField source="type" choices={BRANCH_TYPE} /></Typography>


                    <Typography>Created By: {record.createdBy}</Typography>
                    <Typography>Created Date: {record.createdDate}</Typography>
                    <Typography>Updated By: {record.updatedBy}</Typography>
                    <Typography>Updated Date: {record.updatedDate}</Typography>

                </CardContent>
            </Card>
        </Box>
    );
}

const BranchShow = () => {


    const shopListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Branches',
            isCurrent: false,
            resource: 'branch',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: true,
        },
    ];

    return (
        <>
            <CustomBreadcrumbs title="View" items={shopListBreadcrumbItems} />
            <Show actions={false} component="div">
                <BranchShowLayout />
            </Show>
        </>
    );
}

export default BranchShow;
