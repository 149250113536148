import React from "react";
import { Create, Edit } from "react-admin";
import { Box } from "@mui/material";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import UserEditForm from "./UserEditForm";
import { useParams } from "react-router-dom";

const UserEdit: React.FC<{}> = () => {

    const { userId } = useParams();
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Users",
            resource: "user",
            type: "list",
        },
        {
            label: "User",
            resource: "user",
            type: "show",
            id: userId,
        },
        {
            label: "Edit",
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="User Management"
              resource="user"
              id={userId}
              redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="User Management" items={[]} />
                <UserEditForm type="edit" />
            </Box>
        </Edit>

    )
}

export default UserEdit;
