import React, { Fragment} from "react";
import { Route } from "react-router-dom";
import VoucherRemarkShow from "./VoucherRemarkShow";
import VoucherRemarkCreate from "./VoucherRemarkCreate";
import VoucherRemarkEdit from "./VoucherRemarkEdit";

const VoucherRemark = (
    <Fragment>
        <Route path=":voucherId/remark/create" element={<VoucherRemarkCreate />} />
        <Route path=":voucherId/remark/:remarkId/edit" element={<VoucherRemarkEdit />} />
        <Route path=":voucherId/remark/:remarkId/show" element={<VoucherRemarkShow />} />
    </Fragment>
);

export default VoucherRemark;
