import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useOctopusAccount } from "../data/useOctopusData";
import moment from "moment";
import { useStore } from "react-admin";
import { OctopusProperty, OctopusV4ContextType } from "../types/types";

export const OctopusV4Context = createContext<OctopusV4ContextType>({
    account: {
        isError: false,
        isFetching: false,
        isLoading: false,
        error: undefined,
        data: undefined,
        refetch: () => {},
    },
    displayChart: false,
    selectedProperty: null,
    changeSelectedProperty: () => {},
    setDisplayChart: () => {},
});

const OctopusV4ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [selectedPropertyId, setSelectedPropertyId] = useStore<number | null>("octopus-v4-selected-property-id", null);
    const useOctopusAccountProps = useOctopusAccount();
    const [selectedProperty, setSelectedProperty] = useState<OctopusProperty | null>(null);

    useEffect(() => {
        if (useOctopusAccountProps.data && selectedProperty === null) {
            const { properties } = useOctopusAccountProps.data;
            if (selectedPropertyId !== null) {
                const currentPropertyIndex = properties.findIndex(({recordId}) => recordId === selectedPropertyId);
                if (currentPropertyIndex > -1)
                    setSelectedProperty(properties[currentPropertyIndex]);
                else
                    setSelectedPropertyId(null)
            } else {
                if (properties.length > 1) {
                    const currentPropertyIndex = properties.findIndex(({movedInAt, movedOutAt}) => moment().isBetween(movedInAt, movedOutAt ?? moment(), "day", "[]"));
                    setSelectedProperty(properties[currentPropertyIndex]);
                    setSelectedPropertyId(properties[currentPropertyIndex].recordId);
                } else {
                    setSelectedProperty(properties[0]);
                    setSelectedPropertyId(properties[0].recordId);
                }
            }

        }
    }, [selectedProperty, selectedPropertyId, setSelectedPropertyId, useOctopusAccountProps.data]);

    const changeSelectedProperty = (property: OctopusProperty) => {
        setSelectedProperty(property);
        setSelectedPropertyId(property.recordId);
    };

    return (
        <OctopusV4Context.Provider value={{
            account: useOctopusAccountProps,
            displayChart: false,
            selectedProperty: selectedProperty,
            changeSelectedProperty: changeSelectedProperty,
            setDisplayChart: () => {},
        }}>
            {children}
        </OctopusV4Context.Provider>
    )
};

export default OctopusV4ContextProvider;