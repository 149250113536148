import React from "react";
import { BooleanField, Datagrid, EmailField, FunctionField, List, SelectField, TextField } from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import CustomShowButton from "../../../component/CustomShowButton";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";
import CreateIconButton from "../../../component/CreateIconButton";
import { USER_STATUS_LIST } from "../../constant/display";

const UserList: React.FC = () => {

    return (
        <List actions={false}
              exporter={false}
              sort={{ field: "recordId", order: "ASC" }}
              perPage={25}
              empty={false}
              component="div"
              title="User Management"
        >
            <Box>
                <CustomBreadcrumbs title="User Management" items={[]} />
                <Card>
                    <CardHeader title="Users" action={<CreateIconButton />} />
                    <Divider>
                        <CardContent>
                            <Datagrid bulkActionButtons={false}>
                                <FunctionField label="Actions" render={() => (
                                    <Stack direction="row" spacing={1}>
                                        <CustomShowButton />
                                        <CustomEditButton />
                                        <CustomDeleteIconButton />
                                    </Stack>
                                )} />
                                <TextField source="recordId" label="ID" />
                                <TextField source="uuid" label="UUID" />
                                <TextField source="name" />
                                <TextField source="email" />
                                <BooleanField source="isSuperAdmin" label="Super Admin" />
                                <SelectField source="status" choices={USER_STATUS_LIST} />
                            </Datagrid>
                        </CardContent>
                    </Divider>
                </Card>
            </Box>
        </List>
    );
};

export default UserList;
