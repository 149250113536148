import React, { Fragment } from "react";
import { Box, CircularProgress, Paper } from "@mui/material";

const Loading: React.FC<{}> = ({}) => {

    return (
        <Fragment>
            <Box component="div" sx={{ width: '100%', minHeight: 'calc(100vh - 48px)', textAlign: 'center' }}>
                <Box component="div" sx={{ marginTop: '90px' }}>
                    <CircularProgress size={100} />
                    <CircularProgress color="secondary" />
                    <CircularProgress color="success" />
                    <CircularProgress color="inherit" />
                </Box>
            </Box>
        </Fragment>
    )
}

export default Loading;