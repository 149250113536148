import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { useParams } from "react-router-dom";

const EquipmentNoteEditForm: React.FC<EditFormProps> = ({ type }) => {

    const { equipmentId } = useParams();

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        <TextInput name="equipmentId"
                                   source="equipmentId"
                                   label="E ID"
                                   defaultValue={equipmentId}
                                   fullWidth
                                   variant="filled"
                                   disabled
                                   sx={{ display: "none" }}
                        />

                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="filled"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="title"
                                       source="title"
                                       label="Title"
                                       fullWidth
                                       variant="filled"
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} md={12}>
                            <RichTextInput name="content"
                                           source="content"
                                           label="Content"
                                           fullWidth
                                           minHeight={400}
                                           sx={{
                                               "& .tiptap": {
                                                   minHeight: 400,
                                               },
                                           }}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
}

export default EquipmentNoteEditForm;
