import React, { Fragment } from "react";
import { Resource } from "react-admin";
import VoucherList from "./VoucherList";
import VoucherCreate from "./VoucherCreate";
import VoucherEdit from "./VoucherEdit";
import VoucherShow from "./VoucherShow";
import VoucherRemark from "./remark";
import VoucherConsumption from "./consumption";
import { Route } from "react-router-dom";
import VoucherIndex from "./others/VoucherIndex";
import VoucherRetailerSelection from "./others/VoucherRetailerSelection";
import VoucherStatistics from "./others/VoucherStatistics";

const MODULE = {
    show: VoucherShow,
    create: VoucherCreate,
    edit: VoucherEdit,
    // list: VoucherList,
}

const Voucher = (
    <Fragment>
        <Resource key="voucher" name="voucher" {...MODULE}>
            <Route path="list" element={<VoucherList />} />
            <Route path="*" element={<VoucherIndex />} />
            <Route path="selectByRetailer" element={<VoucherRetailerSelection />} />
            <Route path="statistics" element={<VoucherStatistics />} />
            {VoucherRemark}
            {VoucherConsumption}
        </Resource>
    </Fragment>
);

export default Voucher;
