import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Button, Card, CardContent, CardHeader,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Title, useRedirect } from "react-admin";
import Grid from "@mui/material/Unstable_Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import { useVoucherConsumptionDetails } from "../data/useVoucherConsumptionData";
import VoucherConsumptionRetailerChart from "./components/VoucherConsumptionRetailerChart";
import { useLocaleState } from "ra-core";
import { trackEvent } from "../../../support/googleAnalytics";
import * as XLSX from 'xlsx';
import VoucherConsumptionYearChart from "./components/VoucherConsumptionYearChart";

const VoucherStatistics: React.FC = () => {

    const [ locale, setLocale ] = useLocaleState();
    moment.locale(locale);

    const [ searchState, setSearchState ] = useState<VoucherConsumptionDetailsSearchForm>({
        purchaseDateStart: moment().startOf("month").toISOString(false),
        purchaseDateEnd: moment().endOf("month").toISOString(false),
    });
    const [ tempSearchState, setTempSearchState ] = useState<VoucherConsumptionDetailsSearchForm>({ ...searchState });
    const [ _error, setError ] = useState<string | null>(null);
    const { data, isLoading, isError } = useVoucherConsumptionDetails(searchState);
    const { data: yearData, isLoading: isYearDataLoading, isError: isYearDataError } = useVoucherConsumptionDetails({
        purchaseDateStart: moment().startOf("month").subtract(12, "month").toISOString(false),
        purchaseDateEnd: moment().endOf("month").toISOString(false),
    });

    const filterApply = () => {
        setSearchState({ ...tempSearchState });
    }

    const uri = "data:application/vnd.ms-excel;base64,";

    const download = (fileName: string, base64EncodedFile: string): boolean => {
        const element = window.document.createElement("a");
        element.href = uri + base64EncodedFile;
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return true;
    };

    const exportToXlsx = () => {
        if (!data) return;
        const currencyFormat = "\"£\"#,##0.00_);\\\\(\"£\"#,##0.00\\\\)";

        const voucherData = [
            [ "Date", "Retailer Name", "Voucher Name", "Used Amount", "Discount Rate", "After Discount", "Saved" ],
            ...data.map((record, index) => {
                return [
                    { t: "d", v: record.purchasedDate },
                    { t: "s", v: record.retailerName ? record.retailerName : "Not Specified" },
                    { t: "s", v: record.voucherName },
                    { t: "n", v: record.usedAmount },
                    { t: "n", v: record.discountRate, z: "0.00%" },
                    { t: "n", f: `D${index + 2} * (1 - E${index + 2})`, z: currencyFormat},
                    { t: "n", f: `D${index + 2} - F${index + 2}`, z: currencyFormat},
                ];
            }),
        ];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(voucherData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet A');

        // worksheet["!merges"] = [ { s: { c: 0, r: 3 }, e: { c: 1, r: 3 } } ];

        const base64EncodedFile = XLSX.write(workbook, { bookType: "xlsx", type: "base64" });

        download(`voucherStatistics.xlsx`, base64EncodedFile);
    }

    useEffect(() => {
        trackEvent("voucher", "statistics", `period from ${moment(searchState.purchaseDateStart).format("YYYY-MM-DD")} to ${moment(searchState.purchaseDateEnd).format("YYYY-MM-DD")}`);
    }, [ searchState ]);

    return (
        <>
            <Title title="resources.voucher.page.statistics.title" />

            <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack direction="row" spacing={2} sx={{ py: 3 }}>
                        <DesktopDatePicker
                            label="Start Date"
                            value={moment(tempSearchState.purchaseDateStart)}
                            showDaysOutsideCurrentMonth
                            dayOfWeekFormatter={(day) => {
                                return day.format("ddd");
                            }}
                            onError={(error, value) => {
                                setError(error);
                            }}
                            onChange={(newValue, context) => {
                                if (newValue) {
                                    setTempSearchState((prev) => ({
                                        purchaseDateStart: newValue.toISOString(false),
                                        purchaseDateEnd: prev.purchaseDateEnd,
                                    }));
                                }
                            }} />
                        <DesktopDatePicker
                            label="End Date"
                            value={moment(tempSearchState.purchaseDateEnd)}
                            showDaysOutsideCurrentMonth
                            minDate={moment(tempSearchState.purchaseDateStart)}
                            maxDate={moment().endOf("month")}
                            dayOfWeekFormatter={(day) => {
                                return day.format("ddd");
                            }}
                            onError={(error, value) => {
                                setError(error);
                            }}
                            onChange={(newValue, context) => {
                                if (newValue) {
                                    setTempSearchState((prev) => ({
                                        purchaseDateStart: prev.purchaseDateStart,
                                        purchaseDateEnd: newValue.toISOString(false),
                                    }));
                                }
                            }}
                        />
                        <Button variant="contained" color="success" onClick={() => filterApply()}>Apply</Button>
                    </Stack>
                </LocalizationProvider>
            </Box>

            <Divider />

            {! isLoading && data && data.length > 0 && (
                <Fragment>
                    <Card>
                        <CardHeader
                            title="Consumption List"
                            action={<Button onClick={() => exportToXlsx()}>Export to Excel</Button>}
                        />
                        <Divider />
                        <CardContent>
                            <TableContainer component="div" sx={{ maxHeight: 400 }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Retailer Name</TableCell>
                                            <TableCell>Voucher Name</TableCell>
                                            <TableCell>Used Amount</TableCell>
                                            <TableCell>After Discount</TableCell>
                                            <TableCell>Saved</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((details, index) => {
                                            const {
                                                purchasedDate,
                                                retailerName,
                                                voucherName,
                                                usedAmount,
                                                discountRate
                                            } = details;

                                            return (
                                                <TableRow key={`voucher-statistics-table-row-${index}`}>
                                                    <TableCell>{moment(purchasedDate).format("L")}</TableCell>
                                                    <TableCell>{retailerName || "Not Specified"}</TableCell>
                                                    <TableCell>{voucherName}</TableCell>
                                                    <TableCell>&pound;{usedAmount}</TableCell>
                                                    <TableCell>&pound;{(usedAmount * (1 - discountRate)).toFixed(2)} ({(discountRate * 100).toFixed(2)}%)</TableCell>
                                                    <TableCell>&pound;{(usedAmount - usedAmount * (1 - discountRate)).toFixed(2)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>

                    <Grid container>
                        <Grid xs={12} md={6}>
                            <VoucherConsumptionRetailerChart details={data} type="normal" />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <VoucherConsumptionRetailerChart details={data} type="saved" />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <VoucherConsumptionYearChart details={yearData ?? []} />
                        </Grid>
                    </Grid>

                </Fragment>
            )}
        </>
    );
};

export default VoucherStatistics;
