import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import Box from "@mui/material/Box";
import ProductGroupEditForm from "./ProductGroupEditForm";

const ProductGroupCreate = () => {

    const productGroupCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Product Groups',
            isCurrent: false,
            resource: "productGroup",
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Product Group Management"
                redirect="list"
        >
            <Box>
                <CustomBreadcrumbs title="Create Product Group" items={productGroupCreateBreadcrumbItems} />
                <ProductGroupEditForm type="create" />
            </Box>
        </Create>
    );

}

export default ProductGroupCreate;
