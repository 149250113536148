import React, { useMemo } from "react";
import {
    Box, Card, CardContent, CardHeader, Divider, FormControlLabel, Paper, Switch,
} from "@mui/material";
import { useLocaleState } from "ra-core";
import moment from "moment";
import { OctopusPeriodUsage, OctopusPeriodUsageMap } from "../../types/types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type OctopusConsumptionTableProps = {
    periodUsageMap: OctopusPeriodUsageMap;
}

const OctopusConsumptionTable: React.FC<OctopusConsumptionTableProps> = ({ periodUsageMap }) => {

    const [ locale ] = useLocaleState();
    const [showUsedOnly, setShowUsedOnly] = React.useState(false)

    const columns: GridColDef[] = [
        {
            field: "from",
            headerName: "Period",
            valueGetter: (params) => `${moment(params).locale(locale).format("L LT")}`,
            width: 200,
        },
        {
            field: "consumption",
            headerName: "Consumption",
            type: "number",
            width: 200,
            valueFormatter: (params: number) => `${params > 0 ? params.toFixed(2) : 0} kWh`,

        },
        {
            field: "cost",
            headerName: "Cost",
            width: 200,
            type: "number",
            valueFormatter: (params: number) => `${params > 0 ? params.toFixed(2) : 0} p`,
        },
        {
            field: "costPerKwh",
            headerName: "Cost per kWh",
            width: 200,
            type: "number",
            valueFormatter: (params: number) => `${params > 0 ? params.toFixed(2) : 0} p/kWh`,
        }
    ];

    const rows: OctopusPeriodUsage[] = useMemo(() => {
        const usages: OctopusPeriodUsage[] = Object.keys(periodUsageMap).map((day) => {
            return periodUsageMap[day];
        });
        return showUsedOnly ? usages.filter(usage => usage.consumption > 0) : usages;
    }, [periodUsageMap, showUsedOnly]);

    if (Object.keys(periodUsageMap).length === 0) {
        return (<Paper sx={{ m: 1, minHeight: "500px", textAlign: "center", lineHeight: "500px" }}>No tariffs found</Paper>);
    }

    return (
        <Card sx={{ m: 1 }}>
            <CardHeader
                title="Consumption List"
                action={
                    <Box>
                        <FormControlLabel
                            control={<Switch checked={showUsedOnly} />}
                            label="Show records with consumption only"
                            onClick={() => {
                                setShowUsedOnly((prev) => {
                                    return !prev;
                                })
                            }}
                        />
                    </Box>
                }
            />
            <Divider />
            <CardContent>
                <DataGrid
                    disableRowSelectionOnClick
                    rowHeight={32}
                    autoHeight
                    sx={{

                    }}
                    getRowId={(row) => row.from}
                    columns={columns}
                    rows={rows}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 50]}
                />
            </CardContent>
        </Card>
    );

};

export default OctopusConsumptionTable;
