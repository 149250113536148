import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { SHOP_STATUS } from "./ShopConstant";

const ShopCreate = () => {
    const shopCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Shops',
            isCurrent: false,
            resource: 'shop',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Shop Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Create Shop" items={shopCreateBreadcrumbItems} />
                <Box sx={{ width: '100%' }}>
                    <SimpleForm>
                        <Box sx={{ width: '100%' }}>
                            <Card>
                                <CardContent sx={{ width: '100%' }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput source="name" fullWidth variant="standard" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput source="description" fullWidth variant="standard" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <SelectInput source="status" fullWidth variant="standard"
                                                         choices={SHOP_STATUS}
                                                         defaultValue="A"
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </SimpleForm>
                </Box>
            </Box>
        </Create>
    );
};

export default ShopCreate;
