import React, { useMemo } from "react";
import ReactApexChart, { Props } from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { Divider, Paper } from "@mui/material";
import moment from "moment";
import { useLocaleState } from "ra-core";
import { OctopusPeriodUsage, OctopusPeriodUsageMap } from "../../types/types";

type OctopusConsumptionDayChartProps = {
    periodUsageMap: OctopusPeriodUsageMap;
}

const OctopusConsumptionDayChart: React.FC<OctopusConsumptionDayChartProps> = ({ periodUsageMap }) => {

    console.log(periodUsageMap);

    const [ locale ] = useLocaleState();
    moment.locale(locale);

    const state: Props = useMemo<Props>(() => {
        return {
            series: [
                {
                    name: 'Consume (kWh)',
                    type: 'line',
                    data: Object.keys(periodUsageMap).map((key) => (
                        {
                            x: periodUsageMap[key].from,
                            y: periodUsageMap[key].consumption,
                            meta: {
                                usage: { ...periodUsageMap[key] },
                            },
                        }
                    )),
                },
                {
                    name: 'Unit Rate (kWh)',
                    type: 'line',
                    data: Object.keys(periodUsageMap).map((key) => (
                        {
                            x: periodUsageMap[key].from,
                            y: periodUsageMap[key].costPerKwh,
                            meta: {
                                usage: { ...periodUsageMap[key] },
                            },
                        }
                    )),
                },
                {
                    name: 'Standing Charge',
                    type: "bar",
                    data: Object.keys(periodUsageMap).map((key) => (
                        {
                            x: periodUsageMap[key].from,
                            y: periodUsageMap[key].standingCharge,
                            meta: {
                                usage: { ...periodUsageMap[key] },
                            },
                        }
                    )),
                },
                {
                    name: 'Cost (£)',
                    type: 'bar',
                    data: Object.keys(periodUsageMap).map((key) => (
                        {
                            x: periodUsageMap[key].from,
                            y: periodUsageMap[key].cost,
                            meta: {
                                usage: { ...periodUsageMap[key] },
                            },
                        }
                    )),
                },
            ],
            options: {
                chart: {
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                        animateGradually: {
                            enabled: false,
                        },
                        dynamicAnimation: {
                            enabled: false,
                        },
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                stroke: {
                    curve: "straight",
                    width: [ 5, 5, 0, 0 ],
                },
                dataLabels: {
                    enabled: false,
                },
                theme: {
                    mode: "dark",
                },
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: (options) => {
                        const { dataPointIndex, w } = options;
                        const data = w.config.series[0].data[dataPointIndex];
                        if (! data && ! data.meta && ! data.meta.usage)
                            return null;
                        const usage: OctopusPeriodUsage = data.meta.usage;

                        return renderToString(
                            <Paper>
                                Start Time: {moment(usage.from).format("LLL")}<br />
                                End Time: {moment(usage.to).format("LLL")}<br />
                                Consumption: {usage.consumption.toFixed(3)} kWh <br />
                                Average Cost: {usage.costPerKwh.toFixed(3)}p/kWh
                                <Divider />
                                Estimated Cost: {(usage.cost)?.toFixed(3)}p<br />
                            </Paper>
                        );
                    },
                    fillSeriesColor: false,
                    style: {
                        fontSize: "12px",
                    },
                    onDatasetHover: {
                        highlightDataSeries: false
                    },
                },
                xaxis: {
                    type: "category",
                    position: "bottom",
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [ 0, 100 ],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    labels: {
                        formatter(value: any): string | string[] {
                            return moment(value).format("DD/MM");
                        }
                    },
                    // tickAmount: 20,
                },
                yaxis: [
                    {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return val?.toFixed(2) ?? "";
                            }
                        },
                        title: {
                            text: 'Consumption (kWh)',
                        },
                        min: 0,
                    },
                    {
                        opposite: true,
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return `${val.toFixed(2)}`;
                            }
                        },
                        title: {
                            text: 'Unit Rate (p/kWh)',
                        },
                        min: 0,
                    },
                    {
                        seriesName: ['Standing Charge', 'Cost (£)'],
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return (val / 100).toFixed(1);
                            }
                        },
                        title: {
                            text: 'Cost (£)',
                        },
                        min: 0,
                    },
                ],
            },
        };
    }, [periodUsageMap]);

    console.log(state);

    return (
        <Paper sx={{
            m: 1,
            p: 3,
        }}>
            <ReactApexChart options={state.options} series={state.series} type="bar" height={400} />
        </Paper>
    );
};

export default OctopusConsumptionDayChart;
