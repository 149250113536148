import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import { Box } from "@mui/material";
import BranchEditForm from "./BranchEditForm";

const BranchCreate = () => {

    const branchEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Branches',
            isCurrent: false,
            resource: 'branch',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Branch Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Create Branch" items={branchEditBreadcrumbItems} />
                <BranchEditForm type="create" />
            </Box>
        </Create>
    )

}

export default BranchCreate;
