import React from "react";
import { Route } from "react-router-dom";
import StoreMembershipIndex from "./StoreMembershipIndex";

const StoreMembership = (
    <Route path="storeMembership">
        <Route path="" element={<StoreMembershipIndex />} />
    </Route>
);

export default StoreMembership;
