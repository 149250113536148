import React from "react";
import { Box } from "@mui/material";
import CustomBreadcrumbs from "../../component/CustomBreadcrumbs";
import ProductEditForm from "./ProductEditForm";
import { useParams } from "react-router-dom";
import { Edit } from "react-admin";

const ProductEdit: React.FC = () => {

    const { productId } = useParams();

    return (
        <Edit actions={false}
                    id={productId}
                    component="div"
                    mutationMode="pessimistic"
                    title="Product Management"
                    resource="product"
                    redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Edit Product" items={[]} />
                <ProductEditForm type="edit" />
            </Box>
        </Edit>
    )
}

export default ProductEdit;
