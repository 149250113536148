import React from "react";
import { DeleteWithConfirmButton, SelectField, Show, useShowContext } from "react-admin";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import CustomEditButton from "../../component/CustomEditButton";
import { COMMON_STATUS } from "../../constant/CommonStatus";

const QuantityUnitShow = () => {

    const QuantityUnitShowLayout = () => {
        const { record, isLoading, error, refetch } = useShowContext();

        if (isLoading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error!</div>;
        }

        const CardHeaderAction = () => (
            <Box>
                <CustomEditButton />
                <DeleteWithConfirmButton />
                <CustomDeleteIconButton redirectTo='reload' onSuccessFunction={() => refetch()} />
            </Box>
        );

        return (

            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={`Unit #${record.recordId}`} action={<CardHeaderAction />} />
                    <CardContent sx={{ width: '100%' }}>
                        <Typography data-testid="HelloWorld">Record ID: {record.recordId}</Typography>
                        <Typography>Name in singular: {record.nameSingular}</Typography>
                        <Typography>Name in plural: {record.namePlural}</Typography>
                        <Typography>Description: {record.description}</Typography>
                        <Typography>Type: <SelectField source="status" choices={COMMON_STATUS} /></Typography>


                        <Typography>Created By: {record.createdBy}</Typography>
                        <Typography>Created Date: {record.createdDate}</Typography>
                        <Typography>Updated By: {record.updatedBy}</Typography>
                        <Typography>Updated Date: {record.updatedDate}</Typography>

                    </CardContent>
                </Card>
            </Box>
        )

    }

    const quantityUnitBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Quantity Units',
            isCurrent: false,
            resource: 'quantityUnit',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: true,
        },
    ];

    return (
        <Show
            actions={false}
            component="div"
            title="Quantity Unit Management"
        >
            <Box>
                <CustomBreadcrumbs title="Quantity Unit Management" items={quantityUnitBreadcrumbItems} />
                <Card>
                    <CardHeader title="View Quantity Unit" />
                    <Divider />
                    <CardContent>
                        <QuantityUnitShowLayout />
                    </CardContent>
                </Card>
            </Box>
        </Show>
    )
}

export default QuantityUnitShow;