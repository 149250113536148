import React, { useRef, useState } from "react";
import {
    AutocompleteInput,
    maxValue,
    minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import { Card, CardContent, Dialog, Grid, IconButton, InputAdornment } from "@mui/material";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import { useFormContext } from "react-hook-form";
import { convertToFormatName } from "../../../helper/BarcodeTypeUtils";
import NewScanner from "../../scanner/NewScanner";
import { BARCODE_FORMAT_TYPE } from "../../../constant/BarcodeFormatType";
import { COMMON_STATUS } from "../../../constant/CommonStatus";
import { useParams } from "react-router-dom";

const ProductBarcodeEditForm: React.FC<EditFormProps> = ({ type }) => {

    const { productId } = useParams();
    const [ dialogOpen, setDialogOpen ] = useState(false);

    const dialogOpenHandler = () => {
        setDialogOpen(true);
    }

    const ScannerDialog = () => {

        const codeReader = useRef(new BrowserMultiFormatReader());
        const { setValue } = useFormContext();

        const dialogCloseHandler = () => {
            setDialogOpen(false);
            codeReader.current.reset();
        }

        const resultHandler = (result: Result) => {
            setValue("code", result.getText(), { shouldTouch: true, shouldDirty: true, });
            setValue("format", convertToFormatName(result.getBarcodeFormat()), {
                shouldTouch: true,
                shouldDirty: true,
            });
            setDialogOpen(false);
        }

        return (
            dialogOpen ? (
                <Dialog open={dialogOpen}
                        onClose={dialogCloseHandler}
                >
                    <NewScanner resultHandler={resultHandler}
                                codeReader={codeReader}
                    />
                </Dialog>
            ) : null
        )

    }

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>

                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput
                                name="code"
                                source="code"
                                label="Barcode"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => dialogOpenHandler()}>
                                                <AddAPhotoOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput
                                name="format"
                                source="format"
                                label="Barcode Format Type"
                                choices={BARCODE_FORMAT_TYPE}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         fullWidth
                                         source="status"
                                         choices={COMMON_STATUS}
                                         defaultValue="A"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="amount"
                                         source="amount"
                                         fullWidth
                                         step={1}
                                         min={1}
                                         max={99999}
                                         validate={[ required(), minValue(1), maxValue(99999) ]}
                                         isRequired
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput label="Product"
                                            source="productId"
                                            reference="product"
                                            fullWidth
                            >
                                <AutocompleteInput name="productId"
                                                         optionText={value => value.name}
                                                         optionValue="recordId"
                                                         filterSelectedOptions={false}
                                                         TextFieldProps={{
                                                             InputLabelProps: {
                                                                 shrink: true,
                                                             },
                                                         }}
                                                         defaultValue={productId}
                                                         disableClearable
                                                         isRequired
                                />
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="note"
                                       source="note"
                                       fullWidth
                            />
                        </Grid>

                    </Grid>
                    <ScannerDialog />

                </CardContent>
            </Card>
        </SimpleForm>
    );
};

export default ProductBarcodeEditForm;
