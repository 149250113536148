import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "@tanstack/react-query";
import getFileDetails from "./getFileDetails";

const useGetFileDetails = (fileId?: string) => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getFile', projectUuid, fileId ],
        queryFn: () => getFileDetails(projectUuid, userUuid, fileId || ""),
        enabled: projectUuid.length > 0 && userUuid.length > 0 && !! fileId,
    });
};

export default useGetFileDetails;
