import React, { ReactElement } from "react";
import { useCreatePath, useRecordContext, useResourceContext } from "react-admin";
import { IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import { useNavigate } from "react-router";
import { Queue } from "@mui/icons-material";
import CustomIconButton from "./common/CustomIconButton";


// useful to prevent click bubbling in a Datagrid with rowClick
const stopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation();

// const test = 'recordId';
// const omitId = ({ id , ${ , ...rest }: any) => rest;

const defaultOmitProperties = [ 'id', 'recordId', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate' ];

const omitProperties = (record: any, propertyNames: string[]) => {
    const newRecord = Object.assign({}, record);
    propertyNames.forEach((propertyName) => {
        if (newRecord[propertyName] !== undefined) {
            delete newRecord[propertyName];
        }
    });
    return newRecord;
}

const defaultIcon = <Queue sx={{ fontSize: 16 }} />;

const CustomCloneButton: React.FC<{
    title?: string,
    icon?: ReactElement,
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>,
    IconButtonProps?: IconButtonProps,
    omitProps?: string[],
}> =
    ({
         title = "Clone",
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         omitProps = defaultOmitProperties,
     }) => {
        const resource = useResourceContext();
        const record = useRecordContext();
        const createPath = useCreatePath();
        const navigate = useNavigate();
        const pathname = createPath({ resource, type: 'create' });
        const toPath = `${pathname}?source=${encodeURIComponent(JSON.stringify(omitProperties(record, omitProps)))}`;

        const disabled = IconButtonProps ? IconButtonProps.disabled ? IconButtonProps.disabled : false : false;
        if (! record) return null;

        return (
            <Tooltip title={title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        disabled={disabled}
                        size="small"
                        color="softPrimary"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(toPath, {
                                state: {
                                    _scrollToTop: true,
                                }
                            });
                        }}
                        {...IconButtonProps}
                    >
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        );
    }

export default CustomCloneButton;
