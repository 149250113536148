import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import { useTheme } from "react-admin";

export type CustomIconButtonProps = Omit<IconButtonProps, "color"> & {
    color?: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "dark" | "light";
    soft?: boolean;
    rounded?: boolean;
};

type ButtonStyle = {
    backgroundColor: string;
    textColor: string;
    borderColor: string;
    onHoverBackgroundColor: string;
    onHoverTextColor: string;
    onHoverBorderColor: string;
}

const getButtonStyle = (
    color: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "dark" | "light",
    soft: boolean,
    isDarkMode: boolean,
): ButtonStyle => {
    switch (color) {
        case "light":
            return {
                backgroundColor: isDarkMode ? "#32394e" : "#eff2f7",
                textColor: isDarkMode ? "#c3cbe4" : "#000",
                borderColor: isDarkMode ? "#32394e" : "#eff2f7",
                onHoverBackgroundColor: isDarkMode ? "#5b5f6b" : "#bfc2c6",
                onHoverTextColor: isDarkMode ? "#c3cbe4" : "#000",
                onHoverBorderColor: isDarkMode ? "#32394e" : "#eff2f7",
            }
        case "dark":
            if (soft)
                return {
                    backgroundColor: isDarkMode ? "rgba(166,176,207,0.1)" : "rgba(52,58,64,0.1)",
                    textColor: isDarkMode ? "#a6b0cf" : "#343a40",
                    borderColor: "transparent",
                    onHoverBackgroundColor: isDarkMode ? "#a6b0cf" : "#343a40",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else

                return {
                    backgroundColor: isDarkMode ? "#a6b0cf" : "#343a40",
                    textColor: "#fff",
                    borderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                    onHoverBackgroundColor: isDarkMode ? "#5b5f6b" : "#52585d",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: isDarkMode ? "#a6b0cf" : "#343a40",
                }
        case "danger":
            if (soft)
                return {
                    backgroundColor: "rgba(244,106,106,0.1)",
                    textColor: "#f46a6a",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#f46a6a",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else

                return {
                    backgroundColor: "#f46a6a",
                    textColor: "#fff",
                    borderColor: "#f46a6a",
                    onHoverBackgroundColor: "#c35555",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#f46a6a",
                }
        case "warning":
            if (soft)
                return {
                    backgroundColor: "rgba(241,180,76,0.1)",
                    textColor: "#f1b44c",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#f1b44c",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else

                return {
                    backgroundColor: "#f1b44c",
                    textColor: "#fff",
                    borderColor: "#f1b44c",
                    onHoverBackgroundColor: "#cd9941",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#f1b44c",
                }
        case "info":
            if (soft)
                return {
                    backgroundColor: "rgba(80,165,241,0.1)",
                    textColor: "#50a5f1",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#50a5f1",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else

                return {
                    backgroundColor: "#50a5f1",
                    textColor: "#fff",
                    borderColor: "#50a5f1",
                    onHoverBackgroundColor: "#448ccd",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#50a5f1",
                }
        case "success":
            if (soft)
                return {
                    backgroundColor: "rgba(52,195,143,0.1)",
                    textColor: "#34c38f",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#34c38f",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else
                return {
                    backgroundColor: "#34c38f",
                    textColor: "#fff",
                    borderColor: "#34c38f",
                    onHoverBackgroundColor: "#2ca67a",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#34c38f",
                }
        case "secondary":
            if (soft)
                return {
                    backgroundColor: "rgba(116,120,141,0.1)",
                    textColor: "#74788d",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#74788d",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else
                return {
                    backgroundColor: "#74788d",
                    textColor: "#fff",
                    borderColor: "#74788d",
                    onHoverBackgroundColor: "#636678",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#74788d",
                }
        case "primary":
        default:
            if (soft)
                return {
                    backgroundColor: "rgba(85, 110, 230, 0.1)",
                    textColor: "#556ee6",
                    borderColor: "transparent",
                    onHoverBackgroundColor: "#556ee6",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "transparent",
                }
            else
                return {
                    backgroundColor: "#556ee6",
                    textColor: "#fff",
                    borderColor: "#556ee6",
                    onHoverBackgroundColor: "#485ec4",
                    onHoverTextColor: "#fff",
                    onHoverBorderColor: "#556ee6",
                }
    }
};

const CustomIconButton: React.FC<CustomIconButtonProps> =
    ({
         children,
         color = "primary",
         soft = false,
         rounded = false,
         ...rest
     }) => {

        const [ theme ] = useTheme();

        const {
            backgroundColor,
            textColor,
            borderColor,
            onHoverBackgroundColor,
            onHoverTextColor,
            onHoverBorderColor
        } = getButtonStyle(color, soft, theme === "dark");

        return (
            <IconButton
                size="small"
                sx={{
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    color: textColor,
                    opacity: 1,
                    borderRadius: 3,
                    "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                        backgroundColor: onHoverBackgroundColor,
                        borderRadius: 3,
                    },
                    "&:disabled": {
                        opacity: 0.65,
                    },
                    "&:hover": {
                        backgroundColor: onHoverBackgroundColor,
                        borderColor: onHoverBorderColor,
                        color: onHoverTextColor,
                        opacity: 1,
                    },
                    ...rest.sx,
                }}
                {...rest}
            >
                {children}
            </IconButton>
        );
    };

export default CustomIconButton;
