import { ButtonProps, Interpolation, Theme } from "@mui/material";

export const SOFT_ERROR_COLOR = "#f46a6a";
export const SOFT_ERROR_BACKGROUND_COLOR = "rgba(244, 106, 106, 0.1)";

export const SOFT_ERROR_BUTTON: {props: Partial<ButtonProps<"button", {}>>, style: Interpolation<{theme: Theme}>}[] = [
    {
        props: {
            color: 'mainPrimary',
        },
        style: {
            fontSize: "0.8125rem",
            fontWeight: "400",
            "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                backgroundColor: "#1E69D1",
                borderRadius: 5,
            },
            "&:hover": {
                color: "#fff",
                opacity: 1,
            },
            "&:disabled": {
                color: "#fff",
                opacity: 0.5,
            }
        }
    },
    {
        props: {
            color: 'mainPrimary',
            variant: "contained",
        },
        style: {
            color: "#fff",
            backgroundColor: '#556ee6',
            "&:hover": {
                backgroundColor: "#4053ad",
            },
            "&:disabled": {
                backgroundColor: "#556ee6",
            }
        }
    },
    {
        props: {
            color: 'mainPrimary',
            variant: "outlined",
        },
        style: {
            color: "#556ee6",
            backgroundColor: 'transparent',
            "&:hover": {
                backgroundColor: "#556ee6",
            },
            "&:disabled": {
                backgroundColor: "#556ee6",
            }
        }
    },
];

export const SOFT_ERROR = {
    BUTTON: {
        COLOR: "#fff",
        BG: "#556ee6",
        BORDER_COLOR: "#556ee6",
        HOVER_COLOR: "#fff",
        HOVER_BG: "#485ec4",
        HOVER_BORDER_COLOR: "#4458b8",
// --bs-btn-focus-shadow-rgb: 111, 132, 234;
// --bs-btn-active-color: #fff;
// --bs-btn-active-bg: #4458b8;
// --bs-btn-active-border-color: #4053ad;
// --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
// --bs-btn-disabled-color: #fff;
// --bs-btn-disabled-bg: #556ee6;
// --bs-btn-disabled-border-color: #556ee6;
    }
}
