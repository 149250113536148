import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import getDownloadUrl from "../data/getDownloadUrl";
import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import axios from "axios";

const DownloadButton = () => {

    const [ isDownloading, setIsDownloading ] = useState(false);

    useEffect(() => {
        setIsDownloading(false);
    }, []);

    const download = () => {
        setIsDownloading(true);
        const projectUuid = getProjectUuid() || "";
        const userUuid = getUserUuid() || "";
        getDownloadUrl(projectUuid, userUuid, "eece2a7c-f0a5-463d-bcea-13d50e5eb997/SFO-Page-1-2.pdf")
            .then(result => result.data)
            .then(result => {
                axios
                    .get(result.downloadUrl, {
                        responseType: "blob"
                    })
                    .then(result => {
                        const element = window.document.createElement("a");
                        element.href = window.URL.createObjectURL(
                            new Blob([ result.data ]),
                        );
                        element.download = "111.pdf";
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        setIsDownloading(false);
                    });
            });
    };

    return (
        <Button onClick={() => download()} disabled={isDownloading}>Download</Button>
    );
};

export default DownloadButton;