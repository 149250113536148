import React, { useState } from "react";
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider
} from "@mui/material";
import { Form, maxLength, minLength, regex, required, TextInput, useLogout, useRedirect } from "react-admin";
import { updatePassword } from "./dataHookTest";
import Swal from "sweetalert2";

const PasswordChange: React.FC<{}> = ({}) => {

    const [ isLoading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const redirect = useRedirect();
    const logout = useLogout();

    const passwordValidation = [
        required(),
        minLength(6),
        maxLength(20),
        regex(/^([a-zA-Z0-9$./\\#@!$]){6,20}$/, "Please follow instruction to update password")
    ];

    const formSubmit = (form: any) => {
        setLoading(true);
        setErrorMessage("");
        updatePassword(form as PasswordChangeForm)
            .then((result) => {
                Swal.fire({
                    title: "Update Successfully",
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: "Return to Login",
                    allowOutsideClick: false,
                }).then((result) => {
                    logout();
                });
            })
            .catch((error) => {
                const data = error.response.data;
                setErrorMessage(`(${data.errorCode}) ${data.errorReason}`);
                setLoading(false);
            });
    };

    return (
        <Form mode="onBlur" reValidateMode="onBlur" onSubmit={formSubmit}>
            <Box sx={{ marginTop: "1em" }}>
                <Card>
                    <CardHeader title="Change Password" />
                    <Divider />
                    <CardContent>

                        <Alert severity="info" sx={{ marginBottom: "1em" }}>
                            At least 6 characters long, but not longer that 20 characters.<br/>
                            Case-sensitive, special characters like !@#
                        </Alert>

                        {!isLoading && errorMessage && (
                            <Alert severity="error" sx={{ marginBottom: "1em" }}>
                                { errorMessage }
                            </Alert>
                        )}

                        <TextInput name="currentPassword"
                                   source="currentPassword"
                                   label="Current passowrd"
                                   type="password"
                                   validate={[required()]}
                                   fullWidth
                        />
                        <TextInput name="newPassword"
                                   source="newPassword"
                                   label="New passowrd"
                                   type="password"
                                   validate={passwordValidation}
                                   fullWidth
                        />
                        <TextInput name="newConfirmPassword"
                                   source="newConfirmPassword"
                                   label="Re-eneter new passowrd"
                                   type="password"
                                   validate={passwordValidation}
                                   fullWidth
                        />
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button type="submit"
                                color="primary"
                                disabled={isLoading}
                        >
                            {isLoading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            Update
                        </Button>
                    </CardActions>
                </Card>
            </Box>

        </Form>
    );
};

export default PasswordChange;
