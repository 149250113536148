import * as React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import { Datagrid, FunctionField, List, ReferenceField, SelectField, TextField } from "react-admin";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import { BRANCH_STATUS, BRANCH_TYPE } from "./BranchConstant";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import CreateIconButton from "../../component/CreateIconButton";

const BranchList = () => {

    const branchListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Branches',
            isCurrent: true,
        },
    ];

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            empty={false}
            component="div"
            title="Branch Management"
        >
            <Box>
                <CustomBreadcrumbs title="Branch Management" items={branchListBreadcrumbItems} />
                <Card>
                    <CardHeader title="Branches" action={<CreateIconButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )} />
                            <TextField source="recordId" />
                            <ReferenceField sortable={false} reference="shop" source="shopId" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="name" />
                            <TextField source="description" />
                            <SelectField source="type" choices={BRANCH_TYPE} />
                            <SelectField source="status" choices={BRANCH_STATUS} />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );

}

export default BranchList;
