import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import { Box } from "@mui/material";
import AnnouncementEditForm from "./AnnouncementEditForm";

const AnnouncementCreate: React.FC = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Announcement',
            resource: 'announcement',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Announcement Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Create Announcement" items={breadcrumbItems} />
                <AnnouncementEditForm type="create" />
            </Box>
        </Create>
    )
}

export default AnnouncementCreate;
