import CategoryIcon from "@mui/icons-material/Bookmark";
import QuantityUnitList from "./QuantityUnitList";
import QuantityUnitCreate from "./QuantityUnitCreate";
import QuantityUnitEdit from "./QuantityUnitEdit";
import QuantityUnitShow from "./QuantityUnitShow";
import { Resource } from "react-admin";
import React from "react";

const QuantityUnit = (

    <Resource key="quantityUnit"
              name="quantityUnit"
              show={QuantityUnitShow}
              list={QuantityUnitList}
              create={QuantityUnitCreate}
              edit={QuantityUnitEdit}
              icon={CategoryIcon}
    />
);

export default QuantityUnit;
