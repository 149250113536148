import React, { Fragment, useEffect } from "react";
import { Alert, AlertTitle, Button, Typography, useTheme } from "@mui/material";
import { Authenticated, Title, useGetList, } from "react-admin";
import { getProjectUuid, parseJwt } from "./auth/AuthUtils";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

export const Dashboard = () => {

    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const DashboardContent = () => {
        const navigate = useNavigate();
        const jwtPayload: any = parseJwt();
        const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];
        // ensure run one time only

        useEffect(() => {

            if (! getProjectUuid()) {
                const jwtPayload: any = parseJwt();
                const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];

                console.log(projects)
                if (projects.length === 0) {
                    console.log('No Project Assigned!');
                } else if (projects.length === 1) {
                    console.log('only one project! Auto assign that project to localStorage');
                    localStorage.setItem('project', projects[0]);
                } else {
                    if (projects.length > 1) {
                        console.log('More than one project was found!');
                        navigate('/projectSelect');
                    }
                }
            }
        }, [ navigate ]);

        if (projects.length === 0)
            return (
                <Fragment>
                    You don't have any projects assigned. Please contact Administrator.
                </Fragment>
            )

        return (
            <Fragment>
                <Title title="Dashboard" />
                <Typography variant="h6" p={2}>Welcome to HomeCloud</Typography>
            </Fragment>
        );
    };

    const AnnouncementPortal = () => {

        const { data: announcements, total, isLoading, error, refetch } = useGetList(
            "announcement",
            {
                pagination: { page: 1, perPage: 25 },
                sort: { field: "recordId", order: "DESC" },
                filter: {},
                meta: {
                    projectUuid: getProjectUuid(),
                }
            },
            {
                enabled: getProjectUuid() !== null
            }
        );

        if (isLoading || !announcements     || announcements.length === 0) return;

        return (
            <Fragment>
                <Typography variant="h6" m={2}>Announcements</Typography>
                {announcements.map((announcement: Announcement) => (
                    <Alert severity="warning" key={announcement.recordId}>
                        <AlertTitle>By {announcement.createdBy}</AlertTitle>
                        {announcement.message}
                    </Alert>
                ))}
            </Fragment>
        )
    };

    const showAction = (snackbarId: string | number) => {


        return (
            <Fragment>
                <Button>123</Button>
                <Button onClick={() => closeSnackbar(snackbarId)}>123</Button>
            </Fragment>
        );
    };

    return (
        <Authenticated requireAuth>
            <DashboardContent />
            <AnnouncementPortal />

            {/*<Button onClick={() => enqueueSnackbar("\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321", {*/}
            {/*    title: "Hello World",*/}
            {/*    variant: "info",*/}
            {/*    action: showAction,*/}
            {/*    persist: true,*/}
            {/*})}>*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*</Button>*/}

            {/*<Button onClick={() => enqueueSnackbar("ERROR", {*/}
            {/*    variant: "error",*/}
            {/*    action: showAction,*/}
            {/*    persist: true,*/}
            {/*    title: ""*/}
            {/*})}>ERROR</Button>*/}

            {/*<Button onClick={() => enqueueSnackbar("WARNING", {*/}
            {/*    variant: "warning",*/}
            {/*    title: ""*/}
            {/*})}>WARNING</Button>*/}
        </Authenticated>
    );
};
