import React from "react";
import { DeleteWithConfirmButton, SelectField, Show, useShowContext } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { SHOP_STATUS } from "../../shop/ShopConstant";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";

const StockLocationShow = () => {
    const StockLocationShowLayout = () => {
        const { record, isLoading, error, refetch } = useShowContext();

        if (isLoading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error!</div>;
        }

        const CardHeaderAction = () => (
            <Box>
                <CustomEditButton />
                <DeleteWithConfirmButton />
                <CustomDeleteIconButton redirectTo='reload' onSuccessFunction={() => refetch()} />
            </Box>
        );

        return (

            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={`Stock Location #${record.recordId}`} action={<CardHeaderAction />} />
                    <CardContent sx={{ width: '100%' }}>
                        <Typography data-testid="HelloWorld">Record ID: {record.recordId}</Typography>
                        <Typography>Name: {record.name}</Typography>
                        <Typography>Description: {record.description}</Typography>
                        <Typography>Type: <SelectField source="status" choices={SHOP_STATUS} /></Typography>


                        <Typography>Created By: {record.createdBy}</Typography>
                        <Typography>Created Date: {record.createdDate}</Typography>
                        <Typography>Updated By: {record.updatedBy}</Typography>
                        <Typography>Updated Date: {record.updatedDate}</Typography>

                    </CardContent>
                </Card>
            </Box>
        )

    }

    const stockLocationShowBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Stock Location',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'List',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: true,
        },
    ];

    return (
        <Show
            actions={false}
            component="div"
            title="Stock Location Management"
        >
            <Box>
                <CustomBreadcrumbs title="Stock Location Management" items={stockLocationShowBreadcrumbItems} />
                <Card>
                    <CardHeader title="View Stock Location" />
                    <Divider />
                    <CardContent>
                        <StockLocationShowLayout />
                    </CardContent>
                </Card>
            </Box>
        </Show>
    )
}

export default StockLocationShow;