import { Route } from "react-router-dom";
import ProjectRoleCreate from "./ProjectRoleCreate";
import ProjectRoleEdit from "./ProjectRoleEdit";
import ProjectRoleShow from "./ProjectRoleShow";

const ProjectRole = (
    <Route path="role">
        <Route path="create" element={<ProjectRoleCreate />} />
        <Route path=":roleId/edit" element={<ProjectRoleEdit />} />
        <Route path=":roleId/show" element={<ProjectRoleShow />} />
    </Route>
);

export default ProjectRole;
