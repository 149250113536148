import { BarcodeFormat } from "@zxing/library";

export const BARCODE_FORMAT = {
    AZTEC: {
        format: BarcodeFormat.AZTEC,
        id: "AZTEC",
        name: "AZTEC",
    },
    CODE_128: {
        format: BarcodeFormat.CODE_128,
        id: "CODE_128",
        name: "CODE_128",
    },
    CODE_93: {
        format: BarcodeFormat.CODE_93,
        id: "CODE_93",
        name: "CODE_93",
    },
    CODE_39: {
        format: BarcodeFormat.CODE_39,
        id: "CODE_39",
        name: "CODE_39",
    },
    EAN_13: {
        format: BarcodeFormat.EAN_13,
        id: "EAN_13",
        name: "EAN_13",
    },
    EAN_8: {
        format: BarcodeFormat.EAN_8,
        id: "EAN_8",
        name: "EAN_8",
    },
    QR_CODE: {
        format: BarcodeFormat.QR_CODE,
        id: "QR_CODE",
        name: "QR_CODE",
    },
    CODABAR: {
        format: BarcodeFormat.CODABAR,
        id: "CODABAR",
        name: "CODABAR",
    },
    UPC_A: {
        format: BarcodeFormat.UPC_A,
        id: "UPC_A",
        name: "UPC_A",
    },
    UPC_E: {
        format: BarcodeFormat.UPC_E,
        id: "UPC_E",
        name: "UPC_E",
    },
    UPC_EAN_EXTENSION: {
        format: BarcodeFormat.UPC_EAN_EXTENSION,
        id: "UPC_EAN_EXTENSION",
        name: "UPC_EAN_EXTENSION",
    },
    PDF_417: {
        format: BarcodeFormat.PDF_417,
        id: "PDF_417",
        name: "PDF_417",
    },
    RSS_14: {
        format: BarcodeFormat.RSS_14,
        id: "RSS_14",
        name: "RSS_14",
    },
    RSS_EXPANDED: {
        format: BarcodeFormat.RSS_EXPANDED,
        id: "RSS_EXPANDED",
        name: "RSS_EXPANDED",
    },
    ITF: {
        format: BarcodeFormat.ITF,
        id: "ITF",
        name: "ITF",
    },
    DATA_MATRIX: {
        format: BarcodeFormat.DATA_MATRIX,
        id: "DATA_MATRIX",
        name: "DATA_MATRIX",
    },
    MAXICODE: {
        format: BarcodeFormat.MAXICODE,
        id: "MAXICODE",
        name: "MAXICODE",
    },
};

export const BARCODE_FORMAT_TYPE = [
    BARCODE_FORMAT.AZTEC,
    BARCODE_FORMAT.CODE_128,
    BARCODE_FORMAT.CODE_93,
    BARCODE_FORMAT.CODE_39,
    BARCODE_FORMAT.EAN_13,
    BARCODE_FORMAT.EAN_8,
    BARCODE_FORMAT.QR_CODE,
    BARCODE_FORMAT.CODABAR,
    BARCODE_FORMAT.UPC_A,
    BARCODE_FORMAT.UPC_E,
    BARCODE_FORMAT.UPC_EAN_EXTENSION,
    BARCODE_FORMAT.PDF_417,
    BARCODE_FORMAT.RSS_14,
    BARCODE_FORMAT.RSS_EXPANDED,
    BARCODE_FORMAT.ITF,
    BARCODE_FORMAT.DATA_MATRIX,
    BARCODE_FORMAT.MAXICODE,
];