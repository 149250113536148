import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox, CheckboxProps, Chip,
    Divider, FormControl,
    FormControlLabel, FormHelperText, styled
} from "@mui/material";
import { Title } from "react-admin";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext,
    UseFormReturn,
} from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";

interface BnoForm {
    issueDate: Moment | null;
    arrivalDate: Moment | null;
    countArrivalDate: boolean;
    ilrApplyDate: Moment | null;
    bcApplyDate: Moment | null;
    periods: Period[];
}

interface Period {
    departureDate: Moment | null;
    arrivalDate: Moment | null;
    countDepartureDate: boolean;
    countArrivalDate: boolean;
}

interface CalculatedPeriod {
    from: Moment;
    to: Moment;
    count: number;
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props: CheckboxProps) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const BnoCalculation: React.FC = () => {

    const formProps = useForm<BnoForm>({
        reValidateMode: "onChange",
    });

    const [ results, setResults ] = useState<string[]>([]);
    const [ longestAbsence, setLongestAbsence ] = useState<CalculatedPeriod>();
    const [ bcAbsence90, setBcAbsence90 ] = useState<CalculatedPeriod>();
    const [ bcAbsence450, setBcAbsence450 ] = useState<CalculatedPeriod>();

    useEffect(() => {
        const cache = localStorage.getItem("bno-calculation");
        if (cache) {
            const cacheInJson = JSON.parse(cache);
            formProps.reset({
                issueDate: moment(cacheInJson.issueDate),
                arrivalDate: moment(cacheInJson.arrivalDate),
                countArrivalDate: cacheInJson.countArrivalDate,
                ilrApplyDate: moment(cacheInJson.ilrApplyDate),
                bcApplyDate: moment(cacheInJson.bcApplyDate),
                periods: cacheInJson.periods.map((period: any) => {
                    return {
                        departureDate: moment(period.departureDate),
                        arrivalDate: moment(period.arrivalDate),
                        countDepartureDate: period.countDepartureDate,
                        countArrivalDate: period.countArrivalDate,
                    }
                }),
            });
        } else {
            formProps.reset({
                issueDate: null,
                arrivalDate: null,
                countArrivalDate: false,
                ilrApplyDate: null,
                bcApplyDate: null,
                periods: [],
            });
        }
        // formProps.reset({
        //     issueDate: moment("2023-02-09", "YYYY-MM-DD"),
        //     arrivalDate: moment("2023-05-15", "YYYY-MM-DD"),
        //     countArrivalDate: false,
        //     ilrApplyDate: moment("2028-02-09", "YYYY-MM-DD"),
        //     bcApplyDate: moment("2029-02-09", "YYYY-MM-DD"),
        //     periods: [
        //         {
        //             departureDate: moment("2023-08-31", "YYYY-MM-DD"),
        //             arrivalDate: moment("2023-11-20", "YYYY-MM-DD"),
        //             countDepartureDate: false,
        //             countArrivalDate: false,
        //         },
        //         {
        //             departureDate: moment("2024-08-31", "YYYY-MM-DD"),
        //             arrivalDate: moment("2024-11-20", "YYYY-MM-DD"),
        //             countDepartureDate: false,
        //             countArrivalDate: false,
        //         },
        //     ],
        // });
    }, [ formProps ]);

    const addUncountedPeriod = (uncountedPeriod: string[], startDate: Moment, endDate: Moment, countStartDate?: boolean, countEndDate?: boolean): void => {
        let diff = endDate.clone().diff(startDate, 'days') + (countEndDate ? 1 : 0);
        for (let i = countStartDate ? 0 : 1; i < diff; i++) {
            uncountedPeriod.push(startDate.clone().add(i, 'day').format('YYYY-MM-DD'));
        }
    }

    const onSubmitHandler: SubmitHandler<BnoForm> = (data, event) => {
        if (data.issueDate === null || data.arrivalDate === null || data.ilrApplyDate === null || data.bcApplyDate === null) {
            console.warn("Problem on data!", data);
            return;
        }

        data.periods.forEach((period, index) => {
            if (period.departureDate === null || period.arrivalDate === null) {
                console.warn(`Period ${index} error`, period);
                return;
            }
        });

        const uncountedPeriod: string[] = [];

        addUncountedPeriod(uncountedPeriod, data.issueDate, data.arrivalDate, true, data.countArrivalDate);

        for (let i = 0; i < data.periods.length; i++) {
            const period = data.periods[i];
            if (period.departureDate === null || period.arrivalDate === null) {
                console.warn(`Period ${i} error`, data);
                continue;
            }

            addUncountedPeriod(uncountedPeriod,
                period.departureDate,
                period.arrivalDate,
                period.countDepartureDate,
                period.countArrivalDate
            );
        }

        // Count IRL
        {
            const checkingDate: Moment[] = [];
            checkingDate.push(data.issueDate, data.arrivalDate, data.ilrApplyDate);
            for (let i = 0; i < data.periods.length; i++) {
                const period = data.periods[i];

                if (period.departureDate === null || period.arrivalDate === null) {
                    console.warn(`Period ${i} error`, period);
                    return;
                }

                checkingDate.push(period.departureDate, period.arrivalDate);
            }

            let tempLongestAbsence: CalculatedPeriod = {
                count: 0,
                from: moment(),
                to: moment(),
            }

            const newResult: string[] = [];

            for (let i = 0; i < checkingDate.length; i++) {
                const startDate = checkingDate[i].clone();
                const endDate = checkingDate[i].clone().add(1, 'year');
                const timeToStay = dayDiff(uncountedPeriod, startDate, endDate);
                const timeBeingAbsence = endDate.diff(startDate, 'days') - timeToStay;

                if (! tempLongestAbsence || (tempLongestAbsence.count < timeBeingAbsence)) {
                    console.log("timeToStay: ", timeToStay);
                    console.log("timeBeingAbsence: ", timeBeingAbsence);
                    console.log("longestAbsence: ", longestAbsence);
                    tempLongestAbsence.count = timeBeingAbsence;
                    tempLongestAbsence.from = startDate;
                    tempLongestAbsence.to = endDate;
                }

                if (timeBeingAbsence >= 180) {
                    newResult.push('Period from ' + startDate.format('YYYY-MM-DD') + ' to ' + endDate.format('YYYY-MM-DD') + ' is danger! ' + timeBeingAbsence + ' days absence in this period');
                }
            }

            setResults(newResult);

            console.log(tempLongestAbsence);
            if (tempLongestAbsence.count > 0)
                setLongestAbsence(tempLongestAbsence);

            localStorage.setItem("bno-calculation", JSON.stringify(data));
        }

        // count BC 450
        {
            const bcApplyDateBefore5Year = data.bcApplyDate.clone().subtract(5, 'years');
            const timeBeingStay = dayDiff(uncountedPeriod, bcApplyDateBefore5Year, data.bcApplyDate);
            const timeBeingAbsence = data.bcApplyDate.diff(bcApplyDateBefore5Year.clone(), 'days') - timeBeingStay;
            setBcAbsence450({
                from: bcApplyDateBefore5Year,
                to: data.bcApplyDate,
                count: timeBeingAbsence
            });
        }

        // count BC 90
        {
            const bcApplyDateBefore1Year = data.bcApplyDate.clone().subtract(1, 'years');
            const timeBeingStay = dayDiff(uncountedPeriod, bcApplyDateBefore1Year, data.bcApplyDate);
            const timeBeingAbsence = data.bcApplyDate.diff(moment(bcApplyDateBefore1Year), 'days') - timeBeingStay;
            setBcAbsence90({
                from: bcApplyDateBefore1Year,
                to: data.bcApplyDate,
                count: timeBeingAbsence,
            });
        }
    }

    const dayDiff = (uncountedPeriod: string[], from: Moment, to: Moment) => {
        const d1 = from.clone();
        const d2 = to.clone();
        const positive = d1 >= d2;
        const start = d1 < d2 ? d1 : d2;
        const end = d2 > d1 ? d2 : d1;

        const totalDays = end.diff(start, "days");

        let daysBetween = 0;

        // this will return true since it's the same day, and will in fact return 0
        if (start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')) {
            return daysBetween;
        }

        while (start < end) {
            if (uncountedPeriod.indexOf(start.format('YYYY-MM-DD')) > -1) {
                daysBetween++;
            }
            start.add(1, 'd');
        }

        return totalDays - daysBetween;
    }

    const TestDatePicker: React.FC<{ name: string, label: string }> = ({ name, label }) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={label}
                                value={value}
                                onChange={(event) => onChange(event)}
                                slotProps={{
                                    textField: {
                                        error: !! error,
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    )
                }}
            />
        )
    };
    const CheckBox: React.FC<{ name: string; label: string; helperText?: string }> = ({ name, label, helperText }) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl error={!! error} sx={{ mt: 0 }}>
                        <FormControlLabel
                            control={
                                <BpCheckbox
                                    size="small"
                                    // Value might be undefined when initialization
                                    value={value === true}
                                    onChange={(event) => onChange(event)}
                                    checked={value === true}
                                />}
                            sx={{
                                " .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                },
                            }}
                            label={label}
                        />
                        {helperText && <FormHelperText>{helperText}</FormHelperText>}
                    </FormControl>
                )}
            />
        );
    };

    const LeavePeriods: React.FC<{ formProps: UseFormReturn<BnoForm> }> = ({ formProps: { control } }) => {
        const { fields, append, remove, prepend } = useFieldArray({
            control,
            name: "periods"
        });

        return (
            <Card sx={{ my: 1 }}>
                <CardHeader title="離境資料" action={
                    <Button onClick={() => append({
                        departureDate: null,
                        arrivalDate: null,
                        countDepartureDate: false,
                        countArrivalDate: false,
                    })}>+</Button>
                } />
                <Divider />
                <CardContent>
                    {fields.length === 0 &&
                        <Box>
                            沒有離境資料，可按右方 + 號增加
                        </Box>
                    }
                    {fields.map((period, index) => (
                        <Fragment key={`periods.${index}`}>
                            <Grid container spacing={2}>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name={`periods[${index}].departureDate`} label="離境日期" /> <br />
                                    <CheckBox name={`periods[${index}].countDepartureDate`} label="離境日期計算在內" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name={`periods[${index}].arrivalDate`} label="入境日期" /> <br />
                                    <CheckBox name={`periods[${index}].countArrivalDate`} label="離境日期計算在內" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <Button onClick={() => remove(index)} variant="contained" color="error">-</Button>
                                </Grid>

                            </Grid>
                        </Fragment>
                    ))}
                </CardContent>
            </Card>
        );
    };

    const ProfileSelector: React.FC = () => {
        return (
            <Card sx={{ mt: 1 }}>
                <CardContent>

                </CardContent>
            </Card>
        );
    };

    const ResultWidget: React.FC<{ title: string; count: number; required: number; from: Moment; to: Moment }>
        = ({
               title,
               count,
               required,
               from,
               to,
           }) => {
        return (
            <Grid xs={12} md={4}>
                <Card sx={{ backgroundColor: "#485ec4" }}>
                    <CardHeader
                        disableTypography
                        title={
                            <Fragment>
                                <Box sx={{ mr: 2, fontWeight: "bold" }} component="span">{title}</Box>
                            </Fragment>
                        }
                        action={
                            <Chip
                                color={count < required ? "success" : "error"}
                                label={count < required ? "PASS" : "FAILED"}
                                size="small"
                            />
                        }
                    />
                    <CardContent
                        sx={{
                            color: "rgba(255, 255, 255, 0.5)",
                        }}
                    >
                        {count} 日
                        {count > 0 && (
                            <Fragment>
                                (由 {from.format('YYYY-MM-DD')}
                                至 {to.format('YYYY-MM-DD')})
                            </Fragment>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Fragment>
            <Title title="BNO 計算器" />
            <FormProvider {...formProps}>
                <form onSubmit={formProps.handleSubmit(onSubmitHandler)}>

                    <ProfileSelector />

                    <Card sx={{ my: 1 }}>
                        <CardHeader title="基本資料" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name="issueDate" label="簽發日期" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name="arrivalDate" label="抵達日期" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <CheckBox name="countArrivalDate" label="抵達日期計算在內"
                                              helperText="離境當天若不足24小時仍當在境內！" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name="ilrApplyDate" label="ILR申請日" />
                                </Grid>

                                <Grid xs={12} md={4}>
                                    <TestDatePicker name="bcApplyDate" label="BC 申請日 (預計)" />
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>

                    <LeavePeriods formProps={formProps} />

                    <Card sx={{ my: 1 }}>
                        <CardActions>
                            <Button type="submit" variant="contained" color="warning">Submit</Button>
                        </CardActions>
                    </Card>
                </form>
            </FormProvider>

            {
                formProps.formState.isSubmitSuccessful && (
                    <Card>
                        <CardHeader title="結果" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                {
                                    longestAbsence && (
                                        <ResultWidget
                                            title="ILR 連續十二個月境外時間"
                                            count={longestAbsence.count}
                                            required={180}
                                            from={longestAbsence.from}
                                            to={longestAbsence.to}
                                        />
                                    )
                                }
                                {
                                    bcAbsence450 && (
                                        <ResultWidget
                                            title="BC 申請前5年 450日上限"
                                            count={bcAbsence450.count}
                                            required={450}
                                            from={bcAbsence450.from}
                                            to={bcAbsence450.to}
                                        />
                                    )
                                }
                                {
                                    bcAbsence90 && (
                                        <ResultWidget
                                            title="BC 申請前1年 90日上限"
                                            count={bcAbsence90.count}
                                            required={90}
                                            from={bcAbsence90.from}
                                            to={bcAbsence90.to}
                                        />
                                    )
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                )
            }

        </Fragment>
    );
};

export default BnoCalculation;
