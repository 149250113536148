import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";

const ProjectRoleEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput source="role"
                                       label="Role"
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput source="roleName"
                                       label="Role Name"
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput source="roleDescription"
                                       label="Role"
                                       fullWidth
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
}

export default ProjectRoleEditForm;
