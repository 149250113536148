import { Fragment, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Html5QrcodeResult } from "html5-qrcode";
import { Html5QrcodeError } from "html5-qrcode/core";
import { useScannerContext } from "./context/useScannerContext";

const TestScanner = () => {

    const { openDialog, closeDialog } = useScannerContext();

    const [ result, setResult ] = useState<Html5QrcodeResult>();
    const [ error, setError ] = useState<Html5QrcodeError>();

    const onSuccessHandler = (result: Html5QrcodeResult) => {
        setError(undefined);
        setResult(result);
    };

    const onErrorHandler = (error: Html5QrcodeError) => {
        setResult(undefined);
        setError(error);
    };

    return (
        <Fragment>
            <Typography>Result: {result ? result.result.format?.formatName + " " + result.result.text : "No result"}</Typography>
            <Typography>Error: {error ? error.errorMessage : "No error"}</Typography>

            <Button onClick={() => openDialog({ onScanSuccessfulHandler: onSuccessHandler, onScanErrorHandler: onErrorHandler })}>Open Scanner</Button>
        </Fragment>
    );
};

export default TestScanner;