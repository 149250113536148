import React from "react";
import CategoryIcon from "@mui/icons-material/Bookmark";
import { Resource } from "react-admin";
import ProjectList from "./ProjectList";
import ProjectCreate from "./ProjectCreate";
import ProjectEdit from "./ProjectEdit";
import ProjectShow from "./ProjectShow";
import { Route } from "react-router-dom";
import ProjectRole from "./role";

const Project = (
    <Resource key="project"
              name="project"
              create={ProjectCreate}
              show={ProjectShow}
              list={ProjectList}
              icon={CategoryIcon}
    >
        <Route path=":projectId">
            <Route path="" element={<ProjectEdit />} />
            {ProjectRole}
        </Route>
    </Resource>
);

export default Project;
