const VoucherStatus = [
    {
        id: 'P',
        name: 'common.status.voucher.pending',
    },
    {
        id: 'A',
        name: 'common.status.voucher.active',
    },
    {
        id: 'PC',
        name: 'common.status.voucher.partlyConsumed',
    },
    {
        id: 'C',
        name: 'common.status.voucher.consumed',
    },
    {
        id: 'X',
        name: 'common.status.voucher.deleted',
    },
];

export default VoucherStatus;