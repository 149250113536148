import axios from "axios";
import config from "../../../config.json";
import { getAuth } from "../../../auth/AuthUtils";

const getTags = async (projectUuid: string, userUuid: string): Promise<any> => {
    return axios.get<Tag[]>(`${config.BASE_URL}/project/${projectUuid}/user/${userUuid}/document/tag/list`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuth()}`
        }
    }).then(result => {
        return {
            list: result.data,
            map: result.data.reduce((acc: any, cur) => {
                acc[cur.recordId] = cur;
                return acc;
            }, {}),
        }
    });
};

export default getTags;
