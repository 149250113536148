import {
    DataProvider,
    fetchUtils,
    CREATE,
    DELETE,
    GET_LIST,
    GET_ONE,
    UPDATE, GET_MANY, GET_MANY_REFERENCE,
} from "react-admin";
import { convertHTTPResponse, convertToGetListParams } from "../../hooks/DataProviderUtils";
import { getUserUuid } from "../../auth/AuthUtils";


/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export const sharedSuperAdminDataProvider = (baseUrl: string, targetIdField = "recordId", httpClient = fetchUtils.fetchJson): DataProvider => {

    const headers = new Headers({ Authorization: `Bearer ${localStorage.getItem('auth')}` });

    return {
        create: (resource, params) => {
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/create`, {
                headers: headers,
                method: "POST",
                body: JSON.stringify(params.data),
            })
                .then(response => convertHTTPResponse(response, CREATE, resource, params, targetIdField));
        },
        delete: (resource, params) => {
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/${params.id}/delete`, {
                headers: headers,
                method: "DELETE",
            })
                .then(response => convertHTTPResponse(response, DELETE, resource, params, targetIdField));
        },
        deleteMany: (resource, params) => Promise.reject("Delete many is not supported"),
        getList: (resource, params) => {
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/list${convertToGetListParams(params)}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_LIST, resource, params, targetIdField));
        },
        getMany: (resource, params) => {
            const { ids, meta } = params;

            const mixedParam = ids.reduce((p, id) => {
                return `${p}&recordId=${id}`;
            }, "");

            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}?${mixedParam}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_MANY, resource, params, targetIdField));
        },
        getManyReference: (resource, params) => {
            const { id, meta, target } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/list${convertToGetListParams(params)}&${target}=${id}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_MANY_REFERENCE, resource, params, targetIdField));
        },
        getOne: (resource, params) => {
            const { id, meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/${id}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_ONE, resource, params, targetIdField));
        },
        update: (resource, params) => {
            const { id, data, previousData, meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/${resource}/${id}/update`, {
                headers: headers,
                method: "PUT",
                body: JSON.stringify(data),
            })
                .then(response => convertHTTPResponse(response, UPDATE, resource, params, targetIdField));
        },
        updateMany: (resource, params) => Promise.reject("Not supported"),
    } as DataProvider;
};
