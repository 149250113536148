import { useQuery } from "@tanstack/react-query";
import config from "./../../config.json";
import axios, { AxiosResponse } from "axios";
import { getUserUuid } from "../../auth/AuthUtils";

export const fetchProjects = async (): Promise<any[]> => {
    return axios.get(`${config.BASE_URL}/system/user/${getUserUuid()}/project`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
    }).then(res => {
        return res.data;
    });
};

export const userLogin = async (username: string, password: string): Promise<AxiosResponse<UserAuthenticateResponse>> => {
    return axios.post<UserAuthenticateResponse>(`${config.BASE_URL}/identity/authenticate`, {
        username,
        password,
    }, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
    });
};

export const userAuthorize = async (): Promise<any> => {
    return axios.post(`${config.BASE_URL}/identity/authorise`, {
        "token": `${localStorage.getItem("auth")}`
    }, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then((result) => {
            return result.data;
        });
}

export const useProjects = (): { data: any[] | undefined, isLoading: boolean, refetch: Function } => {
    const { data, isLoading, refetch } = useQuery({
        queryKey: [ 'projects' ],
        queryFn: () => fetchProjects(),
            refetchOnWindowFocus: false,
    });
    return { data, isLoading, refetch };
}

export const updatePassword = async (form: {
    previousPassword: string;
    newPassword: string;
    newConfirmPassword: string;
}): Promise<void> => {
    return axios.post(`${config.BASE_URL}/system/user/${getUserUuid()}/password/update`, form, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
    }).then(res => {
        return res.data;
    });
}