import { useParams } from "react-router-dom";
import { useNotify, useRedirect, Edit } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import React from "react";
import VoucherRemarkEditForm from "./VoucherRemarkEditForm";
import { trackEvent } from "../../../support/googleAnalytics";

const VoucherRemarkEdit: React.FC<{}> = () => {

    const { voucherId, remarkId } = useParams();
    const notify = useNotify();
    const redirect = useRedirect();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Remark',
            path: `/voucher/${voucherId}/remark/${remarkId}/show`,
        },
        {
            label: 'Edit',
            isCurrent: true,
        }
    ];

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Voucher Remark Management"
              resource="voucherRemark"
              id={remarkId}
              queryOptions={{
                  onError: (error: any) => {
                      notify(error.message);
                      redirect('show', 'voucher', voucherId)
                  },
              }}
              mutationOptions={{
                  onSuccess: (data: any, variables: any, context: any) => {
                      trackEvent("voucher", "edit-remark", `voucher ID: ${data.voucherId}`);
                      trackEvent("voucher-remark", "edit", `voucher remark ID: ${data.recordId}, voucher ID: ${data.voucherId}`);
                      redirect('show', 'voucher', voucherId);
                  },
              }}
              redirect={`/voucher/${voucherId}/show`}
        >
            <div>
                <CustomBreadcrumbs title="Edit" items={breadcrumbItems} />
                <VoucherRemarkEditForm type="edit" />
            </div>
        </Edit>
    );

}

export default VoucherRemarkEdit;
