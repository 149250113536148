import React from "react";
import { Alert, AlertTitle, Box, Button, IconButton, Snackbar, styled, Typography } from "@mui/material";
import { CustomContentProps, useSnackbar } from 'notistack'
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { colors } from "../theme/ulTheme";
import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { id, variant, hideIconVariant, title, message, action } = props;

    const StyleAlert = styled(Alert) ({
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        "& .alert-title": {
            fontWeight: 600,
        },
        "& .MuiAlert-icon, .MuiAlert-message": {
            color: colors.gray["800"],
        },
        "&.alert-info": {
            backgroundColor: colors.blue["100"],
        },
        "&.alert-error": {
            backgroundColor: colors.red["100"],
        },
        "&.alert-success": {
            backgroundColor: colors.green["100"],
        }
    });

    const getIcon = (): React.ReactNode | undefined => {
        if (hideIconVariant || variant === "default") return undefined;
        switch (variant) {
            case "error":
                return <ErrorOutlineOutlinedIcon />;
            case "success":
                return <CheckCircleOutlineOutlinedIcon />;
            case "warning":
                return <WarningAmberOutlinedIcon />;
            case "info":
            default:
                return <InfoOutlinedIcon />
        }
    };

    return (
        <StyleAlert
            className={`alert-${variant}`}
            ref={ref}
            icon={getIcon()}
            onClose={() => {}}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        closeSnackbar(id);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            <AlertTitle sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "24px", }}>
                {title}
            </AlertTitle>
            <Typography className={`alert-message`}>
                {message}
            </Typography>
        </StyleAlert>
    );
});

export default CustomSnackbar;
