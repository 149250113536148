import React from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Edit } from "react-admin";
import Box from "@mui/material/Box";
import StockLocationEditForm from "./StockLocationEditForm";

const StockLocationEdit = () => {
    const { id } = useParams();
    const stockLocationEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Stock Location',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'List',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: false,
            resource: 'stock/location',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Stock Location Management"
        >
            <Box>
                <CustomBreadcrumbs title="Stock Location Management" items={stockLocationEditBreadcrumbItems} />
                <StockLocationEditForm type="edit" />
            </Box>
        </Edit>
    )
}

export default StockLocationEdit;
