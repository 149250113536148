import React from "react";
import { Create } from "react-admin";
import { Box } from "@mui/material";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import UserEditForm from "./UserEditForm";

const UserCreate: React.FC<{}> = () => {

    return (
        <Create actions={false}
                component="div"
                title="User Management"
                resource="user"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="User Management" items={[]} />
                <UserEditForm type="create" />
            </Box>
        </Create>

    )
}

export default UserCreate;
