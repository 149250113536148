import {
    CREATE,
    DataProvider,
    DELETE,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from "react-admin";
import { convertHTTPResponse, convertToGetListParams } from "../../hooks/DataProviderUtils";
import { getUserUuid } from "../../auth/AuthUtils";

export const adminProjectRoleDataProvider = (baseUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => {

    const headers = new Headers({ Authorization: `Bearer ${localStorage.getItem('auth')}` });

    return {
        create: (resource, params) => {
            const { meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/create`, {
                headers: headers,
                method: "POST",
                body: JSON.stringify(params.data),
            })
                .then(response => convertHTTPResponse(response, CREATE, resource, params));
        },
        delete: (resource, params) => {
            console.log(resource, params);
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/eece2a7c-f0a5-463d-bcea-13d50e5eb997/role/${params.id}/delete`, {
                headers: headers,
                method: "DELETE",
            })
                .then(response => convertHTTPResponse(response, DELETE, resource, params));
        },
        deleteMany: (resource, params) => Promise.reject("Delete many is not supported"),
        getList: (resource, params) => {
            const { meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/${meta.projectUuid}/role/list${convertToGetListParams(params)}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_LIST, resource, params));
        },
        getMany: (resource, params) => {
            const { ids, meta } = params;
            const mixedParam = ids.reduce((p, id) => {
                return `${p}&recordId=${id}`;
            }, "");
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/eece2a7c-f0a5-463d-bcea-13d50e5eb997/role?${mixedParam}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_MANY, resource, params));
        },
        getManyReference: (resource, params) => {
            const { id, meta, target } = params;
            console.log(params);
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/${id}/role/list${convertToGetListParams(params)}&${target}=${id}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_MANY_REFERENCE, resource, params));
        },
        getOne: (resource, params) => {
            const { id, meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/${id}`, {
                headers: headers,
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_ONE, resource, params));
        },
        update: (resource, params) => {
            const { id, data, previousData, meta } = params;
            return httpClient(`${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/${params.id}/update`, {
                headers: headers,
                method: "PUT",
                body: JSON.stringify(data),
            })
                .then(response => convertHTTPResponse(response, UPDATE, resource, params));
        },
        updateMany: (resource, params) => Promise.reject("Not supported"),
    };
}