import React from "react";
import { useParams } from "react-router-dom";
import { Show, useRedirect, useShowContext } from "react-admin";
import Loading from "../../../component/common/Loading";
import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import CustomEditButtonV2 from "../../../component/CustomEditButtonV2";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";

const VoucherRemarkShow: React.FC = () => {

    const { voucherId, remarkId } = useParams();
    const redirect = useRedirect();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Remark',
            isCurrent: true,
        }
    ];

    const VoucherRemarkShowContent: React.FC<{}> = () => {
        const {
            isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
            isLoading, // boolean that is true until the record is available for the first time
            record, // record fetched via dataProvider.getOne() based on the id from the location
            refetch, // callback to refetch the record via dataProvider.getOne()
        } = useShowContext();

        if (isLoading || isFetching) {
            return <Loading />;
        }

        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 IconButtonProps={{ size: "small", disabled: record.type === "S" }}
                                    customPath={`/voucher/${voucherId}/remark/${remarkId}/edit`} />
                <CustomDeleteIconButtonV2 IconButtonProps={{ size: "small", disabled: record.type === "S" }}
                                          redirectTo='reload' onSuccessFunction={() => refetch()} />
            </Stack>
        )

        return (
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={`Voucher Remark #${record.recordId}`} action={<CardHeaderAction />} />
                    <CardContent sx={{ width: '100%' }}>
                        <Typography>Record ID: {record.recordId}</Typography>
                        <Typography>Remark: {record.remark}</Typography>

                        <Divider />

                        <Typography>Created By: {record.createdBy}</Typography>
                        <Typography>Created Date: {moment(record.createdDate).toISOString()}</Typography>
                        <Typography>Updated By: {record.updatedBy}</Typography>
                        <Typography>Updated Date: {record.updatedDate}</Typography>

                    </CardContent>
                </Card>
            </Box>
        )
    }

    return (
        <Show actions={false}
              component="div"
              title="Voucher Consumption Management"
              resource="voucherRemark"
              id={remarkId}
              queryOptions={{
                  onError: () => {
                      redirect('show', 'voucher', voucherId)
                  }
              }}
        >
            <CustomBreadcrumbs title="Voucher Remark" items={breadcrumbItems} />
            <VoucherRemarkShowContent />
        </Show>
    )

}

export default VoucherRemarkShow;
