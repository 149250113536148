import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { SimpleForm, TextInput } from "react-admin";
import { useParams } from "react-router-dom";

const VoucherRemarkEditForm: React.FC<{
    type: 'create' | 'edit';
}> = ({ type }) => {

    const { voucherId } = useParams();

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Box sx={{ display: "none" }}>
                            <TextInput name="voucherId" source="voucherId" defaultValue={voucherId} readOnly />
                        </Box>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="remark"
                                       source="remark"
                                       label="Remark"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
};

export default VoucherRemarkEditForm;
