import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { AutocompleteInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { COMMON_STATUS } from "../../../constant/CommonStatus";

const ProductGroupEditForm: React.FC<{
    type: "create" | "edit";
}> = ({ type }) => {

    const inputLabelProps = {
        shrink: true,
    };

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           InputLabelProps={inputLabelProps}
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        }


                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       fullWidth
                                       isRequired
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="description"
                                       source="description"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         source="status"
                                         choices={COMMON_STATUS}
                                         fullWidth
                                         InputLabelProps={inputLabelProps}
                                         variant="outlined"
                                         defaultValue={"A"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput
                                source="parentId"
                                reference="productGroup"
                                fullWidth
                            >
                                <AutocompleteInput
                                    name="parentId"
                                    label="Parent Product Group"
                                    variant="outlined"
                                    optionText={value => value.name}
                                    filterSelectedOptions={false}
                                    optionValue="recordId"
                                    emptyText={"N/A"}
                                />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    )
}

export default ProductGroupEditForm;
