import React, { Fragment } from "react";
import { Breadcrumbs, Link, Paper } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate, Link as RouterLink } from "react-router-dom";

export type DocumentBreadcrumbsProps = {
    folderDetails?: FolderDetails;
    file?: FileV2
};

const DocumentBreadcrumbs: React.FC<DocumentBreadcrumbsProps> = ({ folderDetails, file }) => {

    return (
        <Fragment>
            <Paper sx={{ p:2, my: 1 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        component={RouterLink}
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        to={"/document/explorer"}
                    >
                        <FolderIcon fontSize="small" sx={{ mr: 1 }} />&lt;Root&gt;
                    </Link>
                    { folderDetails && (
                        folderDetails.parents.length > 0 && (
                                [...folderDetails.parents].reverse().map(folder => (
                                    <Link
                                        component={RouterLink}
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        to={`/document/explorer/${folder.recordId}`}
                                    >
                                        <FolderIcon fontSize="small" sx={{ mr: 1 }} />{folder.name}
                                    </Link>
                                ))
                            )
                    )}
                    { file && (
                        <Link
                            component={RouterLink}
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={`/document/preview/${file.recordId}`}
                        >
                            <FolderIcon fontSize="small" sx={{ mr: 1 }} />{file.filename}
                        </Link>
                    )}
                </Breadcrumbs>
            </Paper>
        </Fragment>
    );
};

export default DocumentBreadcrumbs;
