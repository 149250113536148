import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "@tanstack/react-query";
import getTags from "./getTags";

const useGetTags = () => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getTags', projectUuid ],
        queryFn: () => getTags(projectUuid, userUuid),
        enabled: projectUuid.length > 0 && userUuid.length > 0,
    });
};

export default useGetTags;
