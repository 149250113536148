import React, { Fragment, useMemo } from "react";
import { useOctopusTariffV4, useStandingChargeV4 } from "../data/useOctopusData";
import moment from "moment/moment";
import { OctopusConvertedConsumption, OctopusPeriodUsageMap } from "../types/types";
import Grid from "@mui/material/Unstable_Grid2";
import { useStore } from "react-admin";

type OctopusCompareToTrackerProps = {
    periodStart: Date,
    periodEnd: Date,
    consumptions: OctopusConvertedConsumption[],
};

const OctopusCompareToTracker: React.FC<OctopusCompareToTrackerProps>
    = ({
           periodStart, periodEnd, consumptions,
       }) => {

    const [ trackerTariffCode, _ ] = useStore("octopus.consumption.compareToTrack.tariff.code", "E-1R-SILVER-23-12-06-E");

    const dummyTrackerPeriodAgreements = useMemo(() => {
        return [
            {
                recordId: 0,
                type: "dummy",
                mpan: "dummy",
                meterSerialNumber: "dummy",
                tariffCode: trackerTariffCode,
                validFrom: periodStart.toISOString(),
                validTo: periodEnd.toISOString()
            }
        ];
    }, [trackerTariffCode, periodStart, periodEnd]);

    const {
        isError: isStandingChargeError,
        isLoading: isStandingChargeLoading,
        data: standingCharges
    } = useStandingChargeV4(dummyTrackerPeriodAgreements);
    const {
        isError: isTariffError,
        isLoading: isTariffLoading,
        data: tariffs
    } = useOctopusTariffV4(dummyTrackerPeriodAgreements);


    const halfHourMapping = useMemo(() => {
        if (consumptions && tariffs) {
            const tariffDateTimeMappings: OctopusPeriodUsageMap =
                tariffs.sort((a, b) => moment(a.validFrom).isBefore(b.validFrom) ? -1 : 1)
                    .reduce((prev: OctopusPeriodUsageMap, current) => {
                        const key = moment(current.validFrom).format("YYYY-MM-DD'T'HH:mm");
                        prev[key] = {
                            from: current.validFrom,
                            to: moment(current.validTo).subtract(1, "millisecond").toISOString(false),
                            consumption: 0.0,
                            cost: 0.0,
                            costPerKwh: current.valueIncVat,
                        }
                        return prev;
                    }, {});

            consumptions.forEach((consumption, index) => {
                const key = moment(consumption.intervalStart).format("YYYY-MM-DD'T'HH:mm");

                if (tariffDateTimeMappings[key]) {
                    tariffDateTimeMappings[key].cost = consumption.consumption * tariffDateTimeMappings[key].costPerKwh;
                    tariffDateTimeMappings[key].consumption = consumption.consumption;
                }
            });

            return tariffDateTimeMappings;
        }
        return {};
    }, [ consumptions, tariffs ]);

    const { totalDays, totalCost, totalConsumptions } = useMemo(() => {
        const totalDays = moment(periodEnd).diff(moment(periodStart), "days", false) + 1;
        if (Object.keys(halfHourMapping).length === 0) {
            return {
                totalDays,
                totalCost: 0,
                totalConsumptions: 0,
            };
        }
        return {
            totalDays,
            ...Object.keys(halfHourMapping).reduce((prev, current) => {
                prev.totalCost += halfHourMapping[current].costPerKwh * halfHourMapping[current].consumption;
                prev.totalConsumptions += halfHourMapping[current].consumption;
                return prev;
            }, {
                totalCost: 0,
                totalConsumptions: 0,
            }),
        };

    }, [periodStart, periodEnd, halfHourMapping]);

    const { totalStandingCharge } = useMemo(() => {
        let totalStandingCharge = 0;
        if (!isStandingChargeLoading && standingCharges && standingCharges.length > 0) {
            standingCharges.forEach((standingCharge) => {
                const daysInDiff = moment(standingCharge.validTo).diff(moment(standingCharge.validFrom), "days", false) + 1;
                totalStandingCharge += daysInDiff * standingCharge.valueIncVat;
            });
        }
        return {
            totalStandingCharge
        }
    }, [isStandingChargeLoading, standingCharges]);


    if (isStandingChargeError || isTariffError)
        return "Error";

    if (isStandingChargeLoading || isTariffLoading)
        return "Loading...";

    if (!standingCharges || !tariffs)
        return "no data.";

    return (
        <Fragment>
            <Grid container>
                <Grid xs={12} md={4}>Total days: {totalDays} days</Grid>
                <Grid xs={12} md={4}>Total Consumption: {totalConsumptions.toFixed(2)} kWh</Grid>
                <Grid mdOffset={4} />
                <Grid xs={12} md={4}>Consumption cost: &pound;{(totalCost / 100).toFixed(2)}</Grid>
                <Grid xs={12} md={4}>Standing charge cost: &pound;{(totalStandingCharge / 100).toFixed(2)}</Grid>
                <Grid xs={12} md={4}>Total cost: &pound;{((totalCost + totalStandingCharge) / 100).toFixed(2)}</Grid>
                <Grid xs={12} md={4}>Cost per kWh: &pound;{(totalConsumptions > 0 ? (totalCost) / 100 / totalConsumptions : 0).toFixed(2)} / kWh</Grid>
                <Grid xs={12} md={4}>Cost per kWh (with standing charge): &pound;{(totalConsumptions > 0 ? (totalCost + totalStandingCharge) / 100 / totalConsumptions : 0).toFixed(2)} / kWh</Grid>
                <Grid xs={12} md={4}>Daily cost: &pound;{((totalCost + totalStandingCharge) / 100 / totalDays).toFixed(2)}</Grid>
            </Grid>
        </Fragment>
    );
};

export default OctopusCompareToTracker;
