import React, { useEffect, useState } from "react";
import { Chip, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export interface ProgressiveImgProps {
    alt: string;
    src: string;
    width?: string | number;
    height?: string | number;
}

const ProgressiveImg: React.FC<ProgressiveImgProps> = ({ alt, src, width, height }) => {

    const [imgSrc, setImgSrc] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const img: HTMLImageElement = new Image();

        setTimeout(() => {
            img.src = src;
        }, 150);

        img.onload = () => {
            setImgSrc(src);
            setIsLoading(false);
        };

        img.onerror = () => {
            setIsLoading(false);
            setIsError(true);
        }

    }, [src]);

    if (isLoading) {
        return (
            <CircularProgress color="error" data-testid="progressive-image-loading" />
        );
    }

    if (isError) {
        return (
            <Chip
                avatar={<CloseIcon />}
                label={`Failed to load image - ${alt}`} color="error"
                data-testid="progressive-image-error"
            />
        );
    }

    return (
        <img
            src={imgSrc}
            alt={alt}
            width={width}
            height={height}
            data-testid="progressive-image"
        />
    );

};

export default ProgressiveImg;
