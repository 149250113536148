import { useMutation, UseMutationOptions, UseMutationResult, useQuery } from "@tanstack/react-query";
import { getProjectUuid, getUserUuid } from "../../../../auth/AuthUtils";
import { OctopusDataProvider } from "./octopusDataProvider";
import { useDataProvider } from "react-admin";
import { OctopusAgreement } from "../types/types";


const useOctopusTariffV4 = (agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');
    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch,
    } = useQuery({
        queryKey: [ 'octopusTariff', 'v4', agreements, project ],
        queryFn: () => {
            return dataProvider.fetchOctopusTariff("octopus", {
                dataProvider,
                agreements: [ ...agreements ],
            });
        },
        refetchOnWindowFocus: false,
        enabled: agreements.length > 0,
        retry: false,
    });
    return { isError, error, data, isLoading, isFetching, refetch };
};

const useOctopusGasTariffV4 = (agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');
    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch,
    } = useQuery({
        queryKey: [ 'octopusTariff', 'gas', 'v4', agreements, project ],
        queryFn: () => {
            return dataProvider.fetchOctopusGasTariff('octopus', {
                dataProvider,
                agreements: [ ...agreements ],
            });
        },
        refetchOnWindowFocus: false,
        enabled: agreements.length > 0,
        retry: false,
    });
    return { isError, error, data, isLoading, isFetching, refetch };
};

const useOctopusAccount = () => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";
    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch,
    } = useQuery({
        queryKey: [ 'octopusAccount', projectUuid, userUuid ],
        queryFn: () => dataProvider.fetchOctopusAccount("octopus", { projectUuid, userUuid }),
        enabled: ! (projectUuid === "" && userUuid === ""),
        retry: false,
    });

    return { isError, error, data, isLoading, isFetching, refetch };
};

interface UpdateOctopusAccountParams {
    apiKey: string;
    accountId: string;
}

type OctopusUpdateAccountResult<ResultRecordType, MutationError, RecordType> = [
    (apiKey: string, accountId: string) => void,
    UseMutationResult<ResultRecordType, MutationError, RecordType>
];

interface OctopusUpdateAccountOptions extends UseMutationOptions<boolean, unknown, UpdateOctopusAccountParams> {
}

const useUpdateOctopusAccount = (options: OctopusUpdateAccountOptions = {}): OctopusUpdateAccountResult<boolean, unknown, UpdateOctopusAccountParams> => {
    const dataProvider = useDataProvider<OctopusDataProvider>();

    const mutation = useMutation<boolean, unknown, UpdateOctopusAccountParams>({
        mutationFn: ({ apiKey, accountId }) => {
            return dataProvider.updateOctopusAccount("octopus", { apiKey, accountId });
        },
        onSuccess: (data, variables, context) => {
            if (!! options && !! options.onSuccess) {
                options.onSuccess(data, variables, context);
            }
        },
        onError: (data, variables, context) => {
            if (!! options && !! options.onError) {
                options.onError(data, variables, context);
            }
        },
        onSettled: (data, error, variables, context) => {
            if (!! options && !! options.onSettled) {
                options.onSettled(data, error, variables, context);
            }
        },
    })

    const update = (apiKey: string, accountId: string) => {
        return mutation.mutate({ apiKey, accountId });
    }

    return [ update, mutation ];
};

const useOctopusDataV4 = (token: string, agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');

    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch
    } = useQuery({
        queryKey: [ 'octopusTariff', 'v4', project, agreements ],
        queryFn: () => dataProvider.fetchOctopusConsumption("octopus", {
            token,
            dataProvider,
            agreements: [ ...agreements ],
        }),
        enabled: token !== "" && agreements.length > 0,
        retry: false,
    });

    return { isError, error, data, isLoading, isFetching, refetch };
};


const useOctopusGasData = (token: string, agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');

    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch
    } = useQuery({
        queryKey: [ 'octopusData', 'gas', project, agreements ],
        queryFn: () => dataProvider.fetchOctopusGasConsumption("octopus", {
            token,
            dataProvider,
            agreements: [ ...agreements ],
        }),
        enabled: token !== "" && agreements.length > 0,
        retry: false,
    });

    return { isError, error, data, isLoading, isFetching, refetch };
};

const useStandingChargeV4 = (agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');

    const {
        isError,
        error,
        data,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [ 'octopusStandingCharges', 'v4', project, agreements ],
        queryFn: () =>
            dataProvider.fetchOctopusStandingCharge("octopus", {
                dataProvider,
                agreements: [ ...agreements ],
            }),
        retry: false,
        enabled: agreements.length > 0,
    });
    return { isError, error, data, isLoading, refetch };
};

const useGasStandingChargeV4 = (agreements: OctopusAgreement[]) => {
    const dataProvider = useDataProvider<OctopusDataProvider>();
    const project = localStorage.getItem('project');

    const {
        isError,
        error,
        data,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [ 'octopusStandingCharges', 'gas', 'v4', project, agreements ],
        queryFn: () =>
            dataProvider.fetchOctopusGasStandingCharge("octopus", {
                dataProvider,
                agreements: [ ...agreements ],
            }),
        retry: false,
        enabled: agreements.length > 0,
    });
    return { isError, error, data, isLoading, refetch };
};

export {
    useOctopusAccount,
    useOctopusTariffV4,
    useOctopusGasTariffV4,
    useOctopusDataV4,
    useOctopusGasData,
    useStandingChargeV4,
    useGasStandingChargeV4,
    useUpdateOctopusAccount,
};
