import React from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create, useRedirect } from "react-admin";
import EquipmentNoteEditForm from "../form/EquipmentNoteEditForm";

const EquipmentNoteCreate: React.FC = () => {

    const redirect = useRedirect();
    const { equipmentId } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Equipments",
            resource: "equipment",
            type: "list",
        },
        {
            label: "Record",
            resource: "equipment",
            type: "show",
            id: equipmentId,
        },
        {
            label: "Note",
            isCurrent: true,
        },
    ];

    return (
        <Create
            actions={false}
            component="div"
            title="Equipment Note"
            resource="equipment/note"
            mutationOptions={{
                onSuccess: (data: EquipmentNote, _: any, __: any) => {
                    redirect(`/equipment/${equipmentId}/show/notes/${data.recordId}`)
                },
            }}
        >
            <CustomBreadcrumbs title="Create Equipment Note" items={breadcrumbItems} />
            <EquipmentNoteEditForm type="create" />
        </Create>
    );
};

export default EquipmentNoteCreate;
