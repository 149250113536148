import ProductBarcodeCreate from "./ProductBarcodeCreate";
import { Route } from "react-router-dom";
import ProductBarcodeEdit from "./ProductBarcodeEdit";

const ProductBarcode = (
    <Route path="barcode">
        <Route path="create" element={<ProductBarcodeCreate />} />
        <Route path=":id/edit" element={<ProductBarcodeEdit />} />
    </Route>
);

export default ProductBarcode;
