import React from "react";
import { useRecordContext } from "ra-core";
import { useNavigate } from "react-router";
import { useCreatePath, useResourceContext } from "react-admin";
import { Tooltip } from "@mui/material";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import CustomIconButton from "./common/CustomIconButton";

const CustomShowButton: React.FC<{ customPath?: string, targetIdField?: string }> = ({ customPath, targetIdField = "id" }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const navigate = useNavigate();
    const createPath = useCreatePath();

    if (! record) return null;

    const toPath: string = customPath ? customPath : createPath({ type: 'show', resource: resource, id: record[targetIdField] })
    return (
        <Tooltip title="Show" disableInteractive>
            <div>
                <CustomIconButton
                    size="small"
                    color="softPrimary"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(toPath);
                    }}
                >
                    <VisibilitySharpIcon sx={{ fontSize: 16 }} />
                </CustomIconButton>
            </div>
        </Tooltip>
    );
}

export default CustomShowButton;