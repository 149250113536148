import React, { Fragment } from "react";
import { SelectField, Show, useShowContext } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material";
import Loading from "../../component/common/Loading";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import { COMMON_STATUS } from "../../constant/CommonStatus";

const BrandShow = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Brands',
            resource: 'brand',
            type: 'list',
        },
        {
            label: 'Brand',
            isCurrent: true,
        },
    ];

    const BrandShowLayout = () => {

        const { record, isLoading, refetch } = useShowContext();


        if (isLoading)
            return <Loading />;

        if (! record)
            return <>Record Not Found</>;

        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButton />
                <CustomDeleteIconButton IconButtonProps={{ disabled: record.status === 'X' }} redirectTo='reload'
                                        onSuccessFunction={() => refetch()} />
            </Stack>
        );

        return (
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={record.name} action={<CardHeaderAction />} />
                    <Divider />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4}>
                                Record ID: {record.recordId}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Name: {record.name}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Description: {record.description}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Status: <SelectField source="status" choices={COMMON_STATUS} />
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container>
                            <Grid item xs={12}>
                                <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                            </Grid>
                            <Grid item xs={12}>
                                <>Last Updated By {record.updatedBy} @ {new Date(record.createdDate).toLocaleString()}</>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>

            </Box>
        );

    };

    return (
        <Show actions={false} component="div">
            <CustomBreadcrumbs title="View" items={breadcrumbItems} />
            <BrandShowLayout />
        </Show>
    )
}

export default BrandShow;
