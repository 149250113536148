import React, { Fragment } from "react";
import { Form } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { Card, CardActions, CardContent } from "@mui/material";

const DocumentPreviewNotesTab: React.FC = () => {

    return (
        <>
            <Form>
                <RichTextInput name="content" source="content" label={false} />
            </Form>


            {Array.from(Array(12)).map((_, index) => (
                <Card key={index} elevation={3} sx={{ my: 1}}>
                    <CardContent sx={{ p: "4px" }}>
                        123123123
                    </CardContent>
                    <CardActions sx={{ p: "4px" }}>
                        123
                    </CardActions>
                </Card>
            ))}
        </>
    );
};

export default DocumentPreviewNotesTab;
