export const BRANCH_STATUS = [
    {
        id: 'A',
        name: 'Active',
    },
    {
        id: 'D',
        name: 'Disabled',
    },
    {
        id: 'X',
        name: 'Deleted',
    },
    {
        id: 'U',
        name: 'Unknown',
    },
];

export const BRANCH_TYPE = [
    {
        id: 'O',
        name: 'Online',
    },
    {
        id: 'P',
        name: 'Physical',
    },
    {
        id: 'U',
        name: 'Unknown',
    },
]