import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Edit } from "react-admin";
import EquipmentEditForm from "./form/EquipmentEditForm";
import { useParams } from "react-router-dom";

const EquipmentEdit: React.FC = () => {

    const { id } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Equipments',
            resource: 'equipment',
            type: 'list',
        },
        {
            label: 'Equipment',
            resource: 'equipment',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
                component="div"
                title="Equipment Management"
                resource="equipment"
                redirect="show"
        >
            <CustomBreadcrumbs title="Edit Equipment" items={breadcrumbItems} />
            <EquipmentEditForm type="edit" />
        </Edit>
    );
};

export default EquipmentEdit;
