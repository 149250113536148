import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import VoucherConsumptionEditForm from "./VoucherConsumptionEditForm";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Edit, useEditContext, useGetOne, useNotify, useRedirect } from "react-admin";
import { trackEvent } from "../../../support/googleAnalytics";
import { useQueryClient } from "@tanstack/react-query";

const VoucherConsumptionEdit: React.FC<{}> = () => {

    const queryClient = useQueryClient();
    const { voucherId, consumptionId } = useParams();
    const notify = useNotify();
    const redirect = useRedirect();
    const { data: voucher, refetch } = useGetOne<Voucher>("voucher", { id: +(voucherId ?? 0) });

    if (! (voucher))
        return null;

    const voucherConsumptionEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Consumption',
            path: `/voucher/${voucherId}/consumption/${consumptionId}/show`,
        },
        {
            label: 'Edit',
            isCurrent: true,
        }
    ];

    const VoucherEditContent = () => {
        const { record } = useEditContext();

        return (
            <Fragment>
                <VoucherConsumptionEditForm type="edit" record={record} />
            </Fragment>
        )
    }

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Voucher Consumption Management"
              resource="voucherConsumption"
              id={consumptionId}
              queryOptions={{
                  onError: (error: any) => {
                      notify(error.message);
                      redirect('show', 'voucher', voucherId)
                  },
              }}
              mutationOptions={{
                  onSuccess: (data: any) => {
                      trackEvent("voucher", "edit-consumption", `voucher ID: ${data.voucherId}`);
                      trackEvent("voucher-consumption", "edit", `voucher consumption ID: ${data.recordId}, voucher ID: ${data.voucherId}`);
                      queryClient.invalidateQueries({
                          predicate: (query) => {
                              return query.queryKey.length === 3 && ((
                                  query.queryKey[0] === "voucherRemark" &&
                                  query.queryKey[1] === "getManyReference" &&
                                  (query.queryKey[2] as any)?.target === "voucherId" &&
                                  (query.queryKey[2] as any)?.id === data.voucherId ) ||
                                  ((query.queryKey[0] === "voucher") &&
                                  query.queryKey[1] === "getOne" &&
                                  (query.queryKey[2] as any)?.id === `${data.voucherId}`) ||
                                  ((query.queryKey[0] === "voucher") &&
                                      query.queryKey[1] === "getList" &&
                                      (query.queryKey[2] as any)?.id === `${data.voucherId}`))
                          }
                      }).then(() => {
                          redirect(`/voucher/${voucherId}/show`);
                      });
                  },
              }}
              redirect={`/voucher/${voucherId}/show`}
        >
            <div>
                <CustomBreadcrumbs title="Edit" items={voucherConsumptionEditBreadcrumbItems} />
                <VoucherEditContent />
            </div>
        </Edit>
    );
}

export default VoucherConsumptionEdit;
