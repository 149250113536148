import CategoryIcon from "@mui/icons-material/Bookmark";
import ShopList from "./ShopList";
import ShopShow from "./ShopShow";
import ShopEdit from "./ShopEdit";
import ShopCreate from "./ShopCreate";

const MODULE = {
    list: ShopList,
    show: ShopShow,
    create: ShopCreate,
    edit: ShopEdit,
    icon: CategoryIcon,
};

export default MODULE;
