import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create, SelectInput, SimpleForm, TextInput } from "react-admin";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import { SHOP_STATUS } from "../../shop/ShopConstant";

const StockLocationCreate = () => {

    const stockLocationCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Stock Location',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'List',
            isCurrent: false,
            resource: 'stock/location',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];


    return (
        <Create actions={false} component="div"
                title="Stock Location Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Stock Location Management" items={stockLocationCreateBreadcrumbItems} />
                <Card>
                    <CardHeader title="Create Stock Location" />
                    <Divider />
                    <CardContent>
                        <SimpleForm>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput source="name" fullWidth required variant="standard" />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput source="description" fullWidth required variant="standard" />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <SelectInput source="status" fullWidth required variant="standard"
                                                 choices={SHOP_STATUS} />
                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </Box>
        </Create>
    );
};

export default StockLocationCreate;
