import React, { ReactElement } from "react";
import { useRecordContext } from "ra-core";
import { useNavigate } from "react-router";
import { useCreatePath, useResourceContext } from "react-admin";
import { IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CustomIconButton from "./common/CustomIconButton";

const stopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation();

const defaultIcon = <EditIcon sx={{ fontSize: 16 }} />;

const CustomEditButton: React.FC<{
    title?: string,
    icon?: ReactElement,
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>,
    IconButtonProps?: IconButtonProps,
    customPath?: string
}> =
    ({
         title = "Edit",
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         customPath
     }) => {
        const resource = useResourceContext();
        const record = useRecordContext();
        const navigate = useNavigate();
        const createPath = useCreatePath();

        const disabled = IconButtonProps ? IconButtonProps.disabled ? IconButtonProps.disabled : false : false;

        if (! record) return null;

        const toPath: string = customPath ? customPath : createPath({ type: 'edit', resource: resource, id: record.id })
        return (
            <Tooltip title={disabled ? "" : title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        disabled={disabled}
                        size="small"
                        color="softPrimary"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(toPath, {
                                state: {
                                    _scrollToTop: true,
                                }
                            });
                        }}
                        {...IconButtonProps}
                    >
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        );
    }

export default CustomEditButton;