import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { CreateButton, Datagrid, FunctionField, List, TextField } from "react-admin";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomShowButton from "../../../component/CustomShowButton";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";

const StockLocationList = () => {

    const stockLocationListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Stock Locations',
            isCurrent: true,
        },
    ];

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            empty={false}
            component="div"
            title="Stock Location Management"
        >
            <Box>
                <CustomBreadcrumbs title={"Stock Location Management"} items={stockLocationListBreadcrumbItems} />
                <Card>
                    <CardHeader title="Stock Locations" action={<CreateButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )}/>
                            <TextField source="recordId" />
                            <TextField source="name" />
                            <TextField source="description" />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );

}

export default StockLocationList;
