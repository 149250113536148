import { useQuery } from "@tanstack/react-query";
import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import getPreviewUrl from "./getPreviewUrl";

const useGetPreviewUrl = (enabled: boolean, filepath: string) => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getPreviewUrl', projectUuid, filepath ],
        queryFn:
            () => getPreviewUrl(projectUuid, userUuid, filepath),
        enabled: enabled && projectUuid.length > 0 && userUuid.length > 0 && !! filepath,
        staleTime: 5 * 60 * 1000,
    });
};

export default useGetPreviewUrl;
