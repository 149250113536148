import React, { Fragment } from "react";
import ReactApexChart, { Props } from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { Alert, Box, Card, CardContent, Divider, Paper, Theme, useMediaQuery } from "@mui/material";
import MathUtils from "../../../../helper/MathUtils";
import { useSidebarState } from "react-admin";
import moment from "moment-timezone";
import { OctopusConvertedTariff } from "../types/types";

const OctopusTariffChartV4: React.FC<{ values: OctopusConvertedTariff[] }> = ({ values }) => {

    moment.tz.setDefault("Europe/London");

    const [open] = useSidebarState();

    const labels: any[] = values.map((e) => moment(e.validFrom).format("HH:mm"));
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const sum = values.reduce((p: number, item) => {
        p = p + item.valueIncVat;
        return p;
    }, 0.0);

    const tariffs = values.reduce((p: number[], item) => {
        p.push(item.valueIncVat);
        return p;
    }, []);

    const smallest = Math.min(...tariffs);
    const biggest = Math.max(...tariffs);
    const average = sum / values.length;

    if (!values || values.length === 0) {
        return <Alert severity="warning" color="warning">Tariff chart rendering failed.</Alert> ;
    }

    const state: Props = {
        series: [ {
            name: 'Tariff',
            data: values.map((value) => {
                return (
                    {
                        x: value.validFrom,
                        y: value.valueIncVat,
                        meta: {
                            ...value
                        },
                    }
                );
            }),
        }, ],
        options: {
            chart: {
                type: 'area',
                zoom: {
                    enabled: false,
                },
                redrawOnParentResize: true,
                redrawOnWindowResize: true,
            },
            stroke: {
                curve: "smooth",
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val + "";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: [ "#304758" ]
                }
            },
            theme: {
                mode: "dark",
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: true,
                intersect: false,
                inverseOrder: false,
                custom: (options) => {
                    const { seriesIndex, dataPointIndex, w } = options;
                    const data = w.config.series[seriesIndex].data[dataPointIndex];
                    return renderToString(
                        <Paper>
                            Start Time: {moment(data.meta.validFrom).format("LLL")}<br />
                            End Time: {moment(data.meta.validTo).format("LLL")}<br />
                            Cost (VAT Included): {data.meta.valueIncVat.toFixed(3)}p
                            <Divider />
                            Average today: {average.toFixed(3)}p
                        </Paper>
                    );
                },
                fillSeriesColor: false,
                // theme: false,
                style: {
                    fontSize: "12px",
                    fontFamily: undefined
                },
                onDatasetHover: {
                    highlightDataSeries: false
                },
            },
            xaxis: {
                categories: labels,
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [ 0, 100 ],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                labels: {
                    formatter(value: any): string | string[] {
                        return moment(value).format("HH:mm");
                    }
                },
                tickAmount: 12,
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: true,
                    formatter: function (val: any) {
                        return val;
                    }
                },
            },
            title: {
                text: 'Tariff',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#fff'
                }
            },
            annotations: {
                yaxis: [
                    {
                        y: average,
                        borderColor: "#FEB019",
                        borderWidth: 4,
                        label: {
                            borderColor: "#FEB019",
                            text: "Average",
                            style: {
                                fontSize: "16px",
                                color: "#000",
                            }
                        }
                    },
                    {
                        y: smallest,
                        borderColor: "#00E396",
                        borderWidth: 4,
                        label: {
                            borderColor: "#00E396",
                            text: "Min",
                            style: {
                                fontSize: "16px",
                                color: "#000",
                            }
                        }
                    },
                    {
                        y: biggest,
                        borderColor: "#FF4560",
                        borderWidth: 4,
                        label: {
                            borderColor: "#FF4560",
                            text: "Max",
                            style: {
                                fontSize: "16px",
                                color: "#000",
                            }
                        }
                    },
                ],
            },
        },
    };

    return (
        <Fragment>
            <Box sx={{
                maxWidth: isDesktop ? `calc(100vw - ${open ? "300px" : "100px"})` : undefined,
            }}>
                <ReactApexChart options={state.options}
                                series={state.series}
                                type="area"
                                height={350}
                />
            </Box>
            <Card>
                <CardContent>
                    Min: {Math.min(...tariffs)}<br />
                    Max: {Math.max(...tariffs)}<br />
                    Average: {MathUtils.mean(tariffs)} <br />
                    Q25: {MathUtils.q25(tariffs)} <br />
                    Q50 / Median: {MathUtils.q50(tariffs)} <br />
                    Q75: {MathUtils.q75(tariffs)} <br />
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default OctopusTariffChartV4;
