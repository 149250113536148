import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create, SimpleForm, useRedirect, useTranslate } from "react-admin";
import { Box } from "@mui/material";
import VoucherEditForm from "./VoucherEditForm";
import { trackEvent } from "../../support/googleAnalytics";

const VoucherCreate  = () => {

    const translate = useTranslate();
    const redirect = useRedirect();

    const voucherCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Index',
            path: "/voucher",
        },
        {
            label: 'List',
            path: "/voucher/list",
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Voucher Management"
                mutationOptions={{
                    onSuccess: (data: any, _variables: any, _context: any) => {
                        trackEvent("voucher", "edit", `voucher ID: ${data.recordId}`);
                        redirect(`/voucher/${data.recordId}/show`);
                    },
                }}
        >
            <Box>
                <CustomBreadcrumbs title={translate("resources.voucher.page.create.title")} items={voucherCreateBreadcrumbItems} />
                <Box sx={{ width: '100%' }}>
                    <SimpleForm mode="onBlur" reValidateMode="onBlur">
                        <Box sx={{ width: '100%' }}>
                            <VoucherEditForm type="create" />
                        </Box>
                    </SimpleForm>
                </Box>
            </Box>
        </Create>
    )

}

export default VoucherCreate;
