import React, { Fragment } from "react";
import CustomIconButton from "../../../../component/common/CustomIconButton";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FolderCreateButton: React.FC = () => {

    const [ dialogOpen, setDialogOpen ] = React.useState(false);
    const dialogOpenHandler = () => {
        setDialogOpen(true);
    }

    const dialogCloseHandler = () => {
        setDialogOpen(false);
    }

    return (
        <Fragment>
            <CustomIconButton size="small" color="softPrimary">
                <AddOutlinedIcon onClick={dialogOpenHandler} />
            </CustomIconButton>

            <Dialog
                open={dialogOpen}
                onClose={dialogCloseHandler}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const email = formJson.email;
                        console.log(email);
                        dialogCloseHandler();
                    },
                }}

            >
                <DialogTitle>
                    Create Folder
                    <IconButton
                        aria-label="close"
                        onClick={dialogCloseHandler}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogCloseHandler}>Cancel</Button>
                    <Button type="submit">Subscribe</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default FolderCreateButton;
