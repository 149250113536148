import { Resource } from "react-admin";
import { Route } from "react-router-dom";
import DocumentExplorer from "./DocumentExplorer";
import DocumentPreview from "./DocumentPreview";

const Document = (
    <Resource
        key="document"
        name="document"
    >
        <Route path="explorer" element={<DocumentExplorer />} />
        <Route path="explorer/:folderId" element={<DocumentExplorer />} />
        <Route path="preview/:fileId" element={<DocumentPreview />} />
    </Resource>
);

export default Document;
