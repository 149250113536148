import { CREATE, DataProvider, DELETE, fetchUtils, GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE } from "react-admin";
import { convertHTTPResponse, convertToGetListParams } from "./DataProviderUtils";
import config from "../config.json";
import { getUserUuid } from "../auth/AuthUtils";

export const voucherSubResourceDataProvider = (subResourceName: string, httpClient = fetchUtils.fetchJson): DataProvider => {
    const baseUrl = config.BASE_URL;

    const project = localStorage.getItem('project');
    const userUuid = getUserUuid();
    const headers = new Headers({ Authorization: `Bearer ${localStorage.getItem('auth')}` });

    const getBaseUri = () => {
        switch (subResourceName) {
            case "remark":
            case "consumption":
                return `${baseUrl}/project/${project}/user/${userUuid}/voucher/${subResourceName}`;
            default:
                throw new Error("Unknown sub resource?!");
        }
    }

    return {
        create: async (resource, params) => {
            return httpClient(`${getBaseUri()}/create`, {
                headers: new Headers({ Authorization: `Bearer ${localStorage.getItem('auth')}` }),
                method: "POST",
                body: JSON.stringify(params.data),
            })
                .catch(({ status, statusText, headers, body }) => {
                    console.log(status);
                    if (status === 400) {
                        return Promise.reject({ message: body.errorReason });
                    }
                    return Promise.resolve({ status, headers, body });
                })
                .then(response => convertHTTPResponse(response, CREATE, resource, params));
        },
        delete: async (resource, params) => {
            const response = await httpClient(`${getBaseUri()}/${params.id}/delete`, {
                headers: headers,
                method: "DELETE",
            });
            return convertHTTPResponse(response, DELETE, resource, params);
        },
        deleteMany: async () => Promise.reject("Method Not Supported"),
        getList: async (resource, params) => {
            console.log("getList - ", resource , " - ", params);
            return httpClient(`${getBaseUri()}/list${convertToGetListParams(params)}`, {
                headers: new Headers({ Authorization: `Bearer ${localStorage.getItem('auth')}` }),
                method: "GET",
            })
                .then(response => convertHTTPResponse(response, GET_LIST, resource, params));
        },
        getMany: async () => Promise.reject("Method Not Supported"),
        getManyReference: async (resource, params) => {
            console.log("getManyReference - ", resource , " - ", params);
            const { id, target } = params;
            const response = await httpClient(`${getBaseUri()}/list${convertToGetListParams(params)}&${target}=${id}`, {
                headers: headers,
                method: "GET",
            });
            return convertHTTPResponse(response, GET_MANY_REFERENCE, resource, params);
        },
        getOne: async (resource, params) => {
            console.log("getOne - ", resource , " - ", params);
            const response = await httpClient(`${getBaseUri()}/${params.id}`, {
                headers: headers,
                method: "GET",
            });
            return convertHTTPResponse(response, GET_ONE, resource, {});
        },
        update: async (resource, params) => {
            console.log("update - ", resource , " - ", params);
            const { id, data } = {...params};
            const response = await httpClient(`${getBaseUri()}/${id}/update`, {
                headers: headers,
                method: "PUT",
                body: JSON.stringify(data),
            });
            return convertHTTPResponse(response, UPDATE, resource, params);
        },
        updateMany: () => Promise.reject("Method Not Supported"),
    } as DataProvider;
};
