import { Create } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box } from "@mui/material";
import BrandEditForm from "./BrandEditForm";

const BrandCreate = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Brands',
            resource: 'brand',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Brand Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Brand Management" items={breadcrumbItems} />
                <BrandEditForm type="create" />
            </Box>
        </Create>
    );
};

export default BrandCreate;
