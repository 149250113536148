import React from "react";
import { IconButton, styled, IconButtonProps } from "@mui/material";

/**
 * Customised IconButton Components with square blue text colour and background colour
 */
const CustomIconButton = styled(IconButton, {
    name: 'CustomIconButton',
    // overridesResolver: (props, styles) => [
    //     styles.root,
    //     props.color === 'primary' && styles.primary,
    //     props.color === 'mainPrimary' && styles.mainPrimary,
    //     props.color === 'errorC' && styles.errorC,
    // ],
})<IconButtonProps>(({ theme }) => ({
    borderRadius: 5,
    backgroundColor: 'aliceblue',
}));

export default CustomIconButton;
