import React, { useEffect, useMemo } from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Fade,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import GroupedButton from "../../component/GroupedButton";
import { DateField, ReferenceManyField, RichTextField, Show, TextField, useShowContext } from "react-admin";
import NatureIcon from '@mui/icons-material/Nature';
import Grid from '@mui/material/Unstable_Grid2';
import EquipmentNoteGridView from "./component/EquipmentNoteGridView";
import CreateIconButton from "../../component/CreateIconButton";
import EquipmentFileGridView from "./component/EquipmentFileGridView";
import { useParams, useNavigate } from "react-router-dom";
import ReportIcon from '@mui/icons-material/Report';

const EquipmentShow: React.FC = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Equipments",
            resource: "equipment",
            type: "list",
        },
        {
            label: "Record",
            isCurrent: true,
        }
    ];

    const EquipmentShowContent: React.FC = () => {

        const navigate = useNavigate();
        const { id } = useParams();
        const params = useParams()["*"];

        const { tab } = useMemo(() => {
            if (!params)
                return { tab: "details" };
            const split = params.split("/");
            return {
                tab: split[0],
            };
        }, [params]);

        const [ tabValue, setTabValue ] = React.useState(0);
        const { record, isLoading } = useShowContext<Equipment>();

        const handleChange = (event: React.SyntheticEvent, newValue: number) => {
            const page = newValue === 0 ? "details" : newValue === 1 ? "notes" : "files";
            navigate(`/equipment/${id}/show/${page}`, { replace: true });
        };

        useEffect(() => {
            if (! ["details", "notes", "files"].includes(tab))
                navigate(`/equipment/${id}/show/details`);
        }, [id, navigate, tab]);

        useEffect(() => {
            if (tab) {
                switch (tab) {
                    case "notes":
                        setTabValue(1);
                        break;
                    case "files":
                        setTabValue(2);
                        break;
                    default:
                        setTabValue(0);
                }
            }
        }, [tab]);

        if (isLoading)
            return <CircularProgress />;

        if (! record)
            return <>Record Not Found</>;

        return (
            <Fade in={true} timeout={0}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Details" value={0} />
                            <Tab label="Notes" value={1} />
                            <Tab label="Files" value={2} />
                        </Tabs>
                    </Box>

                    {tabValue === 0 && (
                        <Card sx={{ mt: 5 }}>
                            <CardHeader
                                title={record.name}
                                action={<GroupedButton />}
                            />
                            <Divider />
                            <CardContent>
                                <Grid container>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Record ID</Typography>
                                            <TextField source="recordId" />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Name</Typography>
                                            <TextField source="name" />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Other name</Typography>
                                            <TextField source="nameZhHk" />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Created by</Typography>
                                            <TextField source="createdBy" /> @ <DateField source="createdDate"
                                                                                          showTime />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Last Updated</Typography>
                                            <TextField source="updatedBy" /> @ <DateField source="updatedDate"
                                                                                          showTime />
                                        </Box>
                                    </Grid>
                                    {!! record.description && (
                                        <Grid xs={12} display="flex">
                                            <NatureIcon fontSize="small" />
                                            <Box flexGrow={1}>
                                                <Typography variant="body2">Description</Typography>
                                                <RichTextField source="description" />
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    )}

                    {tabValue === 1 && (
                        <Fade in={true} timeout={0}>
                            <Card sx={{ mt: 5 }} variant="outlined" color="error">
                                <CardHeader
                                    title="Equipment Notes"
                                    action={<CreateIconButton customPath={`/equipment/${record.recordId}/note/create`} />}
                                />
                                <Divider />
                                <CardContent>
                                    <ReferenceManyField label="Notes" reference="equipment/note"
                                                        target="equipmentId"
                                                        pagination={undefined} perPage={999}
                                                        sort={{ field: 'recordId', order: 'ASC' }}>
                                        <EquipmentNoteGridView />
                                    </ReferenceManyField>
                                </CardContent>
                            </Card>
                        </Fade>

                    )}


                    {tabValue === 2 && (
                        <Fade in={true} timeout={0}>
                            <Card sx={{ mt: 5 }} variant="outlined" color="error">
                                <CardHeader title="Equipment Files" action={<CreateIconButton
                                    customPath={`/equipment/${record.recordId}/note/create`} />} />
                                <Divider />
                                <CardContent>
                                    <ReferenceManyField label="Notes" reference="equipment/note"
                                                        target="equipmentId"
                                                        pagination={undefined} perPage={999}
                                                        sort={{ field: 'recordId', order: 'ASC' }}>
                                        <EquipmentFileGridView />
                                    </ReferenceManyField>
                                </CardContent>
                            </Card>
                        </Fade>

                    )}
                </Box>
            </Fade>
        )
    };

    return (
        <Show actions={false}
              component="div"
              title="Eqipment Management"
              resource="equipment"
        >
            <Box component="div">
                <Alert color="info" sx={{ mt: 1 }} icon={<ReportIcon />}>Experiment function, mobile view is not supported</Alert>
                <CustomBreadcrumbs title="View Equipment" items={breadcrumbItems} />
                <EquipmentShowContent />
            </Box>
        </Show>
    );
};

export default EquipmentShow;
