import React from "react";
import { Resource } from "react-admin";
import RetailerMembershipList from "./RetailerMembershipList";
import RetailerMemberShipShow from "./RetailerMemberShipShow";

const RetailerMembership = (
    <Resource key="retailer/membership"
              name="retailer/membership"
              list={RetailerMembershipList}
              show={RetailerMemberShipShow}
    />
);

export default RetailerMembership;