import React from "react";
import {
    BooleanInput, maxValue,
    minValue,
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    useCreate,
    useCreateContext,
} from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";
import { useNavigate } from "react-router";

const RetailerEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === "edit" && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       label="Name"
                                       fullWidth
                                       variant="outlined"
                                       validate={[ required() ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput name="description"
                                       source="description"
                                       label="Description"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="imagePath"
                                       source="imagePath"
                                       label="Image Path"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <BooleanInput name="isVoucherRetailer"
                                          source="isVoucherRetailer"
                                          label="Is voucher retailer?"
                                          fullWidth
                                          variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="seq"
                                         source="seq"
                                         label="Display Sequence"
                                         step={1}
                                         min={0}
                                         max={99999}
                                         defaultValue={5000}
                                         helperText="The smaller the number, the later the order"
                                         fullWidth
                                         variant="outlined"
                                         validate={[ required(), number(), minValue(0), maxValue(99999) ]}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
};

export default RetailerEditForm;
