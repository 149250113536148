import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Edit, SimpleForm, useEditContext, useRedirect, useTranslate } from "react-admin";
import { Box } from "@mui/material";
import VoucherEditForm from "./VoucherEditForm";
import { useParams } from "react-router-dom";
import { trackEvent } from "../../support/googleAnalytics";

const VoucherEdit = () => {

    const { id } = useParams();
    const translate = useTranslate();
    const redirect = useRedirect();

    const voucherEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Index',
            path: "/voucher",
        },
        {
            label: 'List',
            path: "/voucher/list",
        },
        {
            label: 'Voucher',
            path: `/voucher/${id}/show`,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    const VoucherEditContent = () => {
        const { record } = useEditContext();

        return (
            <Box>
                <CustomBreadcrumbs title={translate("resources.voucher.page.edit.title")} items={voucherEditBreadcrumbItems} />
                <Box sx={{ width: '100%' }}>
                    <SimpleForm mode="onBlur" reValidateMode="onBlur">
                        <Box sx={{ width: "100%" }}>
                            <VoucherEditForm type='edit' record={record} />
                        </Box>
                    </SimpleForm>
                </Box>
            </Box>
        );
    };

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Voucher Management"
              resource="voucher"
              id={id}
              mutationOptions={{
                  onSuccess: (data: any, _variables: any, _context: any) => {
                      trackEvent("voucher", "create", `voucher ID: ${data.recordId}`);
                      redirect(`/voucher/${data.recordId}/show`);
                  },
              }}
              redirect="show"
        >
            <VoucherEditContent />
        </Edit>
    )

}

export default VoucherEdit;
