import { Menu } from 'react-admin';
import AppMenu, { appMenuItems } from "./component/custom/AppMenu";

export const MyMenu = () => {

    return (
        <Menu>
            <AppMenu menuItems={appMenuItems} />
        </Menu>
    );
};