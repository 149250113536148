import React, { ReactElement } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import { useDelete, useNotify, useRedirect, useResourceContext } from "react-admin";
import Swal from "sweetalert2";
import { useRecordContext } from "ra-core";
import CustomIconButton from "./common/CustomIconButton";

// useful to prevent click bubbling in a Datagrid with rowClick
const stopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation();

const defaultIcon = <DeleteOutlineOutlinedIcon  sx={{ fontSize: 16 }}  />

const CustomDeleteIconButton: React.FC<{
    title?: string,
    icon?: ReactElement,
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>,
    IconButtonProps?: IconButtonProps,
    redirectTo?: string,
    onSuccessFunction?: () => void,
}> =
    ({
         title = 'Delete',
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         redirectTo = 'list',
         onSuccessFunction = () => {},
     }) => {
        const resource = useResourceContext();
        const record = useRecordContext();

        const redirect = useRedirect();
        const notify = useNotify();

        const [ deleteOne, { isLoading, error } ] = useDelete();

        const deleteDialog = () => {
            Swal.fire({
                title: `Delete Record #${record?.id}`,
                text: 'Are you sure you want to delete this item?',
                icon: 'warning',
                footer: 'The action cannot be reversed!',
                showCancelButton: true,
                focusConfirm: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        deleteOne(resource, {
                                id: record?.id,
                                previousData: record,
                            },
                            {
                                onSuccess: () => {
                                    if (redirectTo === 'reload') {
                                        Swal.fire({
                                            title: 'Record delete',
                                            showConfirmButton: true,
                                            showDenyButton: true,
                                            confirmButtonText: 'Stay here',
                                            denyButtonText: 'Back to list'
                                        }).then((result) => {
                                            console.log(result);
                                            if (result.isConfirmed || result.isDismissed) {
                                                onSuccessFunction()
                                            } else {
                                                redirect(redirectTo, resource);
                                            }
                                        });
                                    } else {
                                        Swal.fire({
                                            title: 'Record delete',
                                        }).then();
                                    }
                                    if (onSuccessFunction) onSuccessFunction();
                                },
                                onError: () => {
                                    notify('Failed to delete', {
                                        type: 'error',
                                        messageArgs: { smart_count: 1 },
                                    });
                                },
                            },)
                    }
                });
        }

        return (
            <Tooltip title={title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        size="small"
                        color="softError"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteDialog();
                        }}
                        {...IconButtonProps}>
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        );
    }

export default CustomDeleteIconButton;
