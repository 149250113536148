import React from "react";
import {
    Show,
    useRedirect,
    useShowContext
} from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import Loading from "../../../component/common/Loading";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";
import CustomEditButtonV2 from "../../../component/CustomEditButtonV2";

const VoucherConsumptionShow: React.FC<{}> = () => {

    const { voucherId, consumptionId } = useParams();
    const redirect = useRedirect();

    const voucherConsumptionShowBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Consumption',
            isCurrent: true,
        }
    ];

    const VoucherConsumptionShowContent: React.FC<{}> = () => {
        const {
            isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
            isLoading, // boolean that is true until the record is available for the first time
            record, // record fetched via dataProvider.getOne() based on the id from the location
            refetch, // callback to refetch the record via dataProvider.getOne()
        } = useShowContext();

        if (isLoading || isFetching) {
            return <Loading />;
        }


        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 customPath={`/voucher/${voucherId}/consumption/${consumptionId}/edit`} />
                <CustomDeleteIconButtonV2 redirectTo={`/voucher/${voucherId}/show`} onSuccessFunction={() => refetch()} />
            </Stack>
        );

        return (
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={`Voucher Consumption #${record.recordId}`} action={<CardHeaderAction />} />
                    <CardContent sx={{ width: '100%' }}>
                        <Typography>Record ID: {record.recordId}</Typography>
                        <Typography>Purchase Date: {record.purchaseDate}</Typography>
                        <Typography>Amount: £{record.amount}</Typography>

                        <Divider />

                        <Typography>Created By: {record.createdBy}</Typography>
                        <Typography>Created Date: {moment(record.createdDate).toISOString()}</Typography>
                        <Typography>Updated By: {record.updatedBy}</Typography>
                        <Typography>Updated Date: {moment(record.updatedDate).toISOString()}</Typography>

                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Show actions={false}
              component="div"
              title="Voucher Consumption Management"
              resource="voucherConsumption"
              id={consumptionId}
              queryOptions={{
                  onError: () => {
                      redirect('show', 'voucher', voucherId)
                  }
              }}
        >
            <CustomBreadcrumbs title="Voucher Consumption" items={voucherConsumptionShowBreadcrumbItems} />
            <VoucherConsumptionShowContent />
        </Show>
    );
};

export default VoucherConsumptionShow;
