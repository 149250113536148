import { Resource } from "react-admin";
import ProductGroupList from "./ProductGroupList";
import ProductGroupShow from "./ProductGroupShow";
import ProductGroupCreate from "./ProductGroupCreate";
import ProductGroupEdit from "./ProductGroupEdit";

const ProductGroup = (
    <Resource key="productGroup" name="productGroup"
              list={ProductGroupList}
              show={ProductGroupShow}
              create={ProductGroupCreate}
              edit={ProductGroupEdit}
    />
);

export default ProductGroup;
