import React from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { Button, Card, CardActions, CardContent, CardHeader, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SnackbarV2 = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {

    const { closeSnackbar } = useSnackbar();
    const { id, variant, hideIconVariant, title, message, action } = props;

    const CustomAlert = styled(Card) ({
        "& .MuiCardHeader-root": {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 0,
            paddingBottom: 0,
            "& .MuiCardHeader-title": {
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
            },
            "& .MuiCardHeader-action": {
                paddingTop: "4px",
                paddingRight: "4px",
            }
        },
        "& .MuiCardContent-root": {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
        },
        "& .MuiCardActions-root": {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
        }
    });

    return (
        <SnackbarContent ref={ref}>
            <CustomAlert>
                <CardHeader
                    title={title}
                    action={<IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            closeSnackbar(id);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>}
                />
                <CardContent>
                    {message}
                </CardContent>
                <CardActions>
                    <Button>Confirm</Button>
                    <Button>Cancel</Button>
                </CardActions>
            </CustomAlert>
        </SnackbarContent>
    );

});

export default SnackbarV2;
