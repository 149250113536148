import { AxiosHeaders, Method, RawAxiosRequestHeaders } from "axios";
import { getAuth } from "../auth/AuthUtils";

export const getHeaders = (withAuth: boolean = true)
    : (RawAxiosRequestHeaders & Partial<{ [Key in Method as Lowercase<Key>]: AxiosHeaders; } & {
    common: AxiosHeaders
}>) | AxiosHeaders => {
    return {
        'Content-Type': 'application/json',
        'Authorization': withAuth ? `Bearer ${getAuth()}` : null,
    };
};
