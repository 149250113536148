export const colors = {
    blue: {
        50: "#f0f6fe",
        100: "#e2eefd",
        150: "#C5DDFB",
        200: "#bcd8fc",
        250: "#9AC3F9",
        300: "#7eb1f4",
        400: "#4a8fea",
        500: "#2574db",
        550: "#1666D0",
        600: "#0c5cc5",
        700: "#0049A9",
        800: "#003a86",
        900: "#002A61",
        950: "#001633",
    },
    gray: {
        50: "#F1F3F3",
        100: "#E0E3E6",
        150: "#D4D9DD",
        170: "#CED3D9",
        200: "#BFC6CE",
        300: "#B2BAC5",
        400: "#838FA0",
        500: "#6B7786",
        550: "#5E6978",
        600: "#525C69",
        700: "#3D454F",
        800: "#252B31",
        900: "#0B0C0F",
        950: "#0B0C0F",
    },
    yellow: {
        100: "#FFE3A6",
        300: "#F9B625",
        500: "#E39B00",
        700: "#BB8000",
        800: "#875C00",
        900: "#523800",
    },
    green: {
        100: "#E3FAC3",
        300: "#B9F467",
        500: "#76CA02",
        700: "#599900",
        800: "#3C6600",
        900: "#1F3600",
    },
    red: {
        100: "#FFB7CE",
        300: "#FA578B",
        500: "#E60C51",
        700: "#C2003D",
        800: "#93002F",
        900: "#63001F",
    },
    teal: {
        100: "#E1F9FF",
        300: "#9BE5FA",
        500: "#5FCAE7",
        700: "#36A6C5",
        800: "#1F7E98",
        900: "#12586B",
    },
    purple: {
        100: "#F3C6FF",
        300: "#DC73F9",
        500: "#BB30E2",
        700: "#910AB7",
        800: "#640080",
        900: "#380047",
    },
    black: "#0B0C0F",
    white: "#FFFFFF",
}



const primary = {
    500: colors.gray["800"],
}

const accent = {
    50: "#f0f6fe",
    100: "#e2eefd",
    150: "#C5DDFB",
    200: "#bcd8fc",
    250: "#9AC3F9",
    300: "#7eb1f4",
    400: "#4a8fea",
    500: "#2574db",
    550: "#1666D0",
    600: "#0c5cc5",
    700: "#0049A9",
    800: "#003a86",
    900: "#002A61",
    950: "#001633",
};

const secondary = {
    50: "#F1F3F3",
    100: "#E0E3E6",
    150: "#D4D9DD",
    170: "#CED3D9",
    200: "#BFC6CE",
    300: "#B2BAC5",
    400: "#838FA0",
    500: "#6B7786",
    550: "#5E6978",
    600: "#525C69",
    700: "#3D454F",
    800: "#252B31",
};

const success = {
    100: colors.green["100"],
    300: colors.green["300"],
    500: colors.green["500"],
};

const warning = {
    100: colors.yellow["100"],
    300: colors.yellow["300"],
};

const error = {
    100: "#FFB7CE",
    300: "#FA578B",
};

const info = {
    100: colors.teal["100"],
    300: colors.teal["300"],
};

const others = {
    tertiary: "#838FA0",
    quaternary: "#BFC6CE",
    disable: "#B2BAC5",
    inverseWhite: "#D4D9DD",
    inverseDark: "#0B0C0F",
    primaryDefault: primary["500"],
    dragging: "#B2BAC5",
};

const text = {
    accent: accent["500"],
    primary: primary["500"],
    secondary: secondary["500"],
    error: colors.red["500"],
    warning: colors.yellow["300"],
    disable: others.disable,
}