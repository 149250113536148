import React from "react";
import { Button, IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CustomIconButton from "./CustomIconButton";
import { useNotify } from "react-admin";

const CopyMe: React.FC<{
    text: string;
}> = ({
          text
      }) => {

    const notify = useNotify();

    return (
        <>
            {text} {text && (
            <IconButton
                size="small"
                color="primary"
                onClick={() => {
                    navigator.clipboard.writeText(text);
                    notify("Copied to clipboard");
                }}
            >
                <ContentCopyOutlinedIcon sx={{ fontSize: "14px" }} />
            </IconButton>
        )}
        </>
    );
};

export default CopyMe;
