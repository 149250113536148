import React, { useContext } from "react";
import OctopusV4ContextProvider, { OctopusV4Context } from "./context/OctopusV4Context";
import { Box, Button } from "@mui/material";

const OctopusV4Index: React.FC = () => {

    const Wrapper: React.FC = () => {

        const { account: { isLoading, data }, selectedProperty, changeSelectedProperty } = useContext(OctopusV4Context);

        if (! data) {
            return (
                <Box>
                    Account Not Found
                </Box>
            );
        }

        return (
            <>
                SelectedProperty : {selectedProperty?.recordId} <br />
                Properties List : <br />
                {data.properties.map((property) => {
                    return <Box key={property.recordId}>{property.addressLine1} <Button
                        onClick={() => changeSelectedProperty(property)} disabled={selectedProperty === property}>Change it</Button></Box>
                })}
            </>
        )
    }

    return (
        <OctopusV4ContextProvider>
            <Wrapper />
        </OctopusV4ContextProvider>
    )
};

export default OctopusV4Index;
