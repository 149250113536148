import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import CustomButton from "../../../component/CustomButton";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import CustomIconButton from "../../../component/CustomIconButton";
import React from "react";

const TestButton = () => {

    return (
        <Card sx={{ mt: 4 }}>
            <CardHeader title="Test Panel - Only available for admins" />
            <CardContent>
                {[ "primary", "secondary", "success", "info", "warning", "danger", "dark", "link", "light" ].map((item: any) => (
                    <Stack direction="row" spacing={1} mt={1}>
                        <CustomButton color={item}>123</CustomButton> &nbsp;
                        <CustomButton color={item} variant="contained" soft>123</CustomButton> &nbsp;
                        <CustomButton color={item} variant="outlined">123</CustomButton> &nbsp;
                        <CustomButton color={item} variant="text">123</CustomButton> &nbsp;
                        <CustomButton color={item} disabled>123</CustomButton> &nbsp;
                    </Stack>
                ))}
                <CustomButton color="danger" startIcon={<AddModeratorIcon />}>123</CustomButton> &nbsp;
                {[ "primary", "secondary", "success", "info", "warning", "danger", "dark", "light" ].map((item: any) => (
                    <Stack direction="row" spacing={1} mt={1}>
                        <CustomIconButton color={item}><AddModeratorIcon /></CustomIconButton> &nbsp;
                        <CustomIconButton color={item} soft><AddModeratorIcon /></CustomIconButton> &nbsp;
                        <CustomIconButton color={item} disabled><AddModeratorIcon /></CustomIconButton> &nbsp;
                    </Stack>
                ))}
            </CardContent>
        </Card>
    );
};

export default TestButton;
