import React from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Edit, SimpleForm } from "react-admin";
import { Box } from "@mui/material";
import AnnouncementEditForm from "./AnnouncementEditForm";

const AnnouncementEdit: React.FC = () => {
    const { id } = useParams();
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Announcement',
            isCurrent: false,
            resource: 'announcement',
            type: 'list',
        },
        {
            label: 'Announcement',
            isCurrent: false,
            resource: 'announcement',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit
            actions={false}
            component="div"
            title="Announcement Management"
            mutationMode="pessimistic"
        >
            <Box sx={{ width: "100%" }}>
                <CustomBreadcrumbs title="Edit Announcement" items={breadcrumbItems} />
                <AnnouncementEditForm type="edit" />
            </Box>
        </Edit>
    );
};

export default AnnouncementEdit;
