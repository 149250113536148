import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Create } from "react-admin";
import { Box } from "@mui/material";
import ProductEditForm from "./ProductEditForm";

const ProductCreate = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Products',
            resource: 'product',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Product Management"
                redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Create Product" items={breadcrumbItems} />
                <ProductEditForm type="create" />
            </Box>
        </Create>
    );
}

export default ProductCreate;
