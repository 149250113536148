import React, { ReactComponentElement } from "react";
import { Box, Fab, FabProps, IconButtonProps, Tooltip, TooltipProps } from "@mui/material";
import { useCreatePath, useResourceContext } from "react-admin";
import CustomIconButton from "./common/CustomIconButton";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router";

const defaultIcon = <AddIcon />;

interface CreateIconButtonProps {
    disabledFloating?: boolean;
    forwardedRef?: React.ForwardedRef<any>;
    style?: React.CSSProperties;
    title?: string;
    icon?: ReactComponentElement<any>;
    TooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
    IconButtonProps?: IconButtonProps;
    isFloating?: boolean;
    customPath?: string;
    FabProps?: FabProps;
}

const CreateIconButton: React.FC<CreateIconButtonProps> =
    ({
         disabledFloating = false,
         forwardedRef,
         style,
         title = 'Create',
         icon = defaultIcon,
         TooltipProps,
         IconButtonProps,
         customPath,
         isFloating = false,
         FabProps,
     }) => {
        const resource = useResourceContext();
        const navigate = useNavigate();
        const createPath = useCreatePath();

        const FloatingButton = () => (
            <Fab
                onClick={(e) => {
                    if (customPath) {
                        navigate(customPath);
                    } else {
                        navigate(createPath({
                            resource,
                            type: "create",
                        }));
                    }
                }}
                color="primary"
                {...FabProps}
            >
                {icon}
            </Fab>
        )

        const NormalButton = () => (
            <Tooltip title={title} disableInteractive {...TooltipProps}>
                <div>
                    <CustomIconButton
                        size="small"
                        color="softPrimary"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (customPath) {
                                navigate(customPath);
                            } else {
                                navigate(createPath({
                                    resource,
                                    type: "create",
                                }));
                            }
                        }}
                        {...IconButtonProps}>
                        {icon}
                    </CustomIconButton>
                </div>
            </Tooltip>
        );

        return (
            <Box component="span" style={style} ref={forwardedRef}>
                {!disabledFloating && isFloating ? <FloatingButton /> : <NormalButton />}
            </Box>
        );
    }

export default React.forwardRef((props: CreateIconButtonProps, ref) =>
    <CreateIconButton {...props} forwardedRef={ref} />);
