import React from "react";
import { BooleanField, Datagrid, FunctionField, List, Pagination, TextField, useTranslate } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Alert, Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CreateIconButton from "../../component/CreateIconButton";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomCloneButton from "../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../component/CustomDeleteIconButtonV2";
import EquipmentListGrid from "./component/EquipmentListGrid";
import ReportIcon from "@mui/icons-material/Report";

const EquipmentList: React.FC = () => {

    const translate = useTranslate();
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Equipments",
            isCurrent: true,
        },
    ];

    const defaultFilters = {};

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={50}
            pagination={<Pagination />}
            component="div"
            title="Equipment Management"
            filterDefaultValues={defaultFilters}
            empty={false}
        >
            <Box>
                <Alert color="info" sx={{ mt: 1 }} icon={<ReportIcon />}>Experiment function, mobile view is not supported</Alert>
                <CustomBreadcrumbs
                    title={translate("Equipment")}
                    items={breadcrumbItems}
                />
                <Card>
                    <CardHeader
                        title={translate("Equipment")}
                        action={<CreateIconButton />}
                        />
                    <Divider />
                    <CardContent>
                        <EquipmentListGrid />
                        {/*<Datagrid bulkActionButtons={false}>*/}
                        {/*    <FunctionField label="common.table.actions"*/}
                        {/*                   render={() => (*/}
                        {/*                       <Stack component="div" spacing={1} direction="row">*/}
                        {/*                           <CustomShowButton />*/}
                        {/*                           <CustomEditButton />*/}
                        {/*                           <CustomCloneButton*/}
                        {/*                               omitProps={[*/}
                        {/*                                   'id', 'recordId', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate',*/}
                        {/*                               ]}*/}
                        {/*                           />*/}
                        {/*                           <CustomDeleteIconButtonV2 IconButtonProps={{*/}
                        {/*                               sx: {*/}
                        {/*                                   "svg": {*/}
                        {/*                                       fontSize: "16px",*/}
                        {/*                                   }*/}
                        {/*                               }*/}
                        {/*                           }} />*/}
                        {/*                       </Stack>*/}
                        {/*                   )} />*/}
                        {/*    <TextField source="name" />*/}
                        {/*    <TextField source="nameZhHk" />*/}
                        {/*    <TextField source="recordId" label="#" />*/}
                        {/*</Datagrid>*/}
                    </CardContent>
                </Card>
            </Box>
        </List>
    )
};

export default EquipmentList;
