import {
    Box, Button,
    Card,
    CardContent,
    CardHeader, Divider,
    Typography
} from "@mui/material";
import {
    Datagrid, DeleteWithConfirmButton, Pagination, ReferenceManyField,
    SelectField,
    Show,
    TextField, useCreatePath,
    useShowContext,
} from "react-admin";
import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { SHOP_STATUS } from "./ShopConstant";
import CustomEditButton from "../../component/CustomEditButton";
import { useNavigate } from "react-router";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import moment from "moment";
import CustomEditButtonV2 from "../../component/CustomEditButtonV2";

const ShopShowLayout = () => {
    const {
        error,  // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
        isLoading, // boolean that is true until the record is available for the first time
        record, // record fetched via dataProvider.getOne() based on the id from the location
        refetch, // callback to refetch the record via dataProvider.getOne()
        resource, // the resource name, deduced from the location. e.g. 'posts'
    } = useShowContext();

    const navigate = useNavigate();
    const createPath = useCreatePath();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    const createBranchButtonHandler = () => {
        navigate({
            pathname: createPath({ type: 'create', resource: 'branch' }),
            search: `source=${encodeURIComponent(`{"shopId":${record.recordId}}`)}`,
        }, {
            state: {
                from: {
                    view: 'show',
                    resource: resource,
                    id: record.recordId,
                },
            },
        });
    }

    const CardHeaderAction = () => (
        <Box>
            <CustomEditButtonV2 />
            <DeleteWithConfirmButton />
            <CustomDeleteIconButton redirectTo='reload' onSuccessFunction={() => refetch()} />
        </Box>
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Card>
                <CardHeader title={`Shop #${record.recordId}`} action={<CardHeaderAction />} />
                <Divider />
                <CardContent sx={{ width: '100%' }}>
                    <Typography data-testid="HelloWorld">Record ID: {record.recordId}</Typography>
                    <Typography>Name: {record.name}</Typography>
                    <Typography>Description: {record.description}</Typography>
                    <Typography>Type: <SelectField source="status" choices={SHOP_STATUS} /></Typography>


                    <Typography>Created By: {record.createdBy}</Typography>
                    <Typography>Created Date: {moment(record.createdDate).toISOString()}</Typography>
                    <Typography>Updated By: {record.updatedBy}</Typography>
                    <Typography>Updated Date: {record.updatedDate}</Typography>

                </CardContent>
            </Card>

            <Card sx={{ marginY: 4 }}>
                <CardHeader title="Branches"
                            action={<Button onClick={() => createBranchButtonHandler()}>Create</Button>} />
                <CardContent>
                    <ReferenceManyField label="Branches" reference="branch" target="shopId"
                                        pagination={<Pagination />} perPage={5}
                                        sort={{ field: 'recordId', order: 'ASC' }}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="#" source="recordId" sortable={false} />
                            <TextField source="name" sortable={false} />
                            <Box>
                                <CustomEditButton />
                            </Box>
                        </Datagrid>
                    </ReferenceManyField>
                </CardContent>
            </Card>
        </Box>
    );
}

const ShopShow = (props: any) => {

    const shopListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Shops',
            isCurrent: false,
            resource: 'shop',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: true,
        }
    ];

    return (
        <Show actions={false}
              component="div"
              title="Shop Management"
              {...props}
        >
            <CustomBreadcrumbs title="View" items={shopListBreadcrumbItems} />
            <ShopShowLayout />
        </Show>
    );
};

export default ShopShow;
