import { Resource } from "react-admin";
import React from "react";
import CategoryIcon from "@mui/icons-material/Bookmark";
import BrandList from "./BrandList";
import BrandCreate from "./BrandCreate";
import BrandEdit from "./BrandEdit";
import BrandShow from "./BrandShow";

const Brand = (
    <Resource key="brand"
              name="brand"
              list={BrandList}
              create={BrandCreate}
              edit={BrandEdit}
              show={BrandShow}
              icon={CategoryIcon}
    />
);

export default Brand;