import { Menu } from "react-admin";
import LabelIcon from "@mui/icons-material/Label";
import AppMenu, { adminAppMenuItems } from "../component/custom/AppMenu";

export const AdminMenu = () => (
    <Menu>
        <AppMenu menuItems={adminAppMenuItems} />
    </Menu>
    // <Menu>
    //     <Menu.DashboardItem />
    //     <Menu.Item to="/" primaryText="Back To Front" leftIcon={<LabelIcon />} />
    //     <Menu.ResourceItem name="project" />
    //     <Menu.ResourceItem name="user" />
    // </Menu>
);
