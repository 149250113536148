import React from "react";
import {
    BooleanField,
    Datagrid,
    EmailField,
    FunctionField,
    List,
    OptionText,
    SelectField,
    TextField
} from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import CreateIconButton from "../../../component/CreateIconButton";
import CustomShowButton from "../../../component/CustomShowButton";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";
import { PROJECT_STATUS, PROJECT_STATUSES } from "../../constant/display";

const ProjectList = () => {
    return (
        <List actions={false}
              exporter={false}
              sort={{ field: 'recordId', order: 'ASC' }}
              perPage={25}
              empty={false}
              component="div"
              title="Project management"
        >
            <Box>
                <CustomBreadcrumbs title="Project Management" items={[]} />
                <Card>
                    <CardHeader title="Projects" action={<CreateIconButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton targetIdField="uuid" />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )} />
                            <TextField source="recordId" label="ID" />
                            <TextField source="uuid" label="UUID" />
                            <TextField source="name" />
                            <SelectField source="status" choices={PROJECT_STATUSES} optionText={(option: any) => {
                                const { id, Icon } = option;
                                const color = id === PROJECT_STATUS.ACTIVE ? "green" : "red";
                                return (
                                    <Icon sx={{ color: color }}/>
                                );
                            }} />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );
}

export default ProjectList;
