import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create, ResourceContextProvider } from "react-admin";
import { Box } from "@mui/material";
import ProductBarcodeEditForm from "./ProductBarcodeEditForm";
import { useParams } from "react-router-dom";

const ProductBarcodeCreate: React.FC = () => {

    const { productId } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [];

    return (
        <ResourceContextProvider value={"product/barcode"}>
            <Create actions={false}
                    component="div"
                    title="Barcode Management"
                    mutationOptions={{
                        meta: {
                            productId,
                        },
                    }}
                    redirect={`/product/${productId}/show`}
            >
                <Box>
                    <CustomBreadcrumbs title="Create Barcode" items={[ ...breadcrumbItems ]} />
                    <ProductBarcodeEditForm type="create" />
                </Box>
            </Create>
        </ResourceContextProvider>
    )
}

export default ProductBarcodeCreate;
