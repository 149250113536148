import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Edit } from "react-admin";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import QuantityUnitEditForm from "./QuantityUnitEditForm";

const QuantityUnitEdit = () => {
    const { id } = useParams();
    const quantityUnitEditBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Quantity Units',
            isCurrent: false,
            resource: 'quantityUnit',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: false,
            resource: 'quantityUnit',
            type: 'show',
            id: id,
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Quantity Unit Management"
        >
            <Box>
                <CustomBreadcrumbs title="Quantity Unit Management" items={quantityUnitEditBreadcrumbItems} />
                <QuantityUnitEditForm type="edit" />
            </Box>
        </Edit>
    );

}

export default QuantityUnitEdit;
