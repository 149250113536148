import React from "react";
import { Breadcrumbs, Typography, Link, Toolbar, Box } from "@mui/material";
import { CreatePathParams, useCreatePath, useCreate } from "react-admin";
import { useNavigate } from "react-router";

export interface BreadcrumbItem {
    resource?: string;
    type?: string;
    id?: string | number;
    label: string;
    isCurrent?: boolean;
    path?: string;
}

const CustomBreadcrumbs: React.FC<{ title: string, items: BreadcrumbItem[] }> =
    ({
         title,
         items
     }) => {
        const createPath = useCreatePath();
        const navigate = useNavigate();

        return (
            <Box>
                <Toolbar>
                    <Typography variant="h6"
                                sx={{
                                    mr: 2,
                                    flexGrow: 1,
                                    textTransform: "uppercase",
                                    fontWeight: 600,
                                    fontSize: "16px"
                                }}
                    >
                        {title}
                    </Typography>
                    <Breadcrumbs>
                        {items.map((item, index) => {
                            if (item.isCurrent) {
                                return (
                                    <Typography
                                        key={`b-${index}`}
                                        color="text.primary">{item.label}</Typography>
                                );
                            } else {
                                return (
                                    <Link
                                        key={`b-${index}`}
                                        underline="hover"
                                        color="primary"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(item.path ? item.path : createPath({
                                                resource: item.resource,
                                                id: item.id ? item.id : null,
                                                type: item.type,
                                            } as CreatePathParams));
                                        }}>
                                        {item.label}
                                    </Link>
                                )
                            }
                        })}
                    </Breadcrumbs>
                </Toolbar>
            </Box>
        );

    };

export default CustomBreadcrumbs;
