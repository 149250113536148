import React from "react";
import {
    CreateButton,
    Datagrid,
    FilterForm, FunctionField,
    List, ReferenceManyCount, SearchInput, SelectField,
    TextField, TextInput
} from "react-admin";
import { Box, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import { SHOP_STATUS } from "./ShopConstant";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";


const shopFilters = [
    <SearchInput source="q" alwaysOn variant="outlined" />,
    <TextInput label="recordId" source="recordId" name="recordId" alwaysOn variant="outlined" />,
    <TextInput label="name" source="name" name="name" alwaysOn variant="outlined" />,
]

const ListFilter = () => <FilterForm filters={shopFilters} />;

const ShopList = () => {

    const shopListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Shops',
            isCurrent: true,
        },
    ];

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            empty={false}
            component="div"
            title="Shop Management"
        >
            <Box>
                <CustomBreadcrumbs title="Shop Management" items={shopListBreadcrumbItems} />
                <Card>
                    <CardHeader title="Shops" action={<CreateButton />} />
                    <Divider />
                    <ListFilter />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="Actions" render={() => (
                                <Stack direction="row" spacing={1}>
                                    <CustomShowButton />
                                    <CustomEditButton />
                                    <CustomDeleteIconButton />
                                </Stack>
                            )}/>
                            <TextField source="recordId" />
                            <TextField source="name" />
                            <TextField source="description" />
                            <SelectField source="status" choices={SHOP_STATUS} />
                            <ReferenceManyCount label="Count of Branches" reference="branch" target="shopId" />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );
};

export default ShopList;
