import React, { Fragment, useEffect } from 'react';
import "@fontsource/open-sans";
import './App.css';
import config from "./config.json";
import {
    addRefreshAuthToAuthProvider, addRefreshAuthToDataProvider,
    Admin,
    AppBar,
    AppBarProps,
    combineDataProviders,
    CustomRoutes,
    Layout,
    LayoutProps,
    Resource,
} from "react-admin";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { authProvider } from "./authProvider";
import { Dashboard } from "./Dashboard";
import { MyLayout } from "./MyLayout";
import Login from "./pages/auth/Login";
import ProjectSelect from "./pages/auth/ProjectSelect";
import { sharedDataProvider } from "./hooks/sharedDataProvider";
import AdminApp from "./admin/AdminApp";
import shop from "./pages/shop";
import branch from "./pages/branch";
import StockLocation from "./pages/stock/location";
import QuantityUnit from "./pages/quantityUnit";
import ProductGroup from "./pages/product/group";
import Brand from "./pages/brand";
import Product from "./pages/product";
import Stock from "./pages/stock";
import Announcement from "./pages/announcement";
import PasswordChange from "./pages/auth/PasswordChange";
import StoreMembership from "./pages/storeMembership";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { en } from "./i18n/en";
import { zhHK } from "./i18n/zh_hk";
import { refreshAuth } from "./refreshAuth";
import Document from "./pages/document";
import getAppTheme from "./theme/AppTheme";
import Retailer from "./pages/retailer";
import { voucherSubResourceDataProvider } from "./hooks/VoucherSubResourceDataProvider";
import Voucher from './pages/voucher';
import Bno from "./pages/bno";
import OctopusV4 from "./pages/energy/octopus/OctopusV4";
import Test from "./pages/test";
import ReactGA from "react-ga4";
import { setUserUuid } from "./support/googleAnalytics";
import { octopusDataProvider } from "./pages/energy/octopus/data/octopusDataProvider";
import { voucherDataProvider } from "./pages/voucher/data/voucherDataProvider";
import Equipment from "./pages/equipment";
import { QueryClient } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import StyledMaterialDesignContent from "./component/StyledMaterialDesignContent";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon } from "@mui/material";
import CustomSnackbar from "./component/CustomSnackbar";
import SnackbarV2 from "./component/SnackbarV2";
import RetailerMembership from "./pages/retailer/membership";
import ScannerContextProvider from './pages/scanner/context/ScannerContextProvider';

export const CustomAppBar = (props: JSX.IntrinsicAttributes & AppBarProps) => (
    <div hidden={true}>
        <AppBar {...props}></AppBar>
    </div>
);
const CustomLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => (
    <Layout {...props} appBar={CustomAppBar} sx={{ "& .RaLayout-appFrame": { marginTop: 0 } }} />
);

const superDataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'voucher':
            return voucherDataProvider();
        case 'voucherRemark':
            return voucherSubResourceDataProvider("remark");
        case 'voucherConsumption':
            return voucherSubResourceDataProvider("consumption");
        case 'octopus':
            return octopusDataProvider();
        default:
            return sharedDataProvider(`${config.BASE_URL}`);
    }
});


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            retryDelay: 5 * 1000,
            refetchOnWindowFocus: false,
            staleTime: 10 * 60 * 1000,
        },
    },
});

export const myI18nProvider = polyglotI18nProvider(
    (locale) => {
        switch (locale) {
            case "zh-hk":
                return zhHK;
            default:
                return en;
        }
    },
    "en-gb",
    [ { name: 'English', locale: 'en-gb' }, { name: '繁體中文', locale: "zh-hk" } ],
    { allowMissing: true, },
);


const Front = () => {

    return (
        <Admin title="Home Cloud"
            // notification={CustomNotification}
               dataProvider={addRefreshAuthToDataProvider(superDataProvider, refreshAuth)}
               authProvider={addRefreshAuthToAuthProvider(authProvider, refreshAuth)}
               layout={MyLayout}
               loginPage={Login}
               dashboard={Dashboard}
               queryClient={queryClient}
               theme={getAppTheme(false)}
               darkTheme={getAppTheme(true)}
               disableTelemetry
               i18nProvider={myI18nProvider}
        >
            {permissions => (
                <Fragment>
                    {Voucher}

                    <Resource key="shop" name="shop" {...shop} />
                    <Resource key="branch" name="branch" {...branch} />

                    {Brand}
                    {Product}
                    {Stock}
                    {StockLocation}
                    {QuantityUnit}
                    {ProductGroup}
                    {Announcement}
                    {Document}
                    {Retailer}
                    {RetailerMembership}
                    {Equipment}

                    <CustomRoutes>
                        <Route path="/password/change" element={<PasswordChange />} />
                        <Route path="/energy/octopus/v4/*" element={<OctopusV4 />} />
                        {StoreMembership}
                    </CustomRoutes>

                    {Bno}
                    {Test}

                    <CustomRoutes noLayout>
                        <Route path="/projectSelect" element={<ProjectSelect />} />
                    </CustomRoutes>
                </Fragment>
            )}
        </Admin>
    );
};

function App() {

    useEffect(() => {
        if (config.GOOGLE_ANALYTICS_ENABLED) {
            ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_CODE, {
                gaOptions: {
                    debug_mode: false,
                }
            });
            setUserUuid();
        }
    }, []);

    return (
        <SnackbarProvider
            iconVariant={{
                info: <Icon sx={{ fontWeight: "unset" ,marginInlineEnd: "8px" }}><InfoOutlinedIcon /></Icon>,
            }}
            Components={{
                success: CustomSnackbar,
                info: SnackbarV2,
                error: CustomSnackbar,
            }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            maxSnack={8}
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Front />} />
                    <Route path="/admin/*" element={<AdminApp />} />
                </Routes>
            </BrowserRouter>
        </SnackbarProvider>
    );
}

export default App;
