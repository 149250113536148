import React from "react";
import { Create } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import RetailerEditForm from "./form/RetailerEditForm";
import { useNavigate } from "react-router";

const RetailerCreate: React.FC = () => {

    const navigate = useNavigate();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Retailer Management"
                resource="retailer"
                redirect={false}
                mutationOptions={{
                    onSuccess: (data: Retailer, _variables, _context) => {
                        // replace path -> if user go back previous page -> will go to list / the second previous instead of form page
                        navigate(`/retailer/${data.recordId}/show`, {
                            replace: true,
                        });
                    }
                }}
        >
            <CustomBreadcrumbs title="Create Retailer" items={breadcrumbItems} />
            <RetailerEditForm type="create" />
        </Create>
    );

};

export default RetailerCreate;
