import React, { Fragment, useEffect, useState } from "react";
import { Title, useGetList, useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import { useUpdateUserProjectRoles, useUserProjectAssignedRoles } from "../../hook/dataHooks";
import {
    Box, Button,
    Checkbox,
    Paper, Stack, Table, TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, Toolbar
} from "@mui/material";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";


const UserProjectRoleView: React.FC = () => {

    const navigate = useNavigate();

    const [ tempSelectedUserProjectRoles, setTempSelectedUserProjectRoles ] = useState<number[]>([]);
    const { userId, projectUuid } = useParams();
    const {
        data: user,
        isLoading: isUserLoading,
        isError: isUserError
    } = useGetOne("user", { id: userId });
    const {
        data: projectRoles,
        isLoading: isProjectRolesLoading,
        isError: isProjectRolesError
    } = useGetList("project/role", {
        meta: {
            projectUuid,
        },
    });
    const {
        data: assignedUserProjectRoles,
        isLoading: isAssignedUserProjectRolesLoading,
        isError: isAssignedUserProjectRolesError
    } = useUserProjectAssignedRoles(user?.uuid, projectUuid);
    const queryClient = useQueryClient();

    const [ update, { isPending } ] = useUpdateUserProjectRoles({
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData([ 'super', 'user/project/roles', projectUuid, user.uuid ], variables.roleIds);
            Swal.fire({
                title: "Modify user project roles successfully",
                icon: "success",
                showCancelButton: false,
                focusConfirm: true,
            });
            console.log(queryClient.getQueryCache());
        }
    });

    useEffect(() => {
        if (isAssignedUserProjectRolesLoading || isAssignedUserProjectRolesError || ! assignedUserProjectRoles || assignedUserProjectRoles.length === 0) {
            setTempSelectedUserProjectRoles([]);
        } else {
            setTempSelectedUserProjectRoles([ ...assignedUserProjectRoles ]);
        }
    }, [ assignedUserProjectRoles, isAssignedUserProjectRolesError, isAssignedUserProjectRolesLoading ]);

    const onSubmitHandler = () => {
        if (!!user && !!projectUuid)
            update(user.uuid, projectUuid, tempSelectedUserProjectRoles);
    }

    if (isUserLoading || isProjectRolesLoading || isAssignedUserProjectRolesLoading)
        return <Box>Loading...</Box>

    if (isUserError || isProjectRolesError || isAssignedUserProjectRolesError || ! assignedUserProjectRoles)
        return <Box>Error caught...</Box>

    if (! projectRoles || projectRoles.length === 0)
        return <Box>No project roles on that projects</Box>

    const selectProjectRole = (projectRoleId: number) => {
        const index = tempSelectedUserProjectRoles.findIndex(record => projectRoleId === record);
        if (index !== -1) {
            setTempSelectedUserProjectRoles([
                ...tempSelectedUserProjectRoles.slice(0, index),
                ...tempSelectedUserProjectRoles.slice(index + 1),
            ]);
        } else {
            setTempSelectedUserProjectRoles((prev) => [
                ...prev,
                projectRoleId,
            ]);
        }
    }

    return (
        <Fragment>
            <Title title="User Project Roles Management" />
            <Paper elevation={3} sx={{ mt: 2 }}>
                <Toolbar>User Project Roles Management</Toolbar>
                <TableContainer>
                    <Table stickyHeader sx={{ width: "100%" }}>
                        <TableHead>
                            <TableRow sx={{ "& th": { backgroundColor: "unset" }}}>
                                <TableCell></TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectRoles.map(projectRole => (
                                <TableRow
                                    key={projectRole.id}
                                    hover
                                    onClick={() => selectProjectRole(projectRole.id)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={
                                                tempSelectedUserProjectRoles.findIndex(record => projectRole.recordId === record) > -1
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{projectRole.role}</TableCell>
                                    <TableCell>{projectRole.roleName}</TableCell>
                                    <TableCell>{projectRole.roleDescription}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack spacing={2} direction="row" sx={{ m: 2 }}>
                    <Button color="xPrimary" variant="contained" onClick={() => onSubmitHandler()} disabled={isPending || tempSelectedUserProjectRoles.length === assignedUserProjectRoles.length}>Apply</Button>
                    <Button color="xDanger" variant="contained" onClick={() => navigate(`/admin/user/${userId}/show`)}>Back to user</Button>
                </Stack>
            </Paper>
        </Fragment>
    );
};

export default UserProjectRoleView;
