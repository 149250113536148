import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';

export enum PROJECT_STATUS {
    ACTIVE = "A",
    DISABLED = "D",
    DELETED = "X"
}

export const PROJECT_STATUSES = [
    {
        id: PROJECT_STATUS.ACTIVE,
        name: "Active",
        Icon: CheckCircleOutlineIcon,
    },
    {
        id: PROJECT_STATUS.DISABLED,
        name: "Disabled",
        Icon: DoNotDisturbOnOutlinedIcon,
    },
    {
        id: PROJECT_STATUS.DELETED,
        name: "Deleted",
        Icon: DoNotDisturbAltOutlinedIcon,
    },
];

export enum USER_STATUS {
    PENDING = "P",
    ACTIVE = "A",
    SUSPENDED = "S",
    DELETED = "X",
}

export const USER_STATUS_LIST = [
    {
        id: USER_STATUS.PENDING,
        name: "Pending",
        Icon: DoNotDisturbOnOutlinedIcon,
    },
    {
        id: USER_STATUS.ACTIVE,
        name: "Active",
        Icon: DoNotDisturbOnOutlinedIcon,
    },
    {
        id: USER_STATUS.SUSPENDED,
        name: "Suspended",
        Icon: DoNotDisturbOnOutlinedIcon,
    },
    {
        id: USER_STATUS.DELETED,
        name: "Deleted",
        Icon: DoNotDisturbOnOutlinedIcon,
    },
]